import React, { useEffect, useState } from 'react'

const OrderDetail = ({selectedOrder}) => {
    const [orderRec,setOrderRec]=useState([])
    const [totalWithoutGst,setTotalWithoutGst]=useState(0);
    const [gst,setGst]=useState(0)
    useEffect(() => {
        if(Object.keys(selectedOrder).length!==0){

        
        const endPoint = "particularOrder"
        const port = "https://marina-world-order.onrender.com"
        const Url = `${port}/${endPoint}?OrderID=${selectedOrder.OrderID}`
        const fetchData = async () => {
            try {
                const response = await fetch(Url);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                console.log(result,"ppppp")   
                if(result.msg==="data Found"){
                    setOrderRec(result.data)
                    const total = result.data                      
                      .reduce((acc, curr) => acc + (parseFloat(curr.ProductPrice) * parseFloat(curr.Quantity)), 0); // Calculate total amount
                      setTotalWithoutGst(parseFloat(total).toFixed(2)); 
                      const amt=parseFloat(total).toFixed(2);
                      setGst(((amt*9)/100).toFixed(2))
                }            
                
            } catch (err) {
                console.log(err.message);
            }
        };
            fetchData();
    }
        

    }, [selectedOrder]);
    function formatDate(dateString) {
        if (dateString == undefined) {
            return "";
        }
        else {

            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            const date = new Date(dateString);
            const day = date.getUTCDate();
            // const month = months[date.getUTCMonth()];
            const month = date.getUTCMonth()+1;

            const year = date.getUTCFullYear();
           
                return `${day}-${month}-${year}`;
            
            

        }
    }
    return (
        <>
            <div>
                <div className="d-flex align-items-center justify-content-between">
                    <h5>Order no.</h5>
                    <h5>{orderRec[0]?.OrderID}</h5>
                </div>
                <div className="d-flex align-items-center justify-content-between" style={{ fontSize: "1rem" }}>
                    <div>
                        <label htmlFor="date">Date : {formatDate(orderRec[0]?.OrderDate)}</label>
                    </div>
                    <div>
                        <label htmlFor="status">Status : {orderRec[0]?.OrderStatus}</label>
                    </div>
                </div>

            </div>
            <div className='text-center my-3'>
                <h5>Order Summary</h5>
                {orderRec.map((product,index)=>(
                <div className="d-flex align-items-center p-3  mb-3">

                    <div className="position-relative me-3">
                        {/* Product Image */}
                        <img
                             src={`data:${product?.image[0]?.filetype};base64,${product?.image[0]?.fileLocation}`}
                            alt="Product"
                            className="img-fluid rounded-3"
                            style={{ width: "100px", height: "auto" }}
                        />
                        {/* Circular Number */}
                        <div
                            className="position-absolute top-0 start-100 translate-middle bg-white text-dark fw-bold rounded-circle d-flex justify-content-center align-items-center"
                            style={{ width: "20px", height: "20px", fontSize: "1rem" }}
                        >
                           {product?.Quantity}
                        </div>
                    </div>

                    {/* Product Details */}
                    <div className='d-flex align-items-start justify-content-start flex-column px-1' style={{ fontSize: "1rem" }}>
                        <p className="mb-0 text-start">{product?.ProductName}</p>
                        <p className="text-secondary mb-0">{product?.BrandName}</p>
                        <p className="fw-bold mb-0">{product?.ProductPrice}</p>
                    </div>
                </div>
                  ))}
                    <hr />


                {/* Invoice Summary Card */}
                <div style={{ fontSize: "1rem" }}>
                    {/* Sub Total Row */}
                    <div className="d-flex justify-content-between" >
                        <span className="fw-bold">Sub Total</span>
                        <span>₹ {totalWithoutGst}</span>
                    </div>

                    
                    <div className="d-flex gap-5 mt-1">
                        <span className="fw-bold">CGST</span>
                        <span>9%</span>
                        <span>₹ {gst}</span>

                    </div>

                    
                    <div className="d-flex gap-5 mt-1">
                        <span className="fw-bold">CGST</span>
                        <span>9%</span>
                        <span>₹ {gst}</span>

                    </div>

                    {/* Total Tax Row */}
                    <div className="d-flex justify-content-between border-bottom mt-2 pb-2">
                        <span className="fw-bold">Total Tax</span>
                        <span>₹ {(gst*2).toFixed(2)}</span>
                    </div>

                   
                    <div className="d-flex justify-content-between border-bottom mt-3 pb-2">
                        <span className="fw-bold">Total</span>
                        <span className="fw-bold fs-5">₹ {(parseFloat((gst*2).toFixed(2))+parseFloat(totalWithoutGst)).toFixed(2)}</span>
                    </div>

                    
                    <div className="d-flex justify-content-center mt-3">
                        <p className="mb-0">Invoice Name : Bill20.pdf</p>                        
                    </div>
                </div>


            </div>

        </>
    )
}

export default OrderDetail
