import React, { useContext, useEffect, useRef, useState } from 'react'
import PageHeader from '../PageHeader'
import { GreySearch, Upload, WhiteUpload } from '../../../Assets/Images/ExportSvg'
import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OrderList from './OrderList';
import OrderDetail from './OrderDetail';
import { context } from '../../Context/Context';

const UploadInvoice = () => {
    const [selectedDealer, setSelectedDealer] = useState({})
    const [showList, setShowList] = useState(false)
    const [dealerList, setDealerList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null); 
    const {snackbarMessage, setSnackbarMessage,snackbarOpen, setSnackbarOpen} = useContext(context);    
    const [selectedOrder,setSelectedOrder]=useState({})

    const handleImageClick = () => {
        // Trigger the file input click programmatically
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];

        if (uploadedFile) {
            if (selectedOrder.OrderStatus==="Rejected") {
                setSnackbarMessage("Order Rejected You Cannot Upload Invoice");
                setSnackbarOpen(true)
                return;
            }
            // Check file type
            if (uploadedFile.type !== "application/pdf") {
                setSnackbarMessage("Only PDF files are allowed.");
                setSnackbarOpen(true)
                return;
            }

            // Check file size (10 MB = 10 * 1024 * 1024 bytes)
            if (uploadedFile.size > 10 * 1024 * 1024) {
                setSnackbarMessage("File size should not exceed 10 MB.");
                setSnackbarOpen(true)
                return;
            }
           

            // Clear errors and set the file
            // setSnackbarMessage("");
            setFile(uploadedFile);
           
            
        }
    };
   const  handleSave=()=>{
    if (file===null) {
        setSnackbarMessage("Upload file");
        setSnackbarOpen(true)
        return;
    }

    const date= new Date();
    let formData={
        orderid:selectedOrder.OrderID,uploadDate:date,pdf:file,UserID:selectedOrder.UserID
    }
    const Url = `https://marina-world-order.onrender.com/Upload`

   
        const formData1 = new window.FormData();
        for (const key in formData) {
            if (formData.hasOwnProperty(key)) {
                formData1.append(key, formData[key]);
            }
        }
        console.log(formData1)
        fetch(Url, {
            method: 'POST',
            body: formData1,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.msg == "File uploaded successfully") {
                    setSnackbarMessage(data.msg)
                    setSnackbarOpen(true)
                    setFile(null)
                }
                setSnackbarMessage(data.msg)
                setSnackbarOpen(true)

            })
            .catch((error) => {
                console.error('Error:', error);
                setSnackbarMessage(error)
                setSnackbarOpen(true)
            });
   }
    useEffect(() => {
        let port = "https://marina-world-users.onrender.com"
        let endPoint = "userDetails"
        let reqFor = "allDealers"


        if (endPoint !== "" && port !== "") {


            const Url = `${port}/${endPoint}?reqFor=${reqFor}`
            fetch(Url, {
                method: 'GET',  // Specify that this is a POST request
                headers: {
                    'Content-Type': 'application/json',  // Send JSON data
                },
                // Convert the JavaScript object to JSON string
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log('Success:', data.data);
                    setDealerList(data.data)
                    setFilteredList(data.data)
                })
                .catch((error) => {
                    console.error('Error:', error);
                    //   setMessage('Failed to post data.');
                });
        }



    }, [])
    const filterList = (value) => {
        if (value) {
            const filteredItem = dealerList.filter(
                (option) =>
                    option.UserID == (value) ||
                    option.Name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredList(filteredItem)
        }

    }
    const divRef = useRef(null);

    const handleClickOutside = (event) => {
        // Check if the click happened outside the div
        if (divRef.current && !divRef.current.contains(event.target)) {
            setShowList(false);
        }
    };

    useEffect(() => {
        // Add event listener for clicks
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Clean up the event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    // console.log(selectedDealer)
    return (
        <>
            <PageHeader />
            <div className="row">
                <div className="col-4">
                    <div ref={divRef}>
                        <div className="card myCard my-2" >
                            <div className="position-relative">
                                <input
                                    type="text"
                                    id='additional-form-control'
                                    autoComplete="off"
                                    style={{ boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }}
                                    placeholder="Dealer-ID / Dealer Name"
                                    className="form-control w-100"
                                    onClick={() => { setShowList(true) }}
                                    onChange={(e) => {
                                        filterList(e.target.value);
                                    }}
                                />

                                <img src={GreySearch}
                                    alt='Search'
                                    style={{ cursor: 'pointer', background: "#222831" }}
                                    width={"18em"}
                                    className="position-absolute top-50 end-0 translate-middle-y me-2 uploadButton"
                                />
                            </div>
                        </div>
                        {showList && (
                            <ul className="list-group position-absolute text-start " style={{ zIndex: 1, maxHeight: "50vh", overflowY: "auto" }}>
                                {filteredList.map((option, i) => (
                                    <li
                                        key={i}
                                        className="list-group-item"
                                        onClick={() => { setSelectedDealer(option); setShowList(false) }}
                                    >
                                        {option?.UserID} - {option?.Name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                </div>
            </div>
            <div className="row align-items-stretch">
                <div className="col-4">
                        {Object.keys(selectedDealer).length!==0?
                    <div className='card myCard h-100'>
                        <div className="card-body px-2">
                            <OrderList selectedDealer={selectedDealer} selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder}/>

                        </div>
                    </div>
                    :null}
                </div>
                <div className="col-4">
                    {Object.keys(selectedOrder).length!==0?
                    <div className='card myCard h-100'>
                        <div className="card-body px-2">
                            <OrderDetail selectedOrder={selectedOrder}/>
                        </div>
                    </div>:<></>}
                </div>
                <div className="col-4">
                {Object.keys(selectedOrder).length!==0?
                <>
                    <div className='card myCard text-center'>
                        <div className="card-body d-flex align-items-center justify-content-center flex-column" style={{minHeight:"400px"}}>
                            <img src={WhiteUpload}
                                alt='upload'
                                style={{ cursor: 'pointer', background: "#222831" }}
                                width={"50rem"}
                                onClick={handleImageClick}
                                className="uploadButton"
                            />
                            <input
                                ref={fileInputRef}
                                type="file"
                                accept="application/pdf"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                            />
                            <h5 className="mt-2 mb-3">{file===null?"Upload Invoice":file.name}</h5>
                            <label htmlFor="warning" className="text-muted">** PDF Only</label>
                            <label htmlFor="warning" className="text-muted">** Max Size 10MB</label>
                        </div>
                    </div>
                    <div className="d-flex align-bottom justify-content-center w-100 align-item-center mt-4 mb-3">
                <div className={`btn px-5 mx-1 themeButton`} onClick={handleSave}>Save</div>
                <div className="btn whiteThemeButton px-4 mx-1" style={{ border: 0 }} onClick={()=>setFile(null)}>Cancel</div>
               
                </div> </>:<></>}
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical:"bottom",horizontal:"right"}}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                message={snackbarMessage}
                action={action}
            />

        </>
    )
}

export default UploadInvoice
