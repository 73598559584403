import React, { useContext, useEffect, useState } from 'react'
import { context } from '../../Context/Context';

const OrderList = ({selectedDealer,setSelectedOrder,selectedOrder}) => {
    const {setLoading} = useContext(context);
    const [displayRecords,setDisplayRecords]=useState([{}])
    useEffect(() => {
        setSelectedOrder(displayRecords[0])
        if(Object.keys(selectedDealer).length!==0){        
        const endPoint = "RecentOrderRecords"
        const reqFor = "userIdBasis"
        const port ="https://marina-world-users.onrender.com" 
        const Url = `${port}/${endPoint}?reqFor=${reqFor}&userid=${selectedDealer.UserID}`
        console.log(Url)
        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await fetch(Url);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                console.log(result,"hiii")
                if(result.msg==="No Data Found"){
                    setDisplayRecords([]) 
                }
                else{

                    if (endPoint === "RecentOrderRecords") {
                        let uniqueRecords = Object.values(
                            result.data.reduce((acc, obj) => {
                                acc[obj.OrderID] = obj; // Use orderId as the key to ensure uniqueness
                                return acc;
                            }, {})
                        );
                        console.log(uniqueRecords)
                        setDisplayRecords(uniqueRecords)
                        setSelectedOrder(uniqueRecords[0])
                    }
                    else {
                        
                        setDisplayRecords(result.data);
                    }
                }
                setLoading(false)
            } catch (err) {
                console.log(err.message);
            }
        };
            fetchData();
    }
        

    }, [selectedDealer]);
    function formatDate(dateString) {
        if (dateString == undefined) {
            return "";
        }
        else {

            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            const date = new Date(dateString);
            const day = date.getUTCDate();
            // const month = months[date.getUTCMonth()];
            const month = date.getUTCMonth()+1;

            const year = date.getUTCFullYear();
           
                return `${day}-${month}-${year}`;
            
            

        }
    }
  return (
    <>
    <div className="d-flex flex-column">
        {displayRecords.length==0 ?
        <label htmlFor="RecentOrder" className="my-2" style={{fontSize:"1rem"}}>No Orders Yet</label>: 
<div>


    <label htmlFor="RecentOrder" className="my-2" style={{fontSize:"1rem"}}>Recent Order</label>
    <div className={`${JSON.stringify(displayRecords[0])===JSON.stringify(selectedOrder)?"themeButton":"" } py-3 px-2`} onClick={()=>setSelectedOrder(displayRecords[0])}>
        <div className="d-flex align-items-center justify-content-between">
            <h5>Order no.</h5>
            <h5>{displayRecords[0]?.OrderID}</h5>
        </div>
        <div className="d-flex align-items-center justify-content-start" style={{fontSize:"1rem"}}>
            <label htmlFor="date" style={{minWidth:"4.2rem"}}>Date</label>
            <label>: {formatDate(displayRecords[0]?.OrderDate)}</label>
        </div>
        <div className="d-flex align-items-center justify-content-start" style={{fontSize:"1rem"}}>
            <label htmlFor="status" style={{minWidth:"4.2rem"}}>Status</label>
            <label >: {displayRecords[0]?.OrderStatus}</label>
        </div>
    </div>

    <label htmlFor="history" className="mt-2 mb-4" style={{fontSize:"1rem"}}>History</label>
    {displayRecords.map((product,index)=>(
        <>{index===0?null:
        
       <>
    <div className={`py-2 px-2 ${JSON.stringify(product)===JSON.stringify(selectedOrder)?"themeButton":""}`} onClick={()=>setSelectedOrder(product)}>
        <div className="d-flex align-items-center justify-content-between">
            <h5>Order no.</h5>
            <h5>{product?.OrderID}</h5>
        </div>
        <div className="d-flex align-items-center justify-content-start" style={{fontSize:"1rem"}}>
            <label htmlFor="date" style={{minWidth:"4.2rem"}}>Date</label>
            <label>: {formatDate(product?.OrderDate)}</label>
        </div>
        <div className="d-flex align-items-center justify-content-start" style={{fontSize:"1rem"}}>
            <label htmlFor="status" style={{minWidth:"4.2rem"}}>Status</label>
            <label >: {product?.OrderStatus}</label>
        </div>
    </div>
    {index===displayRecords.length-1?null: <hr style={{height:"2px", backgroundColor:"#fff"}}/>}
    </>

     }
     </>

 ))}
   
   </div>}

    </div>

    </>
  )
}

export default OrderList
