import React from 'react'
import Form from '../Forms/Form'
import Tables from '../Tables/Tables'
import PageHeader from './PageHeader'

const DealerManagement = () => {
  
  return (
    <>
    <PageHeader/>
    <Tables TableName={"dealerList"} displayRecords={[{},{}]} heading={false} extraButton={"Add Dealer"} editable={true} /></>
  )
}

export default DealerManagement