import React, { useEffect, useState } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";

// const data = [
//     { name: "Tasks", Monday: 15, Tuesday: 30, Wednesday: 14 },
//     { name: "Task Complete", Monday: 10, Tuesday: 30, Wednesday: 10 },
//     { name: "Ratio", Monday: 1.5, Tuesday: 1.5, Wednesday: 1.5 },
// ];

const COLORS = {
    Monday: "#8a69ff",
    Tuesday: "#ff8073",
    Wednesday: "#4fc3f7",
    Thursday: "#A2D729",
    Friday: "#880D1E",
    Saturday: "#798478",
    Sunday: "#F9F9ED"

};

const TaskCompletionChart = React.memo(({ ExecutiveID }) => {
    const [data, setData] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://marina-world-users.onrender.com/TaskAnalysis?executiveID=${ExecutiveID}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();

                if (result.msg === "data found") {
                    function convertTaskData(data) {
                        // Helper to extract day names
                        const getDayName = (dateString) => {
                            const date = new Date(dateString);
                            const weekdayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

                            return weekdayNames[date.getUTCDay()]
                        };

                        // Map through the data
                        const result = [
                            { name: "Tasks" },
                            { name: "Task Complete" },
                            { name: "Ratio" },
                        ];

                        data[0].forEach((_, index) => {
                            const dayName = getDayName(data[0][index].task_date);
                            // Total tasks for the day
                            result[0][dayName] = data[0][index].task_count;

                            // Completed tasks for the day
                            result[1][dayName] = data[1][index].task_count;

                            // Completion ratio for the day
                            result[2][dayName] = parseFloat(data[2][index].completion_ratio);
                        });

                        return result;
                    }

                    const output = convertTaskData(result.results);
                    console.log(output)
                    setData(output)
                }

            } catch (error) {
            }
        };

        fetchData();
    }, [ExecutiveID]);
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                maxWidth: "700px", // Optional: Max width for larger screens
                margin: "0 auto",
                color: "white",
            }}
        >
            <div className="d-flex justify-content-between align-items-center w-75 mt-3">

                <label style={{ fontSize: "1rem" }}>Task Completion Ratio</label>


                <label style={{ fontSize: "1rem" }}>Month</label>


            </div>
            <ResponsiveContainer width="100%" aspect={2}>
                <BarChart
                    data={data}
                    margin={{
                        top: 20,
                        right: 20,
                        left: 20,
                        bottom: 40, // Increased bottom margin for the legend
                    }}
                >
                    {/* Grid Lines */}
                    <CartesianGrid strokeDasharray="3 3" stroke="#525252" />
                    {/* X and Y Axes */}
                    <XAxis
                        dataKey="name"
                        stroke="white"
                        tick={{ fontSize: 12 }}
                        tickLine={false}
                        axisLine={{ stroke: "white" }}
                    />
                    <YAxis
                        stroke="white"
                        tick={{ fontSize: 12 }}
                        tickLine={false}
                        axisLine={{ stroke: "white" }}
                    />
                    {/* Tooltip */}
                    <Tooltip
                        contentStyle={{ backgroundColor: "#222831", borderRadius: "5px" }}
                        itemStyle={{ color: "#ffffff" }}
                    />
                    {/* Legend Below the Graph */}
                    <Legend
                        verticalAlign="bottom"
                        align="center"
                        layout="horizontal"
                        wrapperStyle={{
                            paddingTop: "10px",
                            color: "white",
                            fontSize: "12px",
                        }}
                    />
                    {/* Bars */}
                    { (data.length>0) && Object.keys(data[0]).map((day, index) => (
                        <>{day!=="name"?<>
                        
                         <Bar key={index} dataKey={day} fill={COLORS[day]} barSize={20} />
                        </>:null}
                        </>
                    ))}
                   
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
});

export default TaskCompletionChart;
