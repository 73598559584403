import React, { useContext } from 'react'
import { context } from '../../Context/Context';

const AddTask = React.memo(({setAction,selectedExecutive}) => {
    console.log(selectedExecutive)
    const { formData, setFormData,setSnackbarMessage,setSnackbarOpen } = useContext(context);
    const handleInput = (e) => {
        console.log(e.target.name, e.target.value)
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const handleAddTask = () => {
        console.log(formData)
        const currentDate = new Date();
        const data={...formData, ExecutiveID: selectedExecutive?.ExecutiveID, task_date: currentDate, status: "pending"}
        const Url=`https://marina-world-users.onrender.com/addTask`
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Headers
              },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                if(data.msg==="executive tasks added successfully"){
                    setSnackbarMessage(data.msg)
                    setSnackbarOpen(true)
                    setFormData({})
                    setAction("")
                }
                setSnackbarMessage(data.msg)
                    setSnackbarOpen(true)
            })
            .catch((error) => {
                console.error('Error:', error);
                setSnackbarMessage(error)
                    setSnackbarOpen(true)
            });
        
    }
    return (
        <div className="card loginCard">
            <div className="card-body">

                <div className="text-center">
                    <label htmlFor="heading" style={{fontSize:"1rem"}} className='my-3 mx-2'>Add Task</label>
                </div>

                <label htmlFor="heading" className="mt-2">Task Heading</label>
                <input
                    className='w-100 form-control box-shadow mb-2'
                    placeholder="Heading"
                    value={formData?.task_name}
                    autoComplete="off"
                    onChange={handleInput}
                    name="task_name" />


                <label htmlFor="description" className="mt-2">Task Description</label>
                <textarea
                    class="form-control box-shadow mb-2"
                    placeholder="Description..."
                    rows="3"
                    autoComplete="off"
                    value={formData?.description}
                    onChange={handleInput}
                    name="description"
                />

                <label htmlFor="priority" className="mt-2">Priority</label>
                <div className="Login-form-select select_box">
                <select class="form-select w-100 box-shadow mb-2" name="priority" onChange={handleInput} style={{ color: formData?.priority === "" ? "#9B9D9F" : "#EEEEEE" }} >
                    <option value="">Priority</option>
                    <option value="Low">Low</option>
                    <option value="Medium">Medium</option>
                    <option value="High">High</option>
                </select>
                </div>


                <div className="d-flex align-bottom justify-content-center w-100 align-item-center mt-4 mb-3">
                    <div className={`btn px-3 mx-1 themeButton`} onClick={handleAddTask} >Add Task</div>
                    <div className="btn whiteThemeButton px-4 mx-1" style={{ border: 0 }} onClick={()=>{setFormData({});setAction("")}}>Cancel</div>
                </div>

            </div>
        </div>
    )
})

export default AddTask
