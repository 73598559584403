import React from 'react'
import Form from '../Forms/Form'
import Tables from '../Tables/Tables'
import PageHeader from './PageHeader'

const AttendanceManagement = () => {
  return (
    <>
    <PageHeader/>
    <Tables TableName={"attendanceManagement"} displayRecords={[{},{}]} heading={false} extraButton={"Current Date"}/></>
  )
}

export default AttendanceManagement
