
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProfileImg, Logout, Menu, WhiteCalendar, Notification } from "../../Assets/Images/ExportSvg";
import { context } from "../Context/Context";


const Navbar = ({ active }) => {
  const navigate = useNavigate()
  const { sideBarOpen, setSideBarOpen, adminName ,setIsAuthenticated} = useContext(context);
  const logout = () => {
    setIsAuthenticated(false)
    navigate("/")
  }


  return (
    <div>
      <nav className="navbar myNavbar d-flex justify-content-between border-bottom align-items-center px-4">

        <img src={Menu} alt="" width={"30rem"} className="submenuIcon"
          onClick={() => { setSideBarOpen(true) }} />
        <label
          className="flex-shrink-1 fw-bold"
          style={{ fontSize: "1rem" }}
        >
          {active}
        </label>






        <div className="d-flex justify-content-center align-items-center ">
          <div className="mx-2">
            <img src={WhiteCalendar} alt="profile image" width={"26rem"} style={{ cursor: "pointer" }} onClick={() => navigate("/layout/AddEvents")} />
          </div>
          <div className="mx-2">
            <img src={ProfileImg} alt="profile image" />
          </div>
          <div className="mx-2">
            <label className="d-block">{adminName}</label>
            <label>Admin</label>
          </div>
          <div className="mx-2">
            <img src={Logout} alt="logout" width={40} onClick={logout} />
          </div>
        </div>
      </nav>
    </div>

  );
};

export default Navbar;
