import React from 'react'


const OrderManagementSummary = ({orderRec,totalWithoutGst,gst}) => {
      function formatDate(dateString) {
        if (dateString == undefined) {
            return "";
        }
        else {

            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            const date = new Date(dateString);
            const day = date.getUTCDate();
            // const month = months[date.getUTCMonth()];
            const month = date.getUTCMonth()+1;

            const year = date.getUTCFullYear();
           
                return `${day}-${month}-${year}`;
        }
    }
    return (
        <>
            
            <div className="mx-5 text-center">
                <label style={{ fontSize: "1rem" }}>Order Summary</label>
                <div>
                    <div className="d-flex align-items-center justify-content-between">

                        <div style={{ fontSize: "1rem" }}>
                            <label className="">Order no.</label>
                            <label className='ms-2'>{orderRec[0]?.OrderID}</label>
                        </div>

                        <div className="d-flex  align-items-start flex-column" style={{ fontSize: "1rem" }}>
                            <div>
                                <label htmlFor="date">Date : {formatDate(orderRec[0]?.OrderDate)}</label>
                            </div>
                            <div>
                                <label htmlFor="status">Status : {orderRec[0]?.OrderStatus}</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='text-center my-5'>
                    {orderRec.map((product,index)=>(

                  
                    <div key={index} className="d-flex justify-content-between align-item-start ">
                        <div className="d-flex align-items-center p-3 mb-3">

                            <div className="position-relative me-3">
                                {/* Product Image */}
                                <img
                                    // src="https://via.placeholder.com/100"
                                    src={`data:${product?.image[0]?.filetype};base64,${product?.image[0]?.fileLocation}`}
                                    alt="Product"
                                    className="img-fluid rounded-3"
                                    style={{ width: "100px", height: "auto" }}
                                />
                                {/* Circular Number */}
                                <div
                                    className="position-absolute top-0 start-100 translate-middle bg-white text-dark fw-bold rounded-circle d-flex justify-content-center align-items-center"
                                    style={{ width: "20px", height: "20px", fontSize: "1rem" }}
                                >
                                    {product?.Quantity}
                                </div>
                            </div>

                            {/* Product Details */}
                            <div className='d-flex align-items-start justify-content-start flex-column px-1' style={{ fontSize: "1rem" }}>
                                <p className="mb-0 text-start">{product?.ProductName}</p>
                                <p className="text-secondary mb-0">{product?.BrandName}</p>

                            </div>
                        </div>
                        <p className="fw-bold mb-0">₹ {product?.ProductPrice}</p>

                    </div>
                      ))}
                    <hr />


                    {/* Invoice Summary Card */}
                    <div className="col-6 offset-6" >
                        {/* Sub Total Row */}
                        <div className="d-flex justify-content-between" >
                            <span className="fw-bold">Sub Total</span>
                            <span>₹ {totalWithoutGst}</span>
                        </div>


                        <div className="d-flex gap-5 mt-1">
                            <span className="fw-bold">CGST</span>
                            <span>9%</span>
                            <span>₹ {gst}</span>

                        </div>


                        <div className="d-flex gap-5 mt-1">
                            <span className="fw-bold">CGST</span>
                            <span>9%</span>
                            <span>₹ {gst}</span>

                        </div>

                        {/* Total Tax Row */}
                        <div className="d-flex justify-content-between mt-2 pb-2">
                            <span className="fw-bold">Total Tax</span>
                            <span>₹ {(gst*2).toFixed(2)}</span>
                        </div>
                    </div>
                    <hr />
                    <div className='col-6 offset-6'>


                        <div className="d-flex justify-content-between  mt-3 pb-2">
                            <span className="fw-bold">Total</span>
                            <span className="fw-bold">₹ {(parseFloat((gst*2).toFixed(2))+parseFloat(totalWithoutGst)).toFixed(2)}</span>
                        </div>

                    </div>
                    <hr />


                    <div className="d-flex align-bottom justify-content-center w-100 align-item-center mt-4 mb-3">
                        <div className={`btn px-5 mx-1 themeButton`} >Download PDF</div>
                        <div className="btn whiteThemeButton px-4 mx-1" style={{ border: 0 }}>Print Order Sheet</div>
                    </div>
                


            </div>
        </div >

        </>
    )
}

export default OrderManagementSummary
