import React, { useEffect, useRef, useState } from 'react'
import Form from '../Forms/Form'
import Tables from '../Tables/Tables'
import PageHeader from './PageHeader'
import { GreySearch } from '../../Assets/Images/ExportSvg'

const StockManager = () => {
  const [searchFilter,setSearchFilter]=useState("")
  console.log(searchFilter)
 
  return (
    <>
    <PageHeader/>
      <div className="row mt-2">
        <div className="col-7">              
          <div className="card myCard my-2" >
            <div className="position-relative">
              <input
                id='additional-form-control'
                type="text"
                style={{ boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }}
                placeholder="Enter Product Name / ID"
                className="form-control w-100"
                onChange={(e)=>setSearchFilter(e.target.value)}
                value={searchFilter}
                
              />

              <img src={GreySearch}
                alt='Search'
                style={{ cursor: 'pointer', background: "#222831" }}
                width={"18em"}
                className="position-absolute top-50 end-0 translate-middle-y me-2 uploadButton"
              />
            </div>
          </div>
          <Tables TableName={"updateStock"} heading={false} editable={true}  criteria={{"ProductName":searchFilter,"ProductID":searchFilter}}/>
        </div>
        <div className="col-5">
          <Form formName={"updateStock"} />
        </div>
      </div>
    </>
  )
}

export default StockManager
