import React, { useContext, useEffect, useState } from "react";
import { Backward, Forward } from "../../Assets/Images/ExportSvg";
import { Box, Modal } from "@mui/material";
import AddEventForm from "./AddEventForm";
import PageHeader from "../Pages/PageHeader"
import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { context } from "../Context/Context";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,

};

const WeekCalendar = () => {
  const { snackbarMessage, setSnackbarMessage,snackbarOpen, setSnackbarOpen} = useContext(context);  
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedDateTime, setSelectedDateTime] = useState({ date: "", time: "" })
  const [startOfWeek, setStartOfWeek] = useState(() => {
    const currentDate = new Date();
    return new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
  });

  const getWeekDates = (startDate) => {
    return Array.from({ length: 7 }, (_, i) => {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      return date;
    });
  };

  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const weekDates = getWeekDates(startOfWeek);

  const times = Array.from({ length: 24 }, (_, i) => {
    const hour = i % 12 || 12;
    const period = i < 12 ? "AM" : "PM";
    return `${hour}:00 ${period}`;
  });

  const [events, setEvents] = useState([]);

  const handleNextWeek = () => {
    setStartOfWeek((prev) => {
      const nextWeek = new Date(prev);
      nextWeek.setDate(prev.getDate() + 7);
      return nextWeek;
    });
  };

  const handlePreviousWeek = () => {
    setStartOfWeek((prev) => {
      const previousWeek = new Date(prev);
      previousWeek.setDate(prev.getDate() - 7);
      return previousWeek;
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    const Url = `http://localhost:4000/viewEvents`
    const fetchData = async () => {
      try {
        const response = await fetch(Url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        if(result.msg==="data found"){
          setEvents(result.data);
        }
        console.log(result);
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchData();
  }, [snackbarOpen])

  return (
    <div className="container">
      <PageHeader />
      {/* Navigation Buttons */}
      <div className="d-flex justify-content-end align-items-center mb-2">
        <img src={Backward} alt="" width={"16rem"} onClick={handlePreviousWeek} />
        <label className="mx-2" style={{ fontSize: "1rem" }}>Week</label>
        <img src={Forward} alt="" width={"16rem"} onClick={handleNextWeek} />
      </div>

      {/* Header Section (Days and Dates) */}
      <div className="row border-bottom text-center fw-bold align-items-center">
        {/* Time Placeholder */}
        <div className="col-2 col-sm-1"></div>

        {/* Days */}
        {weekDates.map((date, index) => (
          <div className="col text-nowrap py-3" key={index}>
            <div>{days[index]}</div>
            <div style={{ fontSize: "1rem" }}>{date.getDate()}</div>
          </div>
        ))}
      </div>
      {/* Body Section (Time and Grid) */}
      {times.map((time, index) => (

        <div className="d-flex justify-content-center  border-bottom align-items-end" style={{ minHeight: "5rem" }}>
          {/* Time Column */}
          <div className="col-2 col-sm-1 h-100 my-auto text-end pe-2  h-100 d-flex align-items-center justify-content-center" style={{ minHeight: "5rem" }}>
            <div
              key={index}
              className=" py-2 text-nowrap h-100 d-flex align-items-center justify-content-center"
            // style={{ height: "5rem" }}
            >
              <label >{time}</label>
            </div>

          </div>

          {/* Calendar Grid */}
          <div className="col h-100 " style={{ minHeight: "5rem" }} >
            <div className="row m-0 h-100" style={{ minHeight: "5rem" }}>
              {weekDates.map((date, dayIndex) => {
                // Filter events for the current day and time
                
                let currentEvents=[]
                if(events.length!==0){
                  currentEvents = events.filter((e) => {
                    const date1 = new Date(e.event_date);
                    const date2 = new Date(date);
                    return (e.event_time === time && date1.getDate() === date2.getDate() && date1.getMonth()===date2.getMonth() && date1.getFullYear()===date2.getFullYear()); // Explicit return
                  });
                }
                

                return (
                  <div key={dayIndex} className="col py-2 mx-auto justify-content-center d-block" style={{ minHeight: "5rem" }} onClick={() => { setSelectedDateTime({ date: date, time: time }); handleOpen() }}>
                    <div
                      key={index}
                      className=" position-relative m-auto p-1 h-100 border-start"
                    // style={{ minHeight: "5rem" }}
                    >
                      {currentEvents.map((event, eventIndex) => (
                        <div
                          key={eventIndex}
                          className="themeButton d-block px-2 py-1 position-relative text-wrap mb-1"
                        >
                          {event.title}
                        </div>
                      ))}
                    </div>
                  </div>
                );

              })}
            </div>
          </div>
        </div>

      ))}


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AddEventForm handleClose={handleClose}
            selectedDateTime={selectedDateTime}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage} />
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={action}
      />
    </div>
  );
};

export default WeekCalendar;



