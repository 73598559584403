import React, { useContext, useEffect, useState } from 'react'
import ExecutiveList from './ExecutiveList'
import TaskList from './TaskList'
import AddTask from './AddTask'
import ViewTask from './ViewTask'
import { context } from '../../Context/Context'
import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ExecutiveTaskManagement = () => {
  const { formData,snackbarMessage, setSnackbarMessage,snackbarOpen, setSnackbarOpen } = useContext(context);
  const [executiveList, setExecutiveList] = useState([])
  const [selectedExecutive, setSelectedExecutive] = useState({})
  const [selectedTask,setSelectedTask]=useState({})
  const [taskAction, setAction] = useState("")

  useEffect(()=>{
    setAction("")
    setSelectedTask({})
  },[selectedExecutive])
  // useEffect(() => {
  //   if (taskAction === "add") {
  //     const currentDate = new Date();
  //     setFormData({ ...formData, ExecutiveID: selectedExecutive?.ExecutiveID, task_date: currentDate, status: "pending" })
  //   }
  // }, [taskAction])
  console.log(formData)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="row">
      <div className="col-4">
        <ExecutiveList executiveList={executiveList} setExecutiveList={setExecutiveList} selectedExecutive={selectedExecutive} setSelectedExecutive={setSelectedExecutive} />
      </div>
      <div className="col-4">
        <TaskList selectedExecutive={selectedExecutive} 
        setAction={setAction} 
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
        setSnackbarMessage={setSnackbarMessage}
          setSnackbarOpen={setSnackbarOpen} />
      </div>
      <div className="col-4">
        {
          taskAction === "add" ? <AddTask setAction={setAction}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen} selectedExecutive={selectedExecutive}/>
            : taskAction === "view" ? <ViewTask selectedTask={selectedTask}/>
              : null
        }


      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackbarMessage}
        action={action}
      />
    </div>
  )
}

export default ExecutiveTaskManagement
