import React, { useContext, useEffect } from 'react'
import MiniCalendar from '../Calendar/MiniCalendar';
import { context } from '../Context/Context';
import NotificationActivity from './NotificationActivity';
import TruckStatus from './TruckStatus';
import LeaderBoard from './LeaderBoard';
import TopExecutive from './TopExecutive';
import DashboardTopCard from './DashboardTopCard';

const DashboardPage = () => {

    useEffect(()=>{
        setSnackbarOpen(false)
    },[])
    const date = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const {adminName,setSnackbarOpen} = useContext(context);

    return (
        <div>
            <div className="text-start text-color-dark">
                <label className='d-block fw-bold' style={{fontSize:"1rem"}}>Hello {adminName}</label>
                <label className='d-block'>{formattedDate}</label>
            </div>
            
            {/* <DashboardTopCard/> */}
            <div className="row my-4"> 
                <div className="col-lg-8  col-12">
                    <div className="card myCard my-1 mb-4" style={{minHeight:"300px"}}>
                        <div className="card-body">

                        </div>
                    </div>
                    <div className="row my-2 px-2">
                        <div className="card col myCard my-1 me-1" style={{minHeight:"300px",maxHeight:"500px",overflowY:"scroll"}}>
                            <div className="card-body">
                                <LeaderBoard/>
                            </div>
                        </div>
                        <div className="card col myCard  my-1 ms-1  me-1" style={{minHeight:"300px",maxHeight:"500px",overflowY:"scroll"}}>
                            <div className="card-body">
                                <TopExecutive/>
                            </div>
                        </div>
                        <div className="card myCard col-5  my-1 ms-1" style={{minHeight:"300px",maxHeight:"500px",overflowY:"scroll"}}>
                            <div className="card-body">
                                <TruckStatus/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-12 text-color-dark">
                    
                    <div className="card myCard my-2 mb-4" style={{minHeight:"300px"}}>
                        <div className="card-body">
                            <MiniCalendar/>
                        </div>
                    </div>
                    <div className="card myCard my-2 " style={{minHeight:"300px",maxHeight:"500px",overflowY:"scroll"}}>
                        <div className="card-body">
                            <NotificationActivity/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardPage
