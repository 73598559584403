
import './App.css';
import React, { useContext, useEffect, useState } from 'react';
import { messaging,generateToken} from "./firebase";
import { onMessage } from 'firebase/messaging';
import { context } from './Components/Context/Context';
import { Snackbar,IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AllRoutes from './AllRoutes';




function App() {
  const {setFcmToken,NotificationArray,setNotificationArray} = useContext(context);
  const [notificationObject,setNotificationObject]=useState({})
  const [notificationOpen,setNotificationOpen]=useState(false)


  useEffect(()=>{
     generateToken().then((data)=>{
      setFcmToken(data)
    })
    onMessage(messaging,(payload)=>{
      console.log(payload.notification);
      setNotificationObject(payload.notification)
      const date=new Date()
      const notification={...payload.notification,date:date}
      setNotificationArray((prevNotificationArray)=>{
        return [notification,...prevNotificationArray];
      }
    )
      setNotificationOpen(true)
    })
  },[])
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setNotificationOpen(false);
};

const action = (
    <React.Fragment>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    </React.Fragment>
);
  return (

    <>
      <AllRoutes/>
          <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={notificationOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                action={action}
                message={
                  <div>
                    <strong style={{ fontSize: '1rem' }}>{notificationObject.title}</strong>
                    <p style={{ margin: 0, fontSize: '0.875rem' }}>
                    {notificationObject.body}
                    </p>
                  </div>
                }/>
    </>
    
  );
}

export default App;
