import React, { useState } from 'react';
import { Backward, Forward } from '../../Assets/Images/ExportSvg';

const MiniCalendar = () => {
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
  const daysInMonth = getDaysInMonth(currentMonth, currentYear);

  const prevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const nextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  const renderCalendarRows = () => {
    const calendarCells = [];
    const totalCells = firstDayOfMonth + daysInMonth;

    // Add empty cells before the first day
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendarCells.push(<div key={`empty-${i}`} className="col ">&nbsp;</div>);
    }

    // Add days in the month
    for (let day = 1; day <= daysInMonth; day++) {
      const isToday =
        day === today.getDate() &&
        currentMonth === today.getMonth() &&
        currentYear === today.getFullYear();
      calendarCells.push(
        <div
          key={day}
          className={`col text-center py-2 `}
          style={{height:"2.6rem"}}
        >
            <label className={`${isToday ? 'py-1 px-2 colorful-table-head-cell text-white rounded-circle' : ""}`}>

          {day}
            </label>
        </div>
      );
    }

    // Add empty cells after the last day (to complete the last row)
    while (calendarCells.length % 7 !== 0) {
      calendarCells.push(
        <div key={`empty-end-${calendarCells.length}`} className="col">&nbsp;</div>
      );
    }

    // Group the cells into rows of 7
    const calendarRows = [];
    for (let i = 0; i < calendarCells.length; i += 7) {
      calendarRows.push(
        <div key={`row-${i}`} className="row">
          {calendarCells.slice(i, i + 7)}
        </div>
      );
    }

    return calendarRows;
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <img src={Backward} alt="" width={"16rem"} onClick={prevMonth} />
        <label className="mx-2" style={{ fontSize: "1rem" }}>{monthNames[currentMonth]} {currentYear}</label>
        <img src={Forward} alt="" width={"16rem"} onClick={nextMonth} />
      </div>
      {/* <div className="d-flex justify-content-between align-items-center mb-3">
        <button className="btn btn-outline-primary" onClick={prevMonth}>
          &lt;
        </button>
        <h5 className="m-0">
          {monthNames[currentMonth]} {currentYear}
        </h5>
        <button className="btn btn-outline-primary" onClick={nextMonth}>
          &gt;
        </button>
      </div> */}
      <div className="row text-center fw-bold colorful-table-head-cell">
        <div className="col py-1">S</div>
        <div className="col py-1">M</div>
        <div className="col py-1">T</div>
        <div className="col py-1">W</div>
        <div className="col py-1">T</div>
        <div className="col py-1">F</div>
        <div className="col py-1">S</div>
      </div>
      {renderCalendarRows()}
    </>
  );
};

export default MiniCalendar;
