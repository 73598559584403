import React from 'react'
import Form from '../Forms/Form'
import Tables from '../Tables/Tables'
import PageHeader from './PageHeader'

const BrandManagement = () => {
  return (
    <>
    <PageHeader/>
      <div className="row">
        <div className="col-5">
          <Form formName={"brandAdd"} />
        </div>
        <div className="col-7">
          <Tables TableName={"brandList"}  filter={false}/>
        </div>
      </div>
    </>
  )
}

export default BrandManagement
