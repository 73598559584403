import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from "recharts";

// const data = [
//   { name: "March", value: 30 },
//   { name: "April", value: 30 },
//   { name: "May", value: 30 },
// ];

const COLORS = ["#8a69ff", "#ff8073", "#4fc3f7"]; // Custom colors




const AttendancePieChart = React.memo(({ExecutiveID}) => {
  const [data,setData]=useState([])
  useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await fetch(`https://marina-world-users.onrender.com/attendanceAnalysis?executiveID=${ExecutiveID}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          console.log(result)
          if(result.msg==="data found"){
            const months = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ];
            function convertAttendanceData(data) {
              return data.map((item) => ({
                name:months[( item.attendance_month.split("-")[1])-1],
                value:item.total_present_days,
              }));
            }
            
            const output = convertAttendanceData(result.res); 
            // console.log(output)
            setData(output)
          }
          console.log(result,"000")
          
        } catch (error) {
        }
      };
  
      fetchData();
  }, [ExecutiveID]);
// Custom label function to render text inside the pie slices
  const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) / 2; // Position the label in the middle of the slice
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180)); // X-coordinate
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180)); // Y-coordinate
  
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontSize: "12px", fontWeight: "bold" }}
      >
        <tspan x={x} dy="-5">{data[index].name}</tspan> {/* Name on the top */}
        <tspan x={x} dy="15">{data[index].value}</tspan> {/* Value below the name */}
      </text>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        width: "100%", // Ensure the container takes full width
        maxWidth: "500px", // Optional: Set a max width for large screens
        margin: "0 auto", // Center the chart horizontally
      }}
    >
      <label style={{ color: "white",fontSize:"1rem", marginBottom: "10px", textAlign: "center" ,whiteSpace:"nowrap"}}>
        Total Attendance
      </label>
      <ResponsiveContainer width="100%" aspect={1}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius="70%" // Adjust outer radius dynamically
            fill="#8884d8"
            dataKey="value"
            labelLine={false} // Remove label lines
            // label={renderCustomLabel} // Use custom label function
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip
                        contentStyle={{ backgroundColor: "#222831", borderRadius: "5px" }}
                        itemStyle={{ color: "#ffffff" }}
                    />
                    <Legend
                        verticalAlign="bottom"
                        align="center"
                        layout="horizontal"
                        wrapperStyle={{
                            paddingTop: "10px",
                            color: "white",
                        }}
                    />
        </PieChart>
      </ResponsiveContainer>
      <label style={{ color: "white",fontSize:"1rem", marginTop: "10px", textAlign: "center" }}>
        Month
      </label>
    </div>
  );
});

export default AttendancePieChart;
