import React from 'react'
import Form from '../Forms/Form'
import PageHeader from './PageHeader'

const AddDealer = () => {
  return (
    <>
    <PageHeader back={true}/>
    <div className="w-75 mx-auto">
    <Form formName={"dealerAdd"} borderless={true} />
    </div>
    </>
  )
}

export default AddDealer
