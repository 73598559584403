import React from 'react'
import Form from '../Forms/Form'
import Tables from '../Tables/Tables'
import PageHeader from './PageHeader'

const ExecutiveManagement = () => {
  return (
    <>
    <PageHeader/>
    <Tables TableName={"executiveList"} displayRecords={[{},{}]} heading={false} extraButton={"Add Executive"} editable={true} /></>
  )
}

export default ExecutiveManagement