import React, { useEffect, useState } from 'react'
import "./ShopPage.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {BlackAdd, PopularProductBottom } from '../../../Assets/Images/ExportSvg';
import Fan from "../../../Assets/TempImages/Images/Fan.png"

const ShopPage = ({tileDetail,dataFromBackend}) => {
  console.log(tileDetail,"tileDetail")
    const popularProducts=[
        {
          "ProductID": 1,
          "ProductName": "Samsung LED TV",
          "colors": "#FFFFFF,#FF5733,#33FF57,#3357FF,#FFFF00",
          "BrandID": 1,
          "BrandName": "Samsung",
          "CategoryID": 1,
          "CategoryName": "Television",
          "SubcategoryID": 1,
          "SubcategoryName": "LED TV",
          "Price": "25000.00",
          "Stock": 50,
          "DealerCount": 2,
          "TotalQuantityOrdered": "2",
          "description": "text discription1",
          "Image": [],
          "specification": {
            "spec1": "value",
            "spec2": "value",
            "spec3": "value",
            "spec4": "value"
          },
          "brandimg": "brandimage1"
        },
        {
          "ProductID": 2,
          "ProductName": "Sony Smart TV",
          "colors": "#FFFFFF,#FF5733,#33FF57,#3357FF,#FFFF00",
          "BrandID": 2,
          "BrandName": "Sony",
          "CategoryID": 1,
          "CategoryName": "Television",
          "SubcategoryID": 2,
          "SubcategoryName": "Smart TV",
          "Price": "45000.00",
          "Stock": 30,
          "DealerCount": 2,
          "TotalQuantityOrdered": "2",
          "description": "text discription2",
          "Image": [],
          "specification": {
            "spec1": "value",
            "spec2": "value",
            "spec3": "value",
            "spec4": "value"
          },
          "brandimg": "brandimage2"
        },
        {
          "ProductID": 5,
          "ProductName": "Philips Front Load Washing Machine",
          "colors": "#FFFFFF,#FF5733,#33FF57,#3357FF,#FFFF00",
          "BrandID": 5,
          "BrandName": "Philips",
          "CategoryID": 3,
          "CategoryName": "Washing Machine",
          "SubcategoryID": 5,
          "SubcategoryName": "Front Load",
          "Price": "22000.00",
          "Stock": 15,
          "DealerCount": 2,
          "TotalQuantityOrdered": "2",
          "description": "text discription5",
          "Image": [],
          "specification": {
            "spec1": "value",
            "spec2": "value",
            "spec3": "value",
            "spec4": "value"
          },
          "brandimg": "brandimage5"
        },
        {
          "ProductID": 7,
          "ProductName": "Samsung Convection Oven",
          "colors": "#FFFFFF,#FF5733,#33FF57,#3357FF,#FFFF00",
          "BrandID": 1,
          "BrandName": "Samsung",
          "CategoryID": 4,
          "CategoryName": "Microwave Oven",
          "SubcategoryID": 7,
          "SubcategoryName": "Convection Oven",
          "Price": "12000.00",
          "Stock": 40,
          "DealerCount": 2,
          "TotalQuantityOrdered": "2",
          "description": "text discription7",
          "Image": [],
          "specification": {
            "spec1": "value",
            "spec2": "value",
            "spec3": "value",
            "spec4": "value"
          },
          "brandimg": "brandimage1"
        },
        {
          "ProductID": 3,
          "ProductName": "LG Single Door Fridge",
          "colors": "#FFFFFF,#FF5733,#33FF57,#3357FF,#FFFF00",
          "BrandID": 3,
          "BrandName": "LG",
          "CategoryID": 2,
          "CategoryName": "Refrigerator",
          "SubcategoryID": 3,
          "SubcategoryName": "Single Door",
          "Price": "18000.00",
          "Stock": 20,
          "DealerCount": 1,
          "TotalQuantityOrdered": "1",
          "description": "text discription3",
          "Image": [],
          "specification": {
            "spec1": "value",
            "spec2": "value",
            "spec3": "value",
            "spec4": "value"
          },
          "brandimg": "brandimage3"
        },
        {
          "ProductID": 6,
          "ProductName": "LG Top Load Washing Machine",
          "colors": "#FFFFFF,#FF5733,#33FF57,#3357FF,#FFFF00",
          "BrandID": 3,
          "BrandName": "LG",
          "CategoryID": 3,
          "CategoryName": "Washing Machine",
          "SubcategoryID": 6,
          "SubcategoryName": "Top Load",
          "Price": "16000.00",
          "Stock": 25,
          "DealerCount": 1,
          "TotalQuantityOrdered": "1",
          "description": "text discription6",
          "Image": [],
          "specification": {
            "spec1": "value",
            "spec2": "value",
            "spec3": "value",
            "spec4": "value"
          },
          "brandimg": "brandimage3"
        },
        {
          "ProductID": 8,
          "ProductName": "Sony Grill Oven",
          "colors": "#FFFFFF,#FF5733,#33FF57,#3357FF,#FFFF00",
          "BrandID": 2,
          "BrandName": "Sony",
          "CategoryID": 4,
          "CategoryName": "Microwave Oven",
          "SubcategoryID": 8,
          "SubcategoryName": "Grill Oven",
          "Price": "9000.00",
          "Stock": 35,
          "DealerCount": 1,
          "TotalQuantityOrdered": "1",
          "description": "text discription8",
          "Image": [],
          "specification": {
            "spec1": "value",
            "spec2": "value",
            "spec3": "value",
            "spec4": "value"
          },
          "brandimg": "brandimage2"
        }
      ]
    
    const settingsManual = {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        speed: 400,
        autoplay: false,
        className: "slider-custom",
      };
      const [productData, setProductData] = useState(null)


      // for Product data
  useEffect(() => {

    let name = "product"
    let endPoint = "ProductDetails"
    let reqFor = ""
    let port = "https://marina-world-order.onrender.com"


    if (name !== "" && endPoint !== "" && port !== "") {


      const Url = `${port}/${endPoint}?reqFor=${reqFor}`
      fetch(Url, {
        method: 'GET',  // Specify that this is a POST request
        headers: {
          'Content-Type': 'application/json',  // Send JSON data
        },
        // Convert the JavaScript object to JSON string
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('ProductData:', data.data);
          setProductData(data.data)

        })
        .catch((error) => {
          console.error('Error:', error);
          //   setMessage('Failed to post data.');
        });
    }
    }, [])
  return (
    <div className='card loginCard'>
      <div className="card-body">

      
      {/* <div
              className="ion-margin-vertical"
              style={{ padding: "0rem 2rem" }}
            > */}
              <Slider {...settingsManual}>
              {tileDetail && "shopPage" in tileDetail && "popularProduct" in tileDetail.shopPage ?
              Object.keys(tileDetail.shopPage.popularProduct)?.map((productKey, i) => {
                  let productid =tileDetail.shopPage.popularProduct[productKey]
                  let product=productData.filter((item)=>item.ProductID===productid)[0]
                  // console.log(product)
                 return(
                   <div
                    key={i}
                    className="product-slider-card"
                    // onClick={() => navigateToDetails(product.ProductID)}
                  >
                    <div className="d-flex justify-content-between w-100">
                      <div className="">
                        <img
                          src={`data:${product.brandImg?.mimetype};base64,${product.brandImg?.buffer}`}
                          alt={product.BrandName}
                          className="brand-logo-img img-fluid"
                        />
                      </div>

                        <div id="product-image-container">

                      <img
                        src={`data:${product.images[(product?.images)?.length-1]?.mimetype};base64,${product.images[(product?.images)?.length-1]?.fileLocation}`}
                        alt={product.ProductName}
                        className="product-image img-fluid"
                        />
                        </div>
                    </div>

                    <div className="popularproduct-details">
                      <p style={{ margin: "0" }}>{product.ProductName}</p>
                    </div>

                    <div
                      className="add-to-cart"
                    //   onClick={(e) => handleAddToCart(e, product)}
                    >
                        <img src={BlackAdd} alt="add" width={30} className="add-cart-btn"/>                     
                    </div>
                  </div>)
}):

                dataFromBackend && "popularProduct" in dataFromBackend && Object.keys(dataFromBackend.popularProduct)?.map((productKey, i) => {
                  let product =dataFromBackend.popularProduct[productKey]
                 return(
                   <div
                    key={i}
                    className="product-slider-card"
                    // onClick={() => navigateToDetails(product.ProductID)}
                  >
                    <div className="d-flex justify-content-between w-100">
                      <div className="">
                        <img
                          src={`data:${product.BrandImage?.mimetype};base64,${product.BrandImage?.buffer}`}
                          alt={product.BrandName}
                          className="brand-logo-img img-fluid"
                        />
                        
                      </div>

                      <div id="product-image-container">
                      <img
                        src={`data:${product?.image[(product?.image)?.length-1]?.mimetype};base64,${product?.image[(product?.image)?.length-1]?.fileLocation}`}
                        alt={product.ProductName}
                        className="product-image img-fluid"
                        />
                        </div>
                    </div>

                    <div className="popularproduct-details">
                      <p style={{ margin: "0" }}>{product.ProductName}</p>
                    </div>

                    <div
                      className="add-to-cart"
                    //   onClick={(e) => handleAddToCart(e, product)}
                    >
                        <img src={BlackAdd} alt="add" width={30} className="add-cart-btn"/>
                      
                    </div>
                  </div>)
})}
              </Slider>
            {/* </div> */}
            <img src={PopularProductBottom} alt="bottom section " className='w-100 mt-2' />
            </div>
    </div>
  )
}

export default ShopPage
