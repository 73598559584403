import React, { useEffect, useMemo, useState } from 'react'
import "./HomePage.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderData from "../Data/Data/HomeData";

import fts from "../../../Assets/TempImages/Images/fts.png";
import truck from "../../../Assets/TempImages/Images/truck.png";
import newlyLaunched from "../../../Assets/TempImages/Images/newlyLaunched.png";
import newlyLaunchedLogo from "../../../Assets/TempImages/Images/newlyLaunchedLogo.png";
import { LogoSection, Medal, RightArrow } from '../../../Assets/Images/ExportSvg';

const HomePageTile = ({ tileDetail, dataFromBackend }) => {
  console.log(dataFromBackend, "dataFromBackend")
  // using useMemo Hook
  const baseSettings = useMemo(
    () => ({

      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    }),
    []
  );

  // slick slider settings
  const settingsAuto = {
    ...baseSettings,
    infinite: true,
    speed: 400,
    autoplay: true,
    fade: true,
    cssEase: "linear",
    autoplaySpeed: 1900,
  };

  const settingsMainAuto = {
    ...baseSettings,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const settingsAchieveAuto = {
    ...baseSettings,
    infinite: true,
    speed: 700,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const settingsManual = {
    ...baseSettings,
    infinite: false,
    slidesToScroll: 3,
    speed: 400,
    slidesToShow: 5,
    autoplay: false,
    responsive: [
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 4,
        },
      },
    ],


  };

  const [dealerData, setDealerData] = useState(null)
  const [employeeData, setEmployeeData] = useState(null)
  const [productData, setProductData] = useState(null)
  // for Dealer Data
  useEffect(() => {

    let name = "dealer"
    let endPoint = "userDetails"
    let reqFor = "allDealers"
    let port = "https://marina-world-users.onrender.com"

    if (name !== "" && endPoint !== "" && port !== "") {


      const Url = `${port}/${endPoint}?reqFor=${reqFor}`
      fetch(Url, {
        method: 'GET',  // Specify that this is a POST request
        headers: {
          'Content-Type': 'application/json',  // Send JSON data
        },
        // Convert the JavaScript object to JSON string
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('DealerData:', data.data);
          setDealerData(data.data)
        })
        .catch((error) => {
          console.error('Error:', error);
          //   setMessage('Failed to post data.');
        });
    }



  }, [])
  // for Employee data
  useEffect(() => {
    let name = "executive"
    let endPoint = "executives"
    let reqFor = "executives"
    let port = "https://marina-world-users.onrender.com"

    if (name !== "" && endPoint !== "" && port !== "") {

      const Url = `${port}/${endPoint}?reqFor=${reqFor}`
      fetch(Url, {
        method: 'GET',  // Specify that this is a POST request
        headers: {
          'Content-Type': 'application/json',  // Send JSON data
        },
        // Convert the JavaScript object to JSON string
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('employee:', data.data);
          setEmployeeData(data.data)
        })
        .catch((error) => {
          console.error('Error:', error);
          //   setMessage('Failed to post data.');
        });
    }



  }, [])

  // for Product data
  useEffect(() => {

    let name = "product"
    let endPoint = "ProductDetails"
    let reqFor = ""
    let port = "https://marina-world-order.onrender.com"


    if (name !== "" && endPoint !== "" && port !== "") {


      const Url = `${port}/${endPoint}?reqFor=${reqFor}`
      fetch(Url, {
        method: 'GET',  // Specify that this is a POST request
        headers: {
          'Content-Type': 'application/json',  // Send JSON data
        },
        // Convert the JavaScript object to JSON string
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('ProductData:', data.data);
          setProductData(data.data)

        })
        .catch((error) => {
          console.error('Error:', error);
          //   setMessage('Failed to post data.');
        });
    }



  }, [])
  const newLaunchedImage=(id)=>{
    let product=productData.filter((item)=>item.ProductID===id)[0]
    // console.log(product)
    //  return  `data:${product.images[product.images.length-1]?.mimetype};base64,${product.images[product.images.length-1]?.fileLocation}`
    return  `data:${product.images[4]?.mimetype};base64,${product.images[4]?.fileLocation}`
   
  }
const newLaunchedBrandImage=(id)=>{
  if(productData!==null){
  let product=productData.filter((item)=>item.ProductID===id)[0]
  console.log(product.ProductID)
  return  `data:${product.brandImg?.mimetype};base64,${product.brandImg?.buffer}`
  }
 
}


  return (
    <div className='card loginCard' key={dataFromBackend || tileDetail}>
      <div className="card-body">


        <div className="dashboard-container page-padding ">
          {/* main slider */}
          <div className="main-slider">
            {"homePage" in tileDetail && "mainSlider" in tileDetail?.homePage ?
              <Slider {...settingsMainAuto}>
                {tileDetail?.homePage?.mainSlider.map((item, index) => (
                  <div key={index} className="slide-item">
                    <img
                      src={URL.createObjectURL(item)}
                      alt={item.name}
                      className="slider-image"
                    />
                  </div>
                ))}
              </Slider>
              :

              <Slider {...settingsMainAuto}>
                {dataFromBackend && "mainSlider" in dataFromBackend && dataFromBackend?.mainSlider.map((item, index) => (
                  <div key={index} className="slide-item">
                    <img
                      src={`data:${item?.mimetype};base64,${item?.bs64}`}
                      alt={`Slide ${index}`}
                      className="slider-image"
                    />
                  </div>
                ))}
              </Slider>}
          </div>

          {/* achiever section slider */}
          <div className="achiever-section" style={{ color: "#000" }}>
            <div className="top-achiever">
              {"homePage" in tileDetail && "achieverSlider" in tileDetail?.homePage ?
                <Slider {...settingsAchieveAuto}>
                  {Object.keys(tileDetail?.homePage?.achieverSlider).map((item, index) => {
                    let dealerId=tileDetail?.homePage?.achieverSlider[item]
                    let dealer=dealerData.filter((item) => item["UserID"] === dealerId)[0]
                    
                    return (<div
                      key={index}
                      className="achiever-card"
                      style={{ position: "relative" }}
                    >
                      <div className="achiever-info no-text-wrap ">
                        <div className="ion-flex">
                          <h6
                            style={{ margin: "10px 5px", fontSize: "0.9rem" }}
                          >
                            Top Achiever
                          </h6>



                        </div>

                        <div className="achiever-rank">
                          <img src={Medal} alt="medal" className='trophy-icon' width={23} />

                          <span>
                            {index + 1}
                            <sup>st</sup>
                          </span>
                        </div>
                        <h6 style={{ margin: "5px" }}>{dealer?.Name}</h6>
                        <p style={{ fontSize: "11px", margin: "5px" }}>
                          {dealer?.businessName}
                        </p>
                      </div>
                      <div className="achiever-image" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: "1.5rem" }}>
                        <div
                          className="flex-property"
                          style={{ position: "absolute", top: "1.3rem" }}
                        >
                          <p
                            href="/leaderboard"
                            className="no-text-wrap"
                            style={{
                              fontSize: "0.5rem",
                              color: "grey",
                              position: "absolute",
                              top: "0rem",
                              left: "1rem",
                            }}
                          >
                            View All
                          </p>
                          <img src={RightArrow} alt="rightArrow" width={"7rem"} style={{
                            position: "absolute",
                            top: "0.1rem",
                            left: "3.1rem",
                          }} />
                        </div>
                        <div id='dealer-image-container'>
                        <img
                          className="img-fluid"
                          src={`data:${dealer?.image?.filetype};base64,${dealer?.image?.data}`}
                          alt={dealer.name} />
                      </div>
                          </div>
                    </div>)
                  })}
                </Slider> :
                <Slider {...settingsAchieveAuto}>
                  {dataFromBackend && "achieverSlider" in dataFromBackend && Object.keys(dataFromBackend?.achieverSlider).map((item, index) => {
                    let dealerData = dataFromBackend?.achieverSlider?.[item]
                    return (<div
                      key={index}
                      className="achiever-card"
                      style={{ position: "relative" }}
                    >
                      <div className="achiever-info no-text-wrap ">
                        <div className="ion-flex">
                          <h6
                            style={{ margin: "10px 5px", fontSize: "0.9rem" }}
                          >
                            Top Achiever
                          </h6>



                        </div>

                        <div className="achiever-rank">
                          <img src={Medal} alt="medal" className='trophy-icon' width={23} />

                          {/* <IonIcon
                            style={{ margin: "5px" }}
                            icon={trophyOutline}
                            className="trophy-icon"
                            /> */}
                          <span>
                            {index + 1}
                            <sup>st</sup>
                          </span>
                        </div>
                        <h6 style={{ margin: "5px" }}>{dealerData?.Name}</h6>
                        <p style={{ fontSize: "11px", margin: "5px" }}>
                          {dealerData?.businessName}
                        </p>
                      </div>
                      <div className="achiever-image" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: "1.5rem" }}>
                        <div
                          className="flex-property"
                          style={{ position: "absolute", top: "1.3rem" }}
                        >
                          <p
                            href="/leaderboard"
                            className="no-text-wrap"
                            style={{
                              fontSize: "0.5rem",
                              color: "grey",
                              position: "absolute",
                              top: "0rem",
                              left: "1rem",
                            }}
                          >
                            View All
                          </p>
                          
                          <img src={RightArrow} alt="rightArrow" width={"7rem"} style={{
                            position: "absolute",
                            top: "0.1rem",
                            left: "3.1rem",
                          }} />
                        </div>
                      
                        <div id='dealer-image-container'>
                        <img
                          className="img-fluid"
                          src={`data:${dealerData?.image?.filetype};base64,${dealerData?.image?.data}`}
                          alt={dealerData.name} />
                          </div>
                      </div>
                    </div>)
                  })}
                </Slider>
              }
            </div>

            <div className="employee-card">
            {"homePage" in tileDetail && "employeeSlider" in tileDetail?.homePage ?
                 <Slider {...settingsAuto} className="slider-content">
                 {Object.keys(tileDetail?.homePage?.employeeSlider).map((item, index) => {
                   let employeeId=tileDetail?.homePage?.employeeSlider[item]
                   console.log(employeeData,"datatatattata")
                   let employee=employeeData.filter((item) => item["ExecutiveID"] === employeeId)[0]
                   console.log(employee)
                   return (<div key={index} className="employee-section">
                     <div className="achiever-info">
                       <h6 style={{ margin: "0", fontSize: "0.8rem" }}>
                         Employee of the Month
                       </h6>
                     </div>
                     <div
                       style={{
                         display: "flex",
                         justifyContent: "flex-end",
                         width: "100%",
                       }}
                     >
                      <div id='image-container'>

                       <img
                         className="img-fluid"
                         src={`data:${employee?.Image?.filetype};base64,${employee?.Image?.data}`}
                         alt={employee.ExecutiveName}
                         />
                         </div>
                     </div>
                     <div className="achiever-info">
                       <p
                         style={{
                           margin: "0",
                           padding: "0",
                           fontSize: "0.8rem",
                         }}
                       >
                         {employee.ExecutiveName}
                       </p>
                       <h6
                         style={{
                           margin: "0",
                           padding: "0",
                           fontSize: "10px",
                         }}
                       >
                         {employee.position}
                       </h6>
                     </div>
                   </div>)
                 })}
               </Slider>:
              <Slider {...settingsAuto} className="slider-content">
                {dataFromBackend && "employeeSlider" in dataFromBackend && Object.keys(dataFromBackend?.employeeSlider).map((item, index) => {
                  let employeeData = dataFromBackend?.employeeSlider?.[item]
                  return (<div key={index} className="employee-section">
                    <div className="achiever-info">
                      <h6 style={{ margin: "0", fontSize: "0.8rem" }}>
                        Employee of the Month
                      </h6>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      <div id='image-container'>
                      <img
                        className="img-fluid"
                        src={`data:${employeeData?.image?.filetype};base64,${employeeData?.image?.data}`}
                        alt={employeeData.ExecutiveName}
                        />
                        </div>
                    </div>
                    <div className="achiever-info">
                      <p
                        style={{
                          margin: "0",
                          padding: "0",
                          fontSize: "0.8rem",
                        }}
                      >
                        {employeeData.ExecutiveName}
                      </p>
                      <h6
                        style={{
                          margin: "0",
                          padding: "0",
                          fontSize: "10px",
                        }}
                      >
                        {employeeData.position}
                      </h6>
                    </div>
                  </div>)
                })}
              </Slider>}
            </div>
          </div>



          {/* Logos Slider */}
          {/* <div>
            <div className="logos-slider-container">
              <Slider {...settingsManual}>
                {sliderData?.logos?.map((item, index) => (
                  <div className="brand-logo-slider"key={index}>
                    <div className="brand-logo-block">
                    <img src={item.logo} alt="" />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            </div> */}
          <img src={LogoSection} alt="" w-100 />

          {/* updates section */}
          <div className="announcements-trips-section">

            <div className="announcement-column">
              <div
                className="announcement-card-product"
                style={{ background: "#D65A31", color: "white" }}
              >
                <p
                  style={{ fontSize: "0.7rem", margin: "0" }}
                  className="no-text-wrap"
                >
                  Newly Launched Product
                </p>
                <div className="d-flex justify-content-space">
                  <div>
                  {"homePage" in tileDetail && "newLaunchedProduct" in tileDetail.homePage
                    && "product" in tileDetail.homePage.newLaunchedProduct ?
                    <img
                    style={{ width: "2rem",height:"2rem" }}
                    src={newLaunchedBrandImage(tileDetail.homePage.newLaunchedProduct.product)}
                    alt="Fan 1"
                    className="fan img-fluid"
                  />:dataFromBackend 
                    && "newLaunchedProduct" in dataFromBackend 
                    && "product" in dataFromBackend.newLaunchedProduct
                    && 
                    <img
                      style={{ width: "2rem" ,height:"2rem" }}
                      src={`data:${dataFromBackend.newLaunchedProduct.product.BrandImage?.mimetype};base64,${dataFromBackend.newLaunchedProduct.product.BrandImage?.buffer}`}
                      alt="Fan 1"
                      className="fan img-fluid"
                    />
                    }

                    <p
                      style={{
                        fontSize: "0.5rem",
                        margin: "0",
                        fontWeight: "lighter",
                      }}
                    >
                      {"homePage" in tileDetail && "newLaunchedProduct" in tileDetail.homePage
                    && "product" in tileDetail.homePage.newLaunchedProduct ?
                    productData.filter((item) => item["ProductID"] === tileDetail.homePage.newLaunchedProduct?.product)[0]?.ProductName
                      
                      :dataFromBackend 
                    && "newLaunchedProduct" in dataFromBackend 
                    && "product" in dataFromBackend.newLaunchedProduct
                    && dataFromBackend.newLaunchedProduct.product?.ProductName}
                     
                    </p>
                    <div className="view-details my-1">
                      <label
                        style={{ fontSize: "8px", color: "white", marginTop: "0" }}
                        className="details-link"
                      >
                        View Detail <span className="arrow">→</span>
                      </label>
                    </div>
                  </div>

                  <div
                    style={{ width: "6rem", height: "auto", maxWidth: "100%", textAlign: "end" }}
                    className="fan-images "
                  >
                    {"homePage" in tileDetail && "newLaunchedProduct" in tileDetail.homePage
                    && "product" in tileDetail.homePage.newLaunchedProduct ?
                    <div id='product-image-container mx-auto'>
                    <img
                    style={{ width: "4rem" }}
                    src={newLaunchedImage(tileDetail.homePage.newLaunchedProduct.product)}
                    alt="Fan 1"
                    className="fan img-fluid"
                    />
                    </div>
                  :dataFromBackend 
                    && "newLaunchedProduct" in dataFromBackend 
                    && "product" in dataFromBackend.newLaunchedProduct
                    && 
                    <div id='product-image-container mx-auto'>
                    <img
                      style={{ width: "4rem" }}
                      src={`data:${dataFromBackend.newLaunchedProduct.product.image[dataFromBackend.newLaunchedProduct.product.image.length-1]?.mimetype};base64,${dataFromBackend.newLaunchedProduct.product.image[dataFromBackend.newLaunchedProduct.product.image.length-1]?.fileLocation}`}
                      alt="Fan 1"
                      className="fan img-fluid"
                    />
                    </div>
                    }
                  </div>
                </div>



              </div>

              <div
                className="announcement-card"
                style={{ background: "black", color: "white" }}
              >
                <h6 style={{ margin: "0rem" }} className="no-text-wrap">
                  Out for Delivery
                </h6>
                <p style={{ marginBottom: "19px", marginTop: "10px" }}>will be there in 15 minutes</p>
                <img src={truck} alt="" />
              </div>
            </div>



            <div className="trips-section loginCard">
              <h6 style={{ margin: 0 }}>Upcoming</h6>
              <h2 style={{ marginTop: "0.6rem" }}>FTS Trips </h2>
              {"homePage" in tileDetail && "ftsScheme" in tileDetail?.homePage ?
                <img
                className='img-fluid mx-auto'
                  style={{ height: "7rem",width:"7rem" }}
                  src={URL.createObjectURL(tileDetail?.homePage?.ftsScheme?.image)}
                /> :
                <img
                className='img-fluid mx-auto'
                  style={{ height: "7rem",width:"7rem" }}
                  src={`data:${dataFromBackend?.ftsScheme?.image.mimetype};base64,${dataFromBackend?.ftsScheme?.image?.bs64}`}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePageTile
