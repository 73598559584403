import React, { useContext, useEffect, useState } from 'react'
import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { context } from '../../Context/Context';

const StatusChange = ({ orderRec }) => {
    const {snackbarMessage, setSnackbarMessage,snackbarOpen, setSnackbarOpen} = useContext(context);
    const [status,setStatus]=useState([])
    const [selectedStatus,setSelectedStatus]=useState("")
    const [selectedVehicle,setSelectedVehicle]=useState("")
    const [vehicleData,setVehicleData]=useState([])
    useEffect(()=>{
        setStatus(orderRec[0]?.OrderStatus === "Pending" ? ["Accepted", "Rejected"] : ["Ongoing", "Dispatched", "Completed"])
    },[orderRec])

    const handleStatusChange = () => {
        let  dataSend={}
        if(selectedStatus===""){
            setSnackbarMessage("Select Status");
            setSnackbarOpen(true)
            return;
        }
        if(selectedStatus==="Dispatched"){
            if(selectedVehicle===""){
                setSnackbarMessage("Select Vehicle");
                setSnackbarOpen(true)
                return; 
            }
            else{
                dataSend = { orderId: orderRec[0]?.OrderID, status: selectedStatus, UserID: orderRec[0]?.UserID,vehichleNo:selectedVehicle }
            }
        }
        else{
            dataSend = { orderId: orderRec[0]?.OrderID, status: selectedStatus, UserID: orderRec[0]?.UserID }
        }
            
            const Url = `https://marina-world-order.onrender.com/updateStatus`
            fetch(Url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json', // Headers
                },
                body: JSON.stringify(dataSend),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log('Success:', data);
                    if(data.message==="Order status updated successfully"){
                        setSelectedStatus("")
                        setSelectedVehicle("")
                        setSnackbarMessage(data.message)
                        setSnackbarOpen(true) 
                    }

                    setSnackbarMessage(data.message)
                    setSnackbarOpen(true)
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setSnackbarMessage(error)
                    setSnackbarOpen(true)
                });
        
    }

  useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await fetch('https://marina-world-users.onrender.com/fleetx');
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setVehicleData(result.vehicles)
        } catch (error) {
        }
      };
  
      fetchData();
  }, []);


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    return (
        <>
            <div className="card myCard">
                <div className="card-body">
                    
                    <div className=" text-center">
                        <label style={{ fontSize: "1rem" }}>Update Status</label>
                    </div>
                    <select class="form-select w-100 my-3" value={selectedStatus} onChange={(e)=>setSelectedStatus(e.target.value)} style={{ color:selectedStatus!==""?"#fff":"#9B9D9F"  }} >
                        <option value={""}>Select Status</option>
                        {status.map((val,index) => (
                            <option value={val} key={index} >{val }</option>
                        ))}


                    </select>
                    {selectedStatus==="Dispatched"?
                    <select class="form-select w-100 my-3" value={selectedVehicle} onChange={(e)=>setSelectedVehicle(e.target.value)} style={{ color:selectedVehicle!==""?"#fff":"#9B9D9F"  }} >
                    <option value={""}>Select Vehicle</option>
                    {vehicleData.map((val,index) => (
                        <option value={val.vehicleNumber} key={index} >{val.vehicleNumber }</option>
                    ))}


                </select>:null}

                    <div className="d-flex align-bottom justify-content-center w-100 align-item-center mt-4 mb-3">
                    <div className={`btn px-3 mx-1 themeButton`} onClick={handleStatusChange} >Update Status</div>
                    <div className="btn whiteThemeButton px-4 mx-1" style={{ border: 0 }} onClick={()=>{setSelectedStatus("");setSelectedVehicle("")}}>Cancel</div>
                </div>

                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                message={snackbarMessage}
                action={action}
            />
        </>
    )
}

export default StatusChange
