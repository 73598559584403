import React, { useState, useEffect, useContext } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import "./Layout.css"
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { context } from "../Context/Context";
import Loading from "../Loading/Loading";


const Layout = ({ children }) => {
  const location = useLocation();
  const { active, setActive, loading } = useContext(context);

  console.log(loading, "loading")


  const handleClick = (item) => {
    setActive(item);
    localStorage.setItem("activeItem", item)
  };


  return (
    <>
      <div className="d-xl-flex">
        <Sidebar handleClick={handleClick} active={active} setActive={setActive} />
        <div className="content">
          <Navbar handleClick={handleClick} active={active} />
          <TransitionGroup>
            <CSSTransition
              key={location.pathname}
              classNames="fade"
              timeout={300}
            >

              <div className="layoutContainer" key={active} style={{overflowY:loading?"hidden":""}}>
                {loading && <Loading />}
                
                  {children}
                    <Outlet/>
                



              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </>
  );
};

export default Layout;
