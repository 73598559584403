import React from 'react'
import Form from '../Forms/Form'
import Tables from '../Tables/Tables'
import PageHeader from './PageHeader'

const CategoryManagement = () => {
  return (
    <>
    <PageHeader/>
    <div className="row">
      <div className="col-5">
        <Form formName={"categoryManagement"} />
      </div>
      <div className="col-7">
          <Tables TableName={"categoryManagement"} displayRecords={[]}/>
        </div>
    </div>
  </>
  )
}

export default CategoryManagement
