// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDuBUzwuD62qbdP_7rCbGkNxLIkZhmD3oU",
  authDomain: "marina-world-8c19e.firebaseapp.com",
  projectId: "marina-world-8c19e",
  storageBucket: "marina-world-8c19e.firebasestorage.app",
  messagingSenderId: "959297511809",
  appId: "1:959297511809:web:5d82b5752fe5d34ef6c880",
  measurementId: "G-FMN2MKK1S3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const generateToken=async()=>{
    const permission=await Notification.requestPermission()
    console.log(permission)
    if(permission==="granted")
    {
        const token =await getToken(messaging,{
            vapidKey:"BNYbd2TJY0mxVxhCgP3FHdaQYHtqiRsiQcJ6oN6bxYw2LF2_LsDYECfgfLv91oqGHIST-Z9PtbDg6Ni6wS0Umok"
        })
        console.log(token,"====");
        
       return {token};
    }
    else{
        return null
    }
}

export { messaging ,generateToken};