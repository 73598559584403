import React, { useContext } from 'react'
import { Cross } from '../../Assets/Images/ExportSvg'
import { useNavigate } from 'react-router-dom'
import { context } from '../Context/Context';

const PageHeader = ({back}) => {
  const {setFormData,setActive} = useContext(context);
    const navigate=useNavigate();
    const handleNavigate=()=>{

    }
  return (
    <div className='text-end my-1'>
      <img src={Cross} alt="Cross icon" width={30} onClick={()=>{setFormData({});setActive("Dashboard");
      back?navigate(-1):
      navigate("/layout/Dashboard")}}/>
    </div>
  )
}

export default PageHeader
