import React from 'react'

const ProfileSection = ({selectedExecutive}) => {
    function formatDate(dateString) {
        if(dateString==undefined){
            return "";
        }
        else{

            const months = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            
            const date = new Date(dateString);
            const day = date.getUTCDate();
            const month = months[date.getUTCMonth()];
            const year = date.getUTCFullYear();
            
            return `${day} - ${month} - ${year}`;
        }
      }
      const profileData=[{label:"ID",name:"ExecutiveID"},
        {label:"Name",name:"ExecutiveName"},
        {label:"Position",name:"position"},
        {label:"Date of Birth",name:"dateOFBirth"},
        {label:"Address",name:"address"},
        {label:"City",name:"city"},
        {label:"State",name:"state"},
        {label:"Email",name:"Email"},
        {label:"Phone",name:"PhoneNumber"},
        {label:"Alternate No.",name:"alternateNo"}
      ]
  return (
    <>
    <div className="d-flex justify-content-center align-items-center py-3">
    <label style={{fontSize:"1rem"}} className="mb-0">Profile</label>
</div>
    <div className="d-flex justify-content-center align-items-center py-3">
        <img 
        style={{borderRadius:".2cm"}}        
        src={`data:${selectedExecutive?.Image?.filetype};base64,${selectedExecutive?.Image?.data}`}
        alt="" 
        width={"50rem"}/>
    </div>
    {profileData.map((obj,index)=>(
          <div className="row mx-5 text-start" style={{fontSize:"1rem"}}  key={index}>
          <div className="col-6 my-2"><label className="mb-0">{obj.label}</label></div>
          <div className="col-6 my-2"> <h5 className="mb-0">{obj.name==="dateOFBirth"?formatDate(selectedExecutive?.dateOFBirth):selectedExecutive?.[obj.name]}</h5></div>
      </div>

    ))}
    {/* <div className="row mx-5 text-start">
        <div className="col-6 my-3"><label className="mb-0">ID</label></div>
        <div className="col-6 my-3"> <h5 className="mb-0">{selectedExecutive?.ExecutiveID}</h5></div>
    </div>
    <div className="row mx-5 text-start">
        <div className="col-6 my-3"> <h5 className="mb-0">Name</h5></div>
        <div className="col-6 my-3"> <h5 className="mb-0">{selectedExecutive?.ExecutiveName}</h5></div>
    </div>
    
    <div className="row mx-5 text-start">
        <div className="col-6 my-3"><h5 className="mb-0">Position</h5></div>
        <div className="col-6 my-3"><h5 className="mb-0">{selectedExecutive?.position}</h5></div>
    </div>
    <div className="row mx-5 text-start">
        <div className="col-6 my-3"><h5 className="mb-0">Date of Birth</h5></div>
        <div className="col-6 my-3"><h5 className="mb-0">{formatDate(selectedExecutive?.dateOFBirth)}</h5></div>
    </div>
    <div className="row mx-5 text-start">
        <div className="col-6 my-3"> <h5 className="mb-0">Address</h5></div>
        <div className="col-6 my-3"><h5 className="mb-0">{selectedExecutive?.address}</h5></div>
    </div>
         
    <div className="row mx-5 text-start">
        <div className="col-6 my-3"><h5 className="mb-0">City</h5></div>
        <div className="col-6 my-3"><h5 className="mb-0">{selectedExecutive?.city}</h5></div>
    </div>
         
    <div className="row mx-5 text-start">
        <div className="col-6 my-3"><h5 className="mb-0">State</h5></div>
        <div className="col-6 my-3"><h5 className="mb-0">{selectedExecutive?.state}</h5></div>
    </div>
          
    <div className="row mx-5 text-start">
        <div className="col-6 my-3"><h5 className="mb-0">Email</h5></div>
        <div className="col-6 my-3"><h5 className="mb-0">{selectedExecutive?.Email}</h5></div>
    </div>
    <div className="row mx-5 text-start">
        <div className="col-6 my-3"><h5 className="mb-0">Phone</h5></div>
        <div className="col-6 my-3"><h5 className="mb-0">{selectedExecutive?.PhoneNumber}</h5></div>
    </div>
         
    <div className="row mx-5 text-start">
        <div className="col-6 my-3"><h5 className="mb-0">Alternate No.</h5></div>
        <div className="col-6 my-3"><h5 className="mb-0">{selectedExecutive?.alternateNo}</h5></div>
    </div> */}
          
    </>
  )
}

export default ProfileSection
