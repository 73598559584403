import React, { useContext, useEffect, useState } from 'react'
import { context } from '../Context/Context';

const NotificationActivity = () => {
    const FormateDate=(dateString)=>{
        if (dateString == undefined || dateString==="") {
            return "";
        }
        else {

            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            const date = new Date(dateString);
            const day = date.getUTCDate();
            const month = months[date.getUTCMonth()];
            const year = date.getUTCFullYear();
            let hours = date.getHours();
            let minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            minutes = minutes.toString().padStart(2, '0');
         
                return `${day}-${month}-${year}`;
            

        }

    }
     const {NotificationArray,setNotificationArray} = useContext(context);
      const [selectedDate, setSelectedDate] = useState(() => {
           // Set today's date as default
           const today = new Date();
           return today.toISOString().split("T")[0]; // Format: YYYY-MM-DD
         });
     
         const EditableDate = ({selectedDate, setSelectedDate}) => {
             const [isEditing, setIsEditing] = useState(false);
           
             // Handle date input change
             const handleDateChange = (e) => {
               setSelectedDate(e.target.value);
             };
           
             // Toggle between label and input
             const toggleEdit = () => {
               setIsEditing((prev) => !prev);
             };
           
             return (
               <div>
                 {isEditing ? (
                   <input
                     type="date"
                     className='form-control'
                     id='small-form-control'
                     value={selectedDate}
                     onChange={handleDateChange}                
                     onBlur={toggleEdit} // Switch back to label when focus is lost
                     autoFocus
                   />
                 ) : (
                   <label onClick={toggleEdit} style={{ cursor: "pointer" }}>
                     {selectedDate}
                   </label>
                 )}
               </div>
             );
           };
           useEffect(() => {
              
               const endPoint="fetchActivities"
               const Url = `https://marina-world-users.onrender.com/${endPoint}`
               const fetchData = async () => {
                   try {
                       const response = await fetch(Url);
                       if (!response.ok) {
                           throw new Error(`HTTP error! status: ${response.status}`);
                       }
                       const result = await response.json();
                       console.log(result)
                       if(result.msg==="no orders found"){
                        setNotificationArray([])
                       }
                       else if(result.msg==="data found"){                        
                         setNotificationArray(result.data)                           
                       }
                   } catch (err) {
                       console.log(err.message);
                   }
               };
               
                   fetchData();
               
           
           }, []);
  return (
    <>
    <div className="d-flex justify-content-between my-2" style={{fontSize:"1rem"}}>
        <div>Notification</div>
    <EditableDate selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
    </div>

    {NotificationArray.length===0?<label className='my-2'>No Notification Found</label>:<>

    {NotificationArray.map((notify,index)=>(
      <>
        <div className='border-bottom my-2 px-1' key={index}>

        <div>{notify.title}</div>
        <div className="d-flex align-items-center justify-content-between">
        <label htmlFor="notification-body">{notify.body}</label>
        <label htmlFor="notification-body">{FormateDate(notify.date)}</label>
        </div>
        </div>
        </>
    ))}
    </>}

    </>
  )
}

export default NotificationActivity