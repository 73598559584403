import React, { useContext, useEffect } from 'react'
import { context } from '../Context/Context';

const AddEventForm = React.memo(({ handleClose, selectedDateTime}) => {
    const { formData, setFormData,setSnackbarOpen, setSnackbarMessage  } = useContext(context);
    useEffect(() => {
        setFormData({ ...formData, time: selectedDateTime.time, date: selectedDateTime.date })
        return () => {
            setFormData({})
        }
    }, [])
    console.log(formData)
    const handleInput = (e) => {
        console.log(e.target.name, e.target.value)
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleAddEvent = () => {
        const Url = `https://marina-world-users.onrender.com/storeEvents`
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Headers
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                if (data.msg === "Event stored and notifications sent successfully") {
                    setSnackbarMessage("Event Stored Successfully")
                    setSnackbarOpen(true)
                    setFormData({})
                    handleClose()
                }
                setSnackbarMessage(data.msg)
                setSnackbarOpen(true)
            })
            .catch((error) => {
                console.error('Error:', error);
                setSnackbarMessage(error)
                setSnackbarOpen(true)
            });

    }

    return (
        <div className="card loginCard">
            <div className="card-body">

                <div className="text-center">
                    <label htmlFor="heading" style={{ fontSize: "1rem" }} className='my-3 mx-2'>Add Event</label>
                </div>

                <label htmlFor="heading" className="mt-2">Date</label>
                <input
                    className='w-100 form-control box-shadow mb-2'
                    placeholder="Date"
                    value={!("date" in formData) ? "" : (formData?.date).toLocaleDateString('en-GB', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    })}
                    readOnly
                    name="date" />

                <label htmlFor="heading" className="mt-2">Time</label>
                <input
                    className='w-100 form-control box-shadow mb-2'
                    placeholder="Time"
                    value={formData?.time}
                    readOnly
                    name="time" />

                <label htmlFor="heading" className="mt-2">Title</label>
                <input
                    className='w-100 form-control box-shadow mb-2'
                    placeholder="Title"
                    value={formData?.title}
                    autoComplete="off"
                    onChange={handleInput}
                    name="title" />

                <label htmlFor="description" className="mt-2">Description</label>
                <textarea
                    class="form-control box-shadow mb-2"
                    placeholder="Description..."
                    rows="3"
                    autoComplete="off"
                    value={formData?.description}
                    onChange={handleInput}
                    name="description"
                />







                <div className="d-flex align-bottom justify-content-center w-100 align-item-center mt-4 mb-3">
                    <div className={`btn px-3 mx-1 themeButton`} onClick={handleAddEvent} >Add Event</div>
                    <div className="btn whiteThemeButton px-4 mx-1" style={{ border: 0 }} onClick={() => { setFormData({}); handleClose() }}>Cancel</div>
                </div>

            </div>
        </div>
    )
})

export default AddEventForm
