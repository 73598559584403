import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormData } from "./FormsData"
import { NumericInput, AlphaNumericInput, AlphaInput } from "./Validation"
import { context } from '../Context/Context';
import { Calendar, Upload,defaultLogo } from "../../Assets/Images/ExportSvg"
import { useNavigate } from 'react-router-dom';
import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Form = React.memo(({ formName, borderless }) => {
    const navigate = useNavigate()
    const formDetail = FormData?.[formName]
    const [formSnackBar,setFormSnackBar]=useState("")

    const { formData, setFormData, editModeOption, setEditModeOption, setActive, snackbarMessage, setSnackbarMessage, snackbarOpen, setSnackbarOpen } = useContext(context);

    console.log(formData, "formData")
    const inputRef = useRef([])
    const [selectedHeading, SetSelectedHeading] = useState(formDetail?.SelectedLabel)

    const [dateType, setDateType] = useState("text")
    const roles = [
        { name: "Admin", send: "admin" },
        { name: "Back Office", send: "backOffice" },
        { name: "Executive", send: "executive" },
        { name: "Driver", send: "driver" },
        { name: "Supporting Staff", send: "supportingStaff" }
    ]

    const [dropDownObjects, setdropDownObjects] = useState({
        role: { values: roles, show: "name", send: "send" }
    })

    const dateInputRef = useRef(null);
    const handleIconClick = () => {
        dateInputRef.current.focus();
    };


    useEffect(() => {

        if (!editModeOption) {
            setFormData({})
        }

    }, [])
    useEffect(() => {
        if (!editModeOption) {
            setFormData({})
        }
    }, [selectedHeading])



    //for initial data 
    useEffect(() => {
        if (!editModeOption && Object.keys(formData) == 0) {
            console.log("hiiiiiiii")
            let obj = formDetail?.initialValue?.[selectedHeading]
            let port = formDetail.port
            if (obj !== undefined) {
                const name = Object.keys(obj)[0]
                const endPoint = Object.values(obj)[0]
                const Url = `${port}/${endPoint}?reqFor=${name}`
                fetch(Url, {
                    method: 'GET',  // Specify that this is a POST request
                    headers: {
                        'Content-Type': 'application/json',  // Send JSON data
                    },
                    // Convert the JavaScript object to JSON string
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log(name, data.data[0]?.[name], "initial value");
                        setFormData({ [name]: data.data[0]?.[name] })


                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        //   setMessage('Failed to post data.');
                    });
            }
        }
    }, [selectedHeading, editModeOption, formData])





    //for dropDownValues 
    useEffect(() => {
        if ("dropdown" in formDetail) {
            let TempData = { ...dropDownObjects }
            let port = formDetail.port
            formDetail?.dropdown?.[selectedHeading].map((drowDown, index) => {

                const Url = `${port}/${drowDown.endPoint}?reqFor=${drowDown.requestfor}`
                fetch(Url, {
                    method: 'GET',  // Specify that this is a POST request
                    headers: {
                        'Content-Type': 'application/json',  // Send JSON data
                    },
                    // Convert the JavaScript object to JSON string
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log('dropdown', data.data);
                        TempData[drowDown.dropDownName] = {
                            values: data.data,
                            show: `${drowDown.show}`,
                            send: `${drowDown.send}`
                        }
                        if (formDetail?.dropdown?.[selectedHeading].length - 1 === index) {
                            setdropDownObjects(TempData)
                        }

                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        //   setMessage('Failed to post data.');
                    });
            })
        }


    }, [selectedHeading])
    console.log(dropDownObjects, "dropDownObject")





    // to add values in form data
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value == "") {
            let temp = { ...formData }
            delete temp[name];
            setFormData(temp)
        }
        else {
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }

    };
    // to add images
    const handleFileChange = (e, index) => {
        if (inputRef.current[index].files.length > 0) {
            const file = inputRef.current[index].files[0]
            console.log(file, e.target.name)
            const { name } = e.target;
            setFormData(prevData => ({
                ...prevData,
                [name]: file
            }));
        }
    }

  
  

    // to handle checkboxex
    const handleCheckBoxSelect = (fieldName, option) => {
        console.log(fieldName, option)
        if (formData[fieldName] === option) {
            setFormData(prevData => ({
                ...prevData,
                [fieldName]: null
            }));
        }
        else {
            setFormData(prevData => ({
                ...prevData,
                [fieldName]: option
            }));
        }
    }

    //click on cancel button
    const handleCancel = () => {
        setFormData({});
        setEditModeOption(false)
        setActive("Dashboard")
        navigate("/layout/dashboard", { state: { activeName: "Dashboard" } })
    }


    console.log(formDetail?.noOfFields?.[selectedHeading], "noOfFields")

    const base64ToFile = (base64, fileName) => {
        const byteString = atob(base64);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        const file = new File([uint8Array], fileName, { type: "image/png" });
        return file;
    };


    const handleSave = (e) => {
        e.preventDefault()

    
        if(formName==="dealerAdd"){
            if(!("ftsApproved" in formData 
                && "password" in formData 
                && "userName" in formData
                && "dealerImage" in formData
                && "Email" in formData
                && "aleternateNo" in formData
                && "PhoneNumber" in formData
                && "Name" in formData
                && "areaType" in formData
                && "ZIPCode" in formData
                && "City" in formData
                && "State" in formData
                && "Address" in formData
                && "Gstin" in formData
                && "businessName" in formData
                && "UserID" in formData )){
                    setSnackbarMessage("fields can't be empty!!")
                    setFormSnackBar(true)
                    return;
            }
        }

        if (formName === "productAdd") {

            if (!("BrandID" in formData &&
                "CategoryID" in formData &&
                "ProductID" in formData &&
                "ProductName" in formData &&
                "Stock" in formData &&
                "SubcategoryID" in formData &&
                "detail" in formData &&
                "ruralPrice" in formData &&
                "urbanPrice" in formData && "mainImage" in formData && "ThumbnailImage" in formData)) {
                setSnackbarMessage("fields can't be empty!!")
                setFormSnackBar(true)
                return;
            }
            if (formData.ruralPrice > 100000 || formData.urbanPrice > 100000) {
                setSnackbarMessage("Price is more than max. amount")
                setFormSnackBar(true)
                return;
            }
            if (formData.ruralPrice < 1 || formData.urbanPrice < 1) {
                setSnackbarMessage("Price is not valid")
                setFormSnackBar(true)
                return;
            }
            if (formData.stock < 1) {
                setSnackbarMessage("Stock amount is not valid")
                setFormSnackBar(true)
                return;
            }

        }
        if("Email" in formData){
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const check= regex.test(formData.Email);
            if(!check){
                setSnackbarMessage("Email is not valid")
                setFormSnackBar(true)
                return; 
            }
        }

        if (formDetail?.noOfFields?.[selectedHeading] > Object.keys(formData).length) {
            setSnackbarMessage("fields can't be empty!!")
            setSnackbarOpen(true)
            return;
        }
        else {
            let port = formDetail.port
            let imagePort=formDetail.imagePort;
            const endPoint = formDetail.onSave[selectedHeading][0]
            const Type = formDetail.onSave[selectedHeading][1]
            const Url = `${port}/${endPoint}`

            if (Type === "fileSend") {
                console.log(formData, "RAHUUUUULLL")
                const formData1 = new window.FormData();
                for (const key in formData) {
                    if (formData.hasOwnProperty(key)) {
                        formData1.append(key, formData[key]);
                    }
                }
                if(formName==="dealerAdd" && !("logo" in formData)){
                    console.log(defaultLogo,"default blockkkkk")
                    const defaultLogo1=base64ToFile(defaultLogo.split(",")[1],"image/png");
                    formData1.append("logo", defaultLogo1);
                }
                console.log(formData1)
                fetch(Url, {
                    method: 'POST',
                    body: formData1,
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.msg == "Data Successfully Added") {

                            if(formName==="dealerAdd"||formName==="executiveAdd"||formName==="brandAdd"||formName==="productAdd"){
                                const url1=`${imagePort}/${endPoint}`
                                fetch(url1, {
                                    method: 'POST',
                                    body: formData1,
                                    // body:""
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        if (data.msg == "Data Successfully Added") {                
                                            setSnackbarMessage(data.msg)
                                            setSnackbarOpen(true)
                                            setFormData({})
                                        }
                                        setSnackbarMessage(data.msg)
                                        setSnackbarOpen(true)
                
                                    })
                                    .catch((error) => {
                                        console.error('Error:', error);
                                        setSnackbarMessage(error)
                                        setSnackbarOpen(true)
                                    });
                            }
                           
                        }
                        setSnackbarMessage(data.msg)
                        setSnackbarOpen(true)

                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        setSnackbarMessage(error)
                        setSnackbarOpen(true)
                    });
            }
            else {
                console.log(formData, "elseeeee")
                fetch(Url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json', // Headers
                    },
                    body: JSON.stringify(formData),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log('Success:', data);
                        if (data.msg === "Data Successfully Added") {
                            setSnackbarMessage(data.msg)
                            setSnackbarOpen(true)
                            setFormData({})
                        }
                        setSnackbarMessage(data.msg)
                        setSnackbarOpen(true)
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        setSnackbarMessage(error)
                        setSnackbarOpen(true)
                    });

            }

        }


    };
    const handleEdit = (e) => {
        e.preventDefault()
        if (formName === "productAdd") {

            if (!("BrandID" in formData &&
                "CategoryID" in formData &&
                "ProductID" in formData &&
                "ProductName" in formData &&
                "Stock" in formData &&
                "SubcategoryID" in formData &&
                "detail" in formData &&
                "ruralPrice" in formData &&
                "urbanPrice" in formData && "mainImage" in formData && "ThumbnailImage" in formData)) {
                setSnackbarMessage("fields can't be empty!!")
                setFormSnackBar(true)
                return;
            }
            if (formData.ruralPrice > 100000 || formData.urbanPrice > 100000) {
                setSnackbarMessage("Price is more than max. amount")
                setFormSnackBar(true)
                return;
            }
            if (formData.ruralPrice < 1 || formData.urbanPrice < 1) {
                setSnackbarMessage("Price is not valid")
                setFormSnackBar(true)
                return;
            }
            if (formData.stock < 1) {
                setSnackbarMessage("Stock amount is not valid")
                setFormSnackBar(true)
                return;
            }

        }
        if("Email" in formData){
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const check= regex.test(formData.Email);
            if(!check){
                setSnackbarMessage("Email is not valid")
                setFormSnackBar(true)
                return; 
            }
        }
        if (formDetail?.editNoOfFields?.[selectedHeading] > Object.keys(formData).length) {
            setSnackbarMessage("fields can't be empty!!")
            setSnackbarOpen(true)
        }
        else {
            let imagePort=formDetail.imagePort;
            let port = formDetail.port
            const endPoint = formDetail.onEdit[selectedHeading][0]
            const Type = formDetail.onSave[selectedHeading][1]
            const Url = `${port}/${endPoint}`
            if (Type === "fileSend") {
                const formData1 = new window.FormData();
                for (const key in formData) {
                    if (formData.hasOwnProperty(key)) {
                        formData1.append(key, formData[key]);
                    }
                }
                console.log(formData1)
                fetch(Url, {
                    method: 'PATCH',
                    body: formData1,
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.msg === "Data Updated successfully") {
                            if(formName==="dealerAdd"||formName==="executiveAdd"||formName==="brandAdd"||formName==="productAdd"){
                                const url1=`${imagePort}/${endPoint}`
                                fetch(url1, {
                                    method: 'POST',
                                    body: formData1,
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        if (data.msg == "Data Successfully Added") {                
                                            setSnackbarMessage("Data Updated Successfully")
                                            setSnackbarOpen(true)
                                            setFormData({})
                                        }
                                        setSnackbarMessage(data.msg)
                                        setSnackbarOpen(true)
                
                                    })
                                    .catch((error) => {
                                        console.error('Error:', error);
                                        setSnackbarMessage(error)
                                        setSnackbarOpen(true)
                                    });
                            }
                            setEditModeOption(false)
                            navigate(-1)
                        }
                        setSnackbarMessage(data.msg)
                        setSnackbarOpen(true)

                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        setSnackbarMessage(error)
                        setSnackbarOpen(true)
                    });
            }
            else {
                console.log(formData, "elseeeee")
                fetch(Url, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json', // Headers
                    },
                    body: JSON.stringify(formData),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log('Success:', data);
                        if (data.msg === "Data Updated successfully") {
                            setSnackbarMessage(data.msg)
                            setSnackbarOpen(true)
                            setFormData({})
                            setEditModeOption(false)
                            navigate(-1)
                        }
                        setSnackbarMessage(data.msg)
                        setSnackbarOpen(true)
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        setSnackbarMessage(error)
                        setSnackbarOpen(true)
                    });

            }
        }


    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
        setFormSnackBar(false)
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    return (
        <div className='card myCard' key={formName} style={{ border: borderless ? 0 : "" }} >
            <div className="card-body">
                <form method="POST" encType="multipart/form-data" onSubmit={handleSave}>
                    <div className="d-flex justify-content-center align-items-center">
                        {formDetail.formLabel.map((formheading) => (
                            <>{formDetail.formLabel.length === 1 ?
                                <>
                                    <label htmlFor="heading" style={{ fontSize: "1rem" }} className={`${borderless ? "my-0" : "mt-2 mb-4"} mx-2`} key={formheading}>{editModeOption ? formDetail.editLabel : formheading}</label>
                                </> : <>
                                    <label htmlFor="heading" style={{ fontSize: "1rem" }} className={`mt-2 mb-4 mx-2 cursor-pointer ${selectedHeading === formheading ? "selectedHeading" : ""}`} key={formheading} onClick={() => SetSelectedHeading(formheading)}>{formheading}</label>

                                </>
                            }</>
                        ))}
                    </div>

                    <div className="row align-items-center">
                        {formDetail[selectedHeading].map((fields, index) => (
                            <>
                                <div className={`${fields?.flex === true ? "col-6" : "col-12"} my-2 ${fields.type === "dropdown" ? "select_box" : ""}`}>
                                    {fields.type === "labelOnly" ? <>
                                        {(editModeOption) && (fields.label === "Login Details") ? null :
                                            <label className='fw-bold'>{fields.label}</label>
                                        }
                                    </> :
                                        fields.type === "dropdown" ? <>
                                            <select class="form-select w-100" value={formData?.[dropDownObjects?.[fields.key]?.show]} name={fields.name} onChange={handleChange} style={{ color: formData[fields.name] === undefined || formData[fields.name] === "" ? "#9B9D9F" : "#EEEEEE" }} >
                                                <option value={editModeOption ? formData?.[dropDownObjects?.[fields.key]?.send] : ""}>{editModeOption ? formData?.[dropDownObjects?.[fields.key]?.show] : fields.label}</option>
                                                {/* {console.log(formData?.[dropDownObjects?.[fields.key]?.show])} */}

                                                {
                                                    fields.filter !== true ? <>
                                                        {dropDownObjects?.[fields.key]?.values.map((option) => (
                                                            <option value={option?.[dropDownObjects?.[fields.key]?.send]} >{option?.[dropDownObjects?.[fields.key]?.show]}</option>
                                                        ))}
                                                    </> : <>
                                                        {
                                                            // console.log( dropDownObjects?.[fields.key]?.values.filter((option)=>option[fields.filterOnBasis]==formData?.[fields.filterOnBasis]).map((option)=>option))
                                                            dropDownObjects?.[fields.key]?.values.filter((option) => option?.[fields.filterOnBasis] == formData?.[fields.filterOnBasis])
                                                                .map((option) => (
                                                                    <option value={option?.[dropDownObjects?.[fields.key]?.send]} >{option?.[dropDownObjects?.[fields.key]?.show]}</option>
                                                                ))
                                                        }
                                                    </>
                                                }


                                            </select></> : fields.type === "upload" ? <>
                                                <div className="position-relative">
                                                    <input
                                                        type="text"
                                                        name={fields.name}
                                                        value={formData[fields.name]?.name || ""}
                                                        placeholder={fields.label}
                                                        onClick={() => inputRef.current[index].click()}
                                                        className="form-control w-100"
                                                    />

                                                    <img src={Upload}
                                                        alt='upload'
                                                        style={{ cursor: 'pointer', background: "#222831" }}
                                                        width={25}
                                                        onClick={() => inputRef.current[index].click()}
                                                        className="position-absolute top-50 end-0 translate-middle-y me-2 uploadButton"
                                                    />
                                                </div>

                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    name={fields.name}
                                                    ref={el => inputRef.current[index] = el}
                                                    onChange={(e) => handleFileChange(e, index)}
                                                    style={{ display: 'none' }}
                                                />
                                            </>
                                            : fields.type === "checkbox" ? <>
                                                <div className="d-flex">
                                                    <label className='me-5'>{fields.label}</label>
                                                    {
                                                        fields.checkboxOption.map((option) => (
                                                            <div className='d-flex align-items-center mx-2'>
                                                                <div className={`${formData[fields.name] === option ? "selected-checkBox" : "checkBox"} mx-1`} onClick={() => handleCheckBoxSelect(fields.name, option)} />
                                                                <label className=''>{option}</label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </> : fields.type === "text-Box" ? <>
                                                <textarea
                                                    class="w-100 form-control"
                                                    name={fields.name}
                                                    autoComplete="off"
                                                    value={formData[fields.name] || ''}
                                                    onChange={handleChange}
                                                    placeholder={fields.label} rows="3" />
                                            </> :
                                                fields.type === "date" ? <>
                                                    <>
                                                        <div className="position-relative">
                                                            <input
                                                                type={dateType}
                                                                onFocus={() => { setDateType("date") }}
                                                                onBlur={() => { setDateType("text") }}
                                                                className={`w-100 form-control ${formData[fields.name] !== undefined && formData[fields.name] !== "" ? "date-input--has-value" : ""}`}
                                                                name={fields.name}
                                                                value={formData[fields.name] || ''}
                                                                onChange={handleChange}
                                                                autoComplete="off"
                                                                ref={dateInputRef}
                                                                placeholder={`${fields.label}`}
                                                            />
                                                            {dateType === "textt" ?
                                                                <img src={Calendar}
                                                                    alt='calendar'
                                                                    onClick={handleIconClick}
                                                                    style={{ cursor: 'pointer', background: "#222831" }}
                                                                    width={13}
                                                                    className="position-absolute top-50 end-0 translate-middle-y me-3 uploadButton"
                                                                /> : null
                                                            }
                                                        </div>
                                                    </>

                                                </> :
                                                    <>{editModeOption && (fields.label === "Password" || fields.label === "Employee UserName" || fields.label === "Dealer Username") ? null :

                                                        <input
                                                            onKeyPress={fields?.validation[0] === "Alpha" ? AlphaInput : fields?.validation[0] === "Numeric" ? NumericInput : AlphaNumericInput}
                                                            maxLength={fields?.validation[1] === "" ? 100 : parseInt(fields?.validation[1])}
                                                            readOnly={editModeOption && fields.label === "Stock" ? true : fields?.readOnly}
                                                            className='w-100 form-control'
                                                            autoComplete="off"
                                                            name={fields.name}
                                                            value={formData[fields.name] || ''}
                                                            onChange={handleChange}
                                                            placeholder={`${fields.label}`} />}
                                                    </>
                                    }



                                </div>

                            </>
                        ))}
                    </div>

                    <div className="d-flex align-bottom justify-content-center w-100 align-item-center mt-4 mb-3">
                        {formDetail.buttons.map((buttonName, index) => (
                            <div style={{ fontSize: "1.1rem" }} className={`btn px-5 mx-1 ${index === 0 ? "themeButton" : "whiteThemeButton"} `} onClick={buttonName === "Cancel" ? handleCancel : buttonName === "Save" && editModeOption ? handleEdit : handleSave}>{buttonName}</div>

                        ))}
                        {/* <div className="btn whiteThemeButton px-5 mx-1">Close</div> */}
                    </div>
                </form>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={formSnackBar}
                autoHideDuration={3000}
                onClose={handleClose}
                message={snackbarMessage}
                action={action}
            />
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                message={snackbarMessage}
                action={action}
            />
        </div>
    )
})

export default Form
