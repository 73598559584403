import React, { useState } from 'react'
import PageHeader from './PageHeader'
import HorizontalCards from './HorizontalCards'
import { Search } from '../../Assets/Images/ExportSvg'
import Tables from '../Tables/Tables'


const PaymentAndInvoice = () => {
    const [time, setTime] = useState("year")
    const [selectedHeading, SetSelectedHeading] = useState("Order Wise Invoice")
    const [carddata, setCarddata] = useState({
        "Total Payment Recieve ": 863,
        "Cash Recieve": 478,
        "Cheque Recieved": 190,
        "Credit": 210
    })

    const tableTypes = ["Order Wise Invoice", "Dealer Wise Invoice"]

    return (
        <>
            <PageHeader />
            <div className="d-flex justify-content-between align-items-center">
                <label htmlFor="order Management" className="fs-4">Payment & Invoice</label>
                <div className="Orange-form-select">

                    <select class="form-select" value={time} onChange={(e) => { setTime(e.target.value) }} style={{ color: "#EEEEEE", width: "250px" }} >
                        <option value="year">This Year</option>
                        <option value="month">This Month</option>
                        <option value="Week">This Week</option>
                        <option value="today">Today</option>


                    </select>
                </div>

            </div>

            <HorizontalCards data={carddata} />

            
                <div className="d-flex justify-content-center align-items-center">
                    {tableTypes.map((type) => (
                        <label htmlFor="heading" className={`my-3 px-3 py-1 cursor-pointer ${selectedHeading === type ? "seletedTabButtons" : "tabButtons"}`} key={type} onClick={() => SetSelectedHeading(type)}>{type}</label>

                    ))}
                </div>

               

            <Tables TableName={"PaymentAndInvoice"} displayRecords={[{},{}]} filter={false} colorful={true} heading={false}/>





        </>
    )
}

export default PaymentAndInvoice
