import { Snackbar } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { context } from '../Context/Context';

const ChangePassword = () => {
    const { snackbarMessage, setSnackbarMessage,snackbarOpen, setSnackbarOpen} = useContext(context);

    const navigate = useNavigate();
    const [passwordShow, setPasswordShow] = useState(false)
    const [userDetail, setUserDetail] = useState({id:"",password:"",reEnterPassword:"" })

  

    const handleInput = (e) => {
        setUserDetail({ ...userDetail, [e.target.name]: e.target.value })
    }
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    const handleLogin = async(e) => {
        e.preventDefault()
        if (!userDetail.id ||!userDetail.password ||!userDetail.reEnterPassword) {
            setSnackbarMessage("field can't be empty!!")
            setSnackbarOpen(true)
        }       
        else if(userDetail.password!==userDetail.reEnterPassword){
            setSnackbarMessage("Password dose not match!!")
            setSnackbarOpen(true)
        }
       

        else if (userDetail.id  && userDetail.password && userDetail.reEnterPassword) {
           
                const url = `https://marina-world-users.onrender.com/UpdatePass`; // Example URL
                          
                try {
                  const response = await fetch(url, {
                    method: 'PATCH', // Specify the HTTP method
                    headers: {
                      'Content-Type': 'application/json', // Set content type
                    },
                    body: JSON.stringify(userDetail), // Convert data to JSON string
                  });
            
                  if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                  }
            
                  const result = await response.json(); 
                  if(result.msg==="Password Updated SucessFully"){
                    setSnackbarMessage("Password Updated Successfully")
                    setSnackbarOpen(true)
                  }
                } catch (error) {
                }
              
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
  return (
    <div className='d-flex align-items-center justify-content-center'>
         <div className=" card loginCard rounded-5 login-form-font py-3 mt-2" style={{ width: "25rem"}}>
                <div className="card-body">
                    <form onSubmit={handleLogin}>
                         
                    <div className='row align-items-center mb-2'>
                       
                    <div className="text-center col  Login-heading fs-4">Change Password</div>
                    
                    </div>

                    <div className="row my-3">
                        <div className="col-12">
                            <label>
                                Employee Id \ Name
                            </label>
                        </div>
                        <div className="col-12">
                            <input name="userName"
                            autocomplete="off"
                                type='text'
                                value={userDetail.userName}
                                style={{boxShadow: "rgba(0, 0, 0, 0.1) 1.5px 1.5px 2px ,rgba(0, 0, 0, 0.1) -1.5px -1.5px 2px"}}
                                onChange={handleInput}
                                className='form-control w-100'
                                placeholder='Employee Id \ Name' />
                        </div>
                    </div>
                    
                    <div className="row my-3">
                        <div className="col-12">
                            <label>
                            New Password
                            </label>
                        </div>
                        <div className="col-12 position-relative">
                            <input name="Newpassword"
                            autocomplete="off"
                                type={passwordShow ? "text" : "password"}
                                value={userDetail.Newpassword}
                                style={{boxShadow: "rgba(0, 0, 0, 0.1) 1.5px 1.5px 2px ,rgba(0, 0, 0, 0.1) -1.5px -1.5px 2px"}}
                                onChange={handleInput}
                                placeholder='********'
                                className='form-control w-100' />
                                {/* <div
                                style={{ cursor: "pointer" }}
                                onClick={() => setPasswordShow(!passwordshow)}
                                className='position-absolute top-50 end-0 translate-middle-y me-4'>
                                    {passwordshow?<VisibilityIcon/>:<VisibilityOffIcon/>}
                                </div> */}


                        </div>
                     
                    </div>
                    <div className="row my-3">
                        <div className="col-12">
                            <label>
                                Re-enter Password
                            </label>
                        </div>
                        <div className="col-12 position-relative">
                            <input name="reEnterPassword"
                            autocomplete="off"
                                type={passwordShow ? "text" : "password"}
                                value={userDetail.reEnterPassword}
                                onChange={handleInput}
                                style={{boxShadow: "rgba(0, 0, 0, 0.1) 1.5px 1.5px 2px ,rgba(0, 0, 0, 0.1) -1.5px -1.5px 2px"}}
                                placeholder='********'
                                className='form-control w-100' />
                                <div
                                style={{ cursor: "pointer" }}
                                onClick={() => setPasswordShow(!passwordShow)}
                                className='position-absolute top-50 end-0 translate-middle-y me-4'>
                                    {passwordShow?<VisibilityIcon/>:<VisibilityOffIcon/>}
                                </div>


                        </div>
                       
                    </div>
                    <div className="row my-4">

                        <div className="col-12" >
                            <button className="w-100 py-1 button" onClick={handleLogin}>
                                Reset
                            </button>
                        </div>
                    </div>

                </form>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical:"bottom",horizontal:"right"}}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                message={snackbarMessage}
                action={action}
            />
      
    </div>
  )
}

export default ChangePassword
