import React, { useEffect, useState } from 'react'
import PageHeader from '../PageHeader'
import AttendancePieChart from "./AttendancePieChart"
import TaskBarGraph from "./TaskBarGraph"
import { GreySearch } from '../../../Assets/Images/ExportSvg'
import ProfileSection from './ProfileSection'
import { useRef } from 'react'

const ExecutiveAnalysis = () => {
  const [selectedExecutive, setSelectedExecutive] = useState({})
  const [showList,setShowList]=useState(false)
  const [executiveList,setExecutiveList]=useState([])
  const [filteredList ,setFilteredList]=useState([])


  useEffect(() => {
    let name = "executive"
    let endPoint = "executives"
    let reqFor = "executives"
    let port = "https://marina-world-users.onrender.com"


    if (name !== "" && endPoint !== "" && port !== "") {


      const Url = `${port}/${endPoint}?reqFor=${reqFor}`
      fetch(Url, {
        method: 'GET',  // Specify that this is a POST request
        headers: {
          'Content-Type': 'application/json',  // Send JSON data
        },
        // Convert the JavaScript object to JSON string
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Success:', data.data);
          const executive=data.data.filter((item)=>item.role==="executive")
                    setExecutiveList(executive)
          // setExecutiveList(data.data)
          setFilteredList(executive)
        })
        .catch((error) => {
          console.error('Error:', error);
          //   setMessage('Failed to post data.');
        });
    }



  }, [])
  const filterList = (value) => {
    if (value) {
        const filteredItem = executiveList.filter(
            (option) =>
                option.ExecutiveID==(value) ||
                option.ExecutiveName.toLowerCase().includes(value.toLowerCase())
        );  
            setFilteredList(filteredItem)
    }

}
const divRef = useRef(null);

const handleClickOutside = (event) => {        
    // Check if the click happened outside the div
    if (divRef.current && !divRef.current.contains(event.target)) {
        setShowList(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  console.log(selectedExecutive)

  return (
    <>
      <PageHeader />
      <div className="row align-items-stretch " >
        <div className="col-6 text-center">
          <div ref={divRef}>         
          <div className="card myCard my-2" >
            <div className="position-relative">
              <input
                type="text"
                style={{ boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }}
                placeholder="Search"
                className="form-control w-100"
                autoComplete="off"
                onClick={() => {setShowList(true)}}
                onChange={(e) => {
                  filterList(e.target.value);
              }}
              />

              <img src={GreySearch}
                alt='Search'
                style={{ cursor: 'pointer', background: "#222831" }}
                width={"18em"}
                className="position-absolute top-50 end-0 translate-middle-y me-2 uploadButton"
              />
            </div>
          </div>
          {showList  && (
            <ul className="list-group position-absolute text-start " style={{ zIndex: 1, maxHeight: "50vh", overflowY: "auto" }}>
              {filteredList.map((option, i) => (
                <li
                  key={i}
                  className="list-group-item"
                  onClick={() => {setSelectedExecutive(option);setShowList(false)}}
                >
                  {option?.ExecutiveID} - {option?.ExecutiveName}
                </li>
              ))}
            </ul>
          )}
           </div>

          <div className='card myCard my-2'>
            <div className="card-body px-4">
              <ProfileSection selectedExecutive={selectedExecutive} />
            </div>
          </div>

        </div>
        {Object.keys(selectedExecutive).length===0?null:
        <div className='col-6 text-center my-2'>
          {/* attendance Pie  chart */}
          <div className='card myCard'>
            <div className="card-body">
              <AttendancePieChart ExecutiveID={selectedExecutive.ExecutiveID}/>
            </div>
          </div>
          {/* task Bar Graph */}
          <div className='card myCard my-2'>
            <div className="card-body">
              <TaskBarGraph ExecutiveID={selectedExecutive.ExecutiveID}/>
            </div>
          </div>
          {/* other Report*/}
          {/* <div className='card myCard my-2'>
            <div className="card-body px-5" style={{fontSize:"1rem"}}>


              <div className="d-flex justify-content-between align-items-center pt-4 pb-4">
                <label className="mb-0">Total New Sales</label>
                <label className="mb-0">1,50,000</label>
              </div>


              <div className="d-flex justify-content-between align-items-center  pb-4">
                <label className="mb-0">New Dealers</label>
                <label className="mb-0">10</label>
              </div>


              <div className="d-flex justify-content-between align-items-center pb-4">
                <label className="mb-0">Quotation Generated</label>
                <label className="mb-0">12</label>
              </div>

            </div>
          </div> */}
        </div>}
      </div>


    </>
  )
}

export default ExecutiveAnalysis
