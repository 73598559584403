import React, { useState } from 'react'
import CompanyDetails from './CompanyDetails'
import ChangePassword from './ChangePassword'
import AddEmployees from './AddEmployees'
import ApiStatus from './ApiStatus'

const MainSetting = () => {
    const [selectedTab, setSelectedTab] = useState("Company Details")
    const data = [
        "Company Details", "Change Password", "Add Employees", "API’s Status"
    ]
    return (
        <div>
            <div className="d-flex justify-content-center align-items-center">
                {data.map((heading) => (
                    <>
                        <label htmlFor="heading" className={`my-3 mx-2 cursor-pointer ${selectedTab === heading ? "selectedHeading" : ""}`} key={heading} onClick={() => setSelectedTab(heading)} style={{ fontSize: ".8rem" }}>{heading}</label>

                    </>

                ))}
            </div>

            <div>
                {selectedTab === "Company Details" ?
                    <CompanyDetails /> :
                    selectedTab === "Change Password" ?
                        <ChangePassword /> :
                        selectedTab === "Add Employees" ?
                            <AddEmployees /> : <ApiStatus />}
            </div>


        </div>
    )
}

export default MainSetting
