import React, { useContext, useEffect, useState } from 'react'
import { Add } from '../../../Assets/Images/ExportSvg'
import { context } from '../../Context/Context';

const TaskList = ({ selectedExecutive, setAction , selectedTask,setSelectedTask}) => {
    const {setSnackbarMessage, setSnackbarOpen} = useContext(context);
    const [taskList,setTaskList]=useState({})
    const addTaskShow = () => {
        if (Object.keys(selectedExecutive).length > 1) {
            setAction("add")
        }
        else {
            setSnackbarMessage("Select Executive!")
            setSnackbarOpen(true)

        }
    }
    useEffect(() => {
        if (Object.keys(selectedExecutive).length > 1) {
            // console.log("api callleddd",selectedExecutive.ExecutiveID)
            const Url = `https://marina-world-users.onrender.com/viewTask?ExecutiveID=${selectedExecutive.ExecutiveID}`
            const fetchData = async () => {
                try {
                    const response = await fetch(Url);
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const result = await response.json();
                    console.log(result.res,"piyuuuu")
                    const groupByDate = (array) => {
                        return array.reduce((acc, curr) => {
                          const { created_at } = curr;
                          function formatDate(dateString) {
                            const months = [
                              "January", "February", "March", "April", "May", "June",
                              "July", "August", "September", "October", "November", "December"
                            ];
                          
                            const date = new Date(dateString);
                            const day = date.getUTCDate();
                            const month = months[date.getUTCMonth()];
                            const year = date.getUTCFullYear();
                          
                            return `${day} - ${month} - ${year}`;
                          }
                          
                          const formattedDate = formatDate(created_at);
                          if (!acc[formattedDate]) {
                            acc[formattedDate] = []; // Initialize an empty array for the date key
                          }
                          acc[formattedDate].push(curr); // Push the current object to the date key's array
                          return acc;
                        }, {});
                      };
                      const res=groupByDate(result.res);
                      setTaskList(res)
                      console.log(res);
                } catch (err) {
                    console.log(err.message);
                }
            };
            fetchData();
        }
    }, [selectedExecutive])
    return (
        <div className="card loginCard" style={{ height: "79vh" }}>
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-end">
                    <button className="btn themeButton d-flex align-items-center justify-content-end px-3 py-1 mx-1 " onClick={addTaskShow}>
                        <img src={Add} alt="add button" width={20} className='mx-1' />
                        Add Task</button>
                </div>


            {Object.keys(taskList).map((date,index)=>(
                <>
                <label htmlFor="date" className="my-2">{date}</label>
              {  taskList[date].map((task,ind)=>(

                <div className={`d-flex justify-content-between px-2 py-2 align-items-center ${JSON.stringify(selectedTask)===JSON.stringify(task)?"themeButton":""}`} onClick={()=>{setSelectedTask(task);setAction("view")}}>
                    <div>
                        <label htmlFor="no">{ind+1}.</label>
                        <label htmlFor="executiveName" className='ms-3'>{task.task_name}</label>
                    </div>
                    
                </div>
                ))}
                    </>
            ))}

            </div>

</div>
    )
}

export default TaskList
