import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TileManagement, Setting, ReportIcon, Promotion, Products, OrderManagement, Invoice, Executive, Dealer, DashBoard, MarinaWorldLogo, Report, IconReport, RightArrow, LeftArrow, FleetexLogo } from "../../Assets/Images/ExportSvg"
import { context } from '../Context/Context';
import { Box, Modal } from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 2,
};

const Sidebar = React.memo(({ active, handleClick, setActive }) => {
  const { formData, setFormData, sideBarOpen, setSideBarOpen,setEditModeOption } = useContext(context);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate()
  const [showSubmenu, setShowSubmenu] = useState(null);

  const toggleSubmenu = (submenuName) => {
    setShowSubmenu(showSubmenu === submenuName ? null : submenuName)
  };

  const location = useLocation()
  useEffect(() => {

    if (location.state?.activeName === "Dashboard") {
      setShowSubmenu(null)
      setActive("Dashboard")
    }
    if (active === "Dashboard") {
      setShowSubmenu(null)
      setActive("Dashboard")
    }
  })



  const menuItems = [
    { name: "Dashboard", path: "dashboard", icon: DashBoard },
    {
      name: "Products", hasSubmenu: true, icon: Products,
      submenu: [
        { name: "Brand Management", path: "brandManagement" },
        { name: "Category & Sub Category", path: "CategoryManagement" },
        { name: "Add Product", path: "productManagement" },

      ],
    },
    {
      name: "Dealers", path: "dealerManagement", icon: Dealer,

    },
    {
      name: "Executive", hasSubmenu: true, icon: Executive,
      submenu: [
        { name: "Task Assignment", path: "executiveTaskManagement" },
        { name: "Manage Employee", path: "executiveManagement" },
        { name: "Attendance Management", path: "attendanceManagement" },
        { name: "Executive Analysis", path: "executiveAnalysis" },
        // { name: "Executive Reports", path: ""},
      ],
    },
    {
      name: "Order Management", hasSubmenu: true, icon: OrderManagement,
      submenu: [
        { name: "List of Orders", path: "orderManagement" },
        { name: "Stock Manage", path: "stockManage" },
        // { name: "Delivery Tracking", path: ""},
      ],
    },
    {
      name: "Payment & Invoices", path: "uploadInvoice", icon: Invoice,
    },
    { name: "Tile Management", path: "tileManagement", icon: TileManagement },
    // { name: "Reports", path: "", icon: IconReport },
  ];
  const [path, setPath] = useState([])
  const handleRedirect = () => {
    window.open("https://www.example.com", "_blank", "noopener,noreferrer");
  };


  return (
    <>
      <div className={`sidebar ${sideBarOpen ? "sidebarOpen border-end" : ""} border-end`}>
        <div className="sidebar-top d-flex align-items-center justify-content-xl-center justify-content-between p-2" onClick={() => navigate("#")}>

          <div className="d-flex align-items-center justify-content-center">

            <img
              src={MarinaWorldLogo}
              alt="logo"
              className="mx-1"
              style={{ width: "55px", height: "55px" }}
            />
            <label
              className="fw-bold"
              style={{ fontSize: "1rem" }}
            >Marina World</label>
          </div>
          <img src={LeftArrow} alt="LeftArrow"
            className="sidebarCloseIcon" width={"25rem"} onClick={() => { setSideBarOpen(false) }} />


        </div>

        <div
          className={`d-flex flex-column p-2 justify-content-between sidebar-bottom  pt-3`}
        >
          <div className="pb-1">
            <ul className="nav nav-pills flex-column mb-auto ">
              {menuItems.map((item, index) => (
                <li key={index} className={`nav-item my-1`}>
                  <Link
                    style={{ fontWeight: "500", fontSize: "1rem" }}
                    to={Object.keys(formData).length < 2 ? item.path : "#"}
                    className={`nav-link ${active === item.name ? "active" : ""} d-flex align-items-center `}
                    onClick={() => {
                      toggleSubmenu(item.hasSubmenu && Object.keys(formData).length < 2 ? item.name : null)
                      handleClick(Object.keys(formData).length < 2 ? item.name : active)
                      setOpen(Object.keys(formData).length < 2 ? false : true)
                      setPath([item.path, item.name, item.hasSubmenu])
                    }}
                  >


                    <div className="d-flex align-items-center">
                      <img src={item.icon} alt={"r"} className="mx-2 sidebar-icons" />
                      <label className="mx-1 " style={{ cursor: "pointer", color: "#fff" }}>{item.name}</label>
                    </div>
                  </Link>
                  {item.hasSubmenu && showSubmenu === item.name && (

                    <ul className="nav flex-column ms-4 tree-list " >
                      {item.submenu.map((subItem, subIndex) => (
                        <li key={subIndex} className="nav-item">
                          <Link
                            to={Object.keys(formData).length < 2 ? subItem.path : "#"}
                            state={subItem.data}
                            className={`nav-link ${active === subItem.name ? "active" : ""} mx-2`}
                            onClick={() => {
                              handleClick(Object.keys(formData).length < 2 ? subItem.name : active)
                              setOpen(Object.keys(formData).length < 2 ? false : true)
                              setPath([subItem.path, subItem.name])
                            }}
                            style={{ fontSize: "1rem", color: "#fff" }}
                          >
                            {subItem.name}
                          </Link>
                        </li>
                      ))}
                    </ul>

                  )}
                </li>
              ))}
              <li className={`nav-item `}>
                <Link
                  style={{ fontWeight: "500", fontSize: "1rem" }}
                  className={`nav-link ${active === "Integration" ? "active" : ""} d-flex align-items-center `}
                >


                  <div className="d-block align-items-center"><label className="mx-1 " style={{ cursor: "pointer", color: "#fff" }}>Integrations</label></div>
                </Link>
              </li>
              <li className={`nav-item `}>
                <div
                  style={{ fontWeight: "500", fontSize: "1rem" }}
                  className={`nav-link d-flex align-items-center `}
                >

                  <div className="d-block align-items-center"  style={{ fontWeight: "500", fontSize: "1rem" }}>
                    <a href="https://www.fleetx.io/"   target="_blank" rel="noopener noreferrer">
                      <img src={FleetexLogo} alt="fletexlogo" className="mx-2" width={"90rem"}  />
                    </a>
                  </div>
                </div>
              </li>

            </ul>
          </div>



          <div className={`d-flex align-items-center justify-content-center ${showSubmenu ? "mt-5" : ""}`} style={{ cursor: "pointer", fontSize: "1rem" }} onClick={()=>{navigate("Setting");setActive("Setting")}}>
            <img src={Setting} alt="setting" className="" width={"20rem"} />
            <label htmlFor="setting" className=""> Setting</label>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popup">
          <label htmlFor="asking" className="my-3">Do you want to save?</label>
          <div className="d-flex align-bottom justify-content-center w-100 align-item-center">
            <div className="btn themeButton px-4 mx-1" onClick={handleClose}>continue</div>
            <div className=" btn whiteThemeButton px-4 mx-1" onClick={() => {
              setFormData({})
              navigate(path[0])
              handleClick(path[1])
              toggleSubmenu(path[2] ? path[1] : null)
              setEditModeOption(false)
              handleClose()
            }}>Close</div>
          </div>
        </Box>
      </Modal>
    </>
  );
});

export default Sidebar;
