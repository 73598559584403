import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import OrderManagementSummary from './OrderManagementSummary'
import StatusChange from './StatusChange'
import PageHeader from '../PageHeader'
import { context } from '../../Context/Context';

const MainSummary = () => {
    const {snackbarMessage, setSnackbarMessage,snackbarOpen, setSnackbarOpen} = useContext(context);
    const location = useLocation();
    const {id} = location.state || {};
    const [orderRec,setOrderRec]=useState([])
    const [totalWithoutGst,setTotalWithoutGst]=useState(0);
    const [gst,setGst]=useState(0)

      useEffect(() => {
          const endPoint = "particularOrder"
          const port ="https://marina-world-order.onrender.com"
          const Url = `${port}/${endPoint}?OrderID=${id}`
          const fetchData = async () => {
              try {
                  const response = await fetch(Url);
                  if (!response.ok) {
                      throw new Error(`HTTP error! status: ${response.status}`);
                  }
                  const result = await response.json();
                  console.log(result,"ppppp")   
                  if(result.msg==="data Found"){
                      setOrderRec(result.data)
                      const total = result.data                      
                      .reduce((acc, curr) => acc + (parseFloat(curr.ProductPrice) * parseFloat(curr.Quantity)), 0); // Calculate total amount
                      setTotalWithoutGst(parseFloat(total).toFixed(2)); 
                      const amt=parseFloat(total).toFixed(2);
                      setGst(((amt*9)/100).toFixed(2))
                  }            
                  
              } catch (err) {
                  console.log(err.message);
              }
          };
              fetchData();
          
  
      }, []);
  
  return (
    <>
    <PageHeader  back={true}/>
    <div className='row'>
        <div className="col-8">
            <OrderManagementSummary orderRec={orderRec} totalWithoutGst={totalWithoutGst} gst={gst}/>
        </div>
        <div className="col-4">
            <StatusChange orderRec={orderRec} snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage} setSnackbarMessage={setSnackbarMessage}/>
        </div>
    </div>
    </>
  )
}

export default MainSummary
