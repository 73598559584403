import React from 'react'
// import fts from '../Data/Data/FTSData'
import "./ShopPage.css"


const FtsBottomBox = ({ tileDetail, dataFromBackend }) => {
    // const platinumClub = fts.find(club => club.clubName === "Platinum Club");
    return (
      <div className="card loginCard">
      <div className="card-body">
        <div>
          <div className="page-padding">
            {dataFromBackend && "termAndCondition" in dataFromBackend && (
              <div>


                <div className="milestone-grid">
                  {Object.keys(dataFromBackend).map((box, index) => {
                    const boxData = (tileDetail &&
                      "ftsScheme" in tileDetail &&
                      "ftsBottomBoxPage" in tileDetail?.ftsScheme &&
                      box in tileDetail?.ftsScheme?.ftsBottomBoxPage) ? tileDetail?.ftsScheme?.ftsBottomBoxPage?.[box] :
                      box in dataFromBackend &&
                      dataFromBackend?.[box]

                    const checkk = (tileDetail &&
                      "ftsScheme" in tileDetail &&
                      "ftsBottomBoxPage" in tileDetail?.ftsScheme &&
                      box in tileDetail?.ftsScheme?.ftsBottomBoxPage) ? true : false

                    console.log(dataFromBackend, box, dataFromBackend?.[box], "boxData", checkk);

                    return (
                      <div key={index}>
                        {box === "termsAndConditionsBoxData" || box === "termAndCondition" ? null :
                          <div className="loginCard card milestone-card">
                            <div className="d-flex justify-content-around align-items-center">
                              <img
                                src={"destinationPhoto" in boxData && checkk ?
                                  URL.createObjectURL(boxData?.destinationPhoto) :
                                  `data:${dataFromBackend?.[box]?.destinationPhoto?.mimetype};base64,${dataFromBackend?.[box]?.destinationPhoto?.data}`}
                                alt={boxData.typeName}
                                className="milestone-image" />
                              <div className="milestone-text">
                                <h2 className='ion-text-center'>{"typeName" in boxData && checkk ? boxData.typeName : dataFromBackend?.[box]?.typeName}</h2>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <p className='mx-1'>{"purchaseAmount" in boxData && checkk ? boxData.purchaseAmount : dataFromBackend?.[box]?.purchaseAmount}</p> <p className="person mx-1">{"numberOfPerson" in boxData ? boxData.numberOfPerson : dataFromBackend?.[box]?.numberOfPerson}</p>
                                </div>
                                <p style={{ textAlign: "center" }}>{"quote" in boxData && checkk ? boxData.quote : dataFromBackend?.[box]?.quote}</p>

                              </div>
                            </div>
                          </div>}
                      </div>

                    )
                  })}
                </div>

                <div className="card loginCard terms-card">
                  <h2 className="terms-header">T&C</h2>

                  <div className='text-start'>
                    <ul style={{ listStyleType: "disc" }}>
                    {(tileDetail &&
                      "ftsScheme" in tileDetail &&
                      "ftsBottomBoxPage" in tileDetail?.ftsScheme &&
                      "termsAndConditionsBoxData" in tileDetail?.ftsScheme?.ftsBottomBoxPage) ?
                      <>
                      {tileDetail?.ftsScheme?.ftsBottomBoxPage?.termsAndConditionsBoxData?.termAndCondition.map((term, index) => (
                        <li key={index}>{term}</li>
                      ))}
                      </>:<>
                      {"termAndCondition" in dataFromBackend &&(dataFromBackend?.termAndCondition.split(",")).map((term, index) => (
                        <li key={index}>{term}</li>
                      ))}
                      </>
                }
                    </ul>

                  </div>
                </div>
              </div>
            )

            }
          </div>
        </div>
      </div>
    </div>
    )
}

export default FtsBottomBox
