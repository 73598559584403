import React from 'react'
import Form from '../Forms/Form'
import Tables from '../Tables/Tables'
import PageHeader from './PageHeader'

const ProductManagment = () => {
  return (
    <>
    <PageHeader/>
    <div className="row">
      <div className="col-5">
        <Form formName={"productAdd"} />
      </div>
      <div className="col-7">
          <Tables TableName={"productList"} editable={true} />

        </div>

    </div>
  </>
  )
}

export default ProductManagment
