import { createContext, useState } from "react";
import Loading from "../Loading/Loading"
import React from 'react'

export const context=createContext()

export const FormContext = ({children}) => {  
    const [formData, setFormData] = useState({});
    const [active, setActive] = useState("Dashboard")
    const [sideBarOpen,setSideBarOpen]=useState(false)
    const [editModeOption,setEditModeOption]=useState(false)
    const [loading,setLoading]=useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [fcmToken,setFcmToken]=useState()
    const [adminName,setAdminName]=useState()
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [NotificationArray,setNotificationArray]=useState([])
    console.log(isAuthenticated)
    
  return (
    <context.Provider value={{formData,setFormData,active, setActive,sideBarOpen,setSideBarOpen,setEditModeOption,editModeOption,setLoading,loading,fcmToken,setFcmToken,snackbarMessage, setSnackbarMessage,snackbarOpen, setSnackbarOpen,adminName,setAdminName,isAuthenticated, setIsAuthenticated,NotificationArray,setNotificationArray}}>       
        {children}
    </context.Provider>
  )
}

