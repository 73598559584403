import React, { useEffect, useState } from 'react'
import PageHeader from './PageHeader'
import HorizontalCards from './HorizontalCards'
import { Search } from '../../Assets/Images/ExportSvg'
import Tables from '../Tables/Tables'


const OrderManagement = () => {
    const [time, setTime] = useState("yearly")
    const [selectedHeading, SetSelectedHeading] = useState("All Orders")    
  const [searchFilter,setSearchFilter]=useState("")
    const [carddata, setCarddata] = useState({
        "Total Orders ": 863,
        "Ongoing": 120,
        "Dispatched": 120,
        "Completed": 11
    })


    useEffect(() => {
        const endPoint = "countOrders"
        const port ="https://marina-world-order.onrender.com"
        const Url = `${port}/${endPoint}?timePeriod=${time}`
        const fetchData = async () => {
            try {
                const response = await fetch(Url);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                console.log(result)
                setCarddata({
                    "Total Orders ": result.allOrders,
                    "Ongoing": result.Dispatched,
                    "Dispatched": result.Completed,
                    "Completed": result.Completed
                })

            } catch (err) {
                console.log(err.message);
            }
        };
        fetchData();


    }, []);


    const tableTypes = ["All Orders", "Ongoing", "Dispatched", "Completed", "Rejected"]

    return (
        <>
            <PageHeader />
            <div className="d-flex justify-content-between align-items-center">
                <label htmlFor="order Management" style={{fontSize:"1rem"}}> Order Management</label>
                <div className="Orange-form-select">

                    <select class="form-select" value={time} onChange={(e) => { setTime(e.target.value) }} style={{ color: "#EEEEEE", width: "250px" }} >
                        <option value="yearly">This Year</option>
                        <option value="monthly">This Month</option>
                        <option value="weekly">This Week</option>
                        <option value="daily">Today</option>


                    </select>
                </div>

            </div>

            <HorizontalCards data={carddata} />

            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex justify-content-center align-items-center">
                    {tableTypes.map((type) => (
                        <label htmlFor="heading" className={`my-3 px-3 py-1 cursor-pointer ${selectedHeading === type || (type === "Ongoing" && selectedHeading === "Accepted") ? "seletedTabButtons" : "tabButtons"}`} key={type} onClick={() => { type === "Ongoing" ? SetSelectedHeading("Accepted") : SetSelectedHeading(type) }}>{type}</label>

                    ))}
                </div>

                <div className="position-relative d-xl-block d-none myCard  text-secondary" style={{ width: "300px" }}>
                    <div className="position-absolute start-0 top-50 translate-middle-y  mt-0 ms-2">
                        <img src={Search} alt="search" width={20} /></div>
                    <input
                        id='additional-form-control'
                        className="form-control w-100 flex-grow-1 ps-5"
                        autoComplete="off"
                        placeholder="Order ID / User ID"
                        style={{ borderRadius: ".3cm"}}
                        onChange={(e) => setSearchFilter(e.target.value)}
                        value={searchFilter} />
                </div>

            </div>

            <Tables TableName={"orderManagement"}
                heading={false}
                filter={false}
                colorful={true}
                criteria={{ "OrderStatus": selectedHeading, "UserID": searchFilter ,"OrderID": searchFilter}}                
                threeDots={true} />





        </>
    )
}

export default OrderManagement
