import React from 'react'

const ViewTask = React.memo(({selectedTask}) => {
  return (
    <div className="card loginCard" key={selectedTask}>
            <div className="card-body">

                <div className="text-center">
                    <label htmlFor="heading" style={{fontSize:"1rem"}}  className='my-3 mx-2'>View Task</label>
                </div>
                <label htmlFor="heading" className="mt-2">Task Heading</label>
                <p className='text-muted ps-2'>{selectedTask?.task_name}</p>
               


                <label htmlFor="description" className="mt-2">Task Description</label>
                <p className='text-muted ps-2'>{selectedTask?.description}</p>
                

                <label htmlFor="priority" className="mt-2">Priority</label>
                <p className='text-muted ps-2'>{selectedTask?.priority}</p>
                </div>
    </div>
  )
})

export default ViewTask
