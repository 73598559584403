import React, { useContext, useEffect, useState } from 'react'
import { GreySearch, RightArrow, Search, WhiteRightArrow } from '../../../Assets/Images/ExportSvg'
import { context } from '../../Context/Context'

const ExecutiveList = ({ selectedExecutive, executiveList, setExecutiveList, setSelectedExecutive }) => {
    const [searchExecutive, setSearchExecutive] = useState("")
    const [allData, setAllData] = useState([])
     const {setLoading } = useContext(context);

    const filterList = (value) => {
        if (value === "") {
            setExecutiveList(allData)
        }
        if (value) {
            const filteredItem = allData.filter(
                (option) =>
                    option.ExecutiveID == (value) ||
                    option.ExecutiveName.toLowerCase().includes(value.toLowerCase())
            );
            setExecutiveList(filteredItem)
        }

    }

    useEffect(() => {
        let name = "executive"
        let endPoint = "executives"
        let reqFor = "executives"
        let port = "https://marina-world-users.onrender.com"


        if (name !== "" && endPoint !== "" && port !== "") {

            setLoading(true)
            const Url = `${port}/${endPoint}?reqFor=${reqFor}`
            fetch(Url, {
                method: 'GET',  // Specify that this is a POST request
                headers: {
                    'Content-Type': 'application/json',  // Send JSON data
                },
                // Convert the JavaScript object to JSON string
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log('Success:', data.data);
                    const executive=data.data.filter((item)=>item.role==="executive")
                    setExecutiveList(executive)
                    // setExecutiveList(data.data)
                    // setAllData(data.data)
                     setAllData(executive)
                    setLoading(false)
                })
                .catch((error) => {
                    console.error('Error:', error);
                    //   setMessage('Failed to post data.');
                });
        }



    }, [])
    return (
        <div className="card loginCard" style={{ height: "79vh" }}>

            <div className="card-body">


                <div className="text-center">
                    <label htmlFor="heading" style={{fontSize:"1rem"}} className='my-3 mx-2'>Executive Select</label>
                </div>


                <div className="position-relative">
                    <input
                        type="text"
                        style={{ boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }}
                        placeholder="Search"
                        className="form-control w-100"
                        autoComplete="off"
                        onChange={(e) => { filterList(e.target.value); setSearchExecutive(e.target.value) }}
                        value={searchExecutive}
                    />

                    <img src={GreySearch}
                        alt='Search'
                        style={{ cursor: 'pointer', background: "#222831" }}
                        width={"18em"}
                        className="position-absolute top-50 end-0 translate-middle-y me-2 uploadButton"
                    />
                </div>




                <label htmlFor="executiveList" style={{fontSize:"1rem"}} className="my-2 ">Executive Select</label>

                {executiveList.map((executive, index) => (
                    <div className={`d-flex justify-content-between px-2 py-2 align-items-center ${JSON.stringify(selectedExecutive) === JSON.stringify(executive) ? "themeButton" : ""}`} onClick={() => setSelectedExecutive(executive)}>
                        <div>
                            <label htmlFor="no">{index + 1}.</label>
                            <label htmlFor="executiveName" className='ms-3'>{executive.ExecutiveName}</label>
                        </div>
                        {selectedExecutive === executive ?

                            <img src={WhiteRightArrow} alt="arrow" width={19} /> : null
                        }
                    </div>
                ))}

            </div>

        </div>
    )
}

export default ExecutiveList
