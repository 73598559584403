import React, { useEffect, useState } from 'react'
import { MittalHospital } from '../../Assets/Images/ExportSvg'

const TopExecutive = () => {
  const [executives,setExecutives]=useState([])
     useEffect(() => {
                  
                   const endPoint="ViewHomePageData"
                   const Url = `https://marina-world-tiles.onrender.com/${endPoint}`
                   const fetchData = async () => {
                       try {
                           const response = await fetch(Url);
                           if (!response.ok) {
                               throw new Error(`HTTP error! status: ${response.status}`);
                           }
                           const result = await response.json();
                           console.log(result)
                           if(result.msg==="no orders found"){
                            setExecutives([])
                           }
                           else if(result.msg==="data found"){ 
                            const data=Object.values(result.data.employeeSlider)
                            console.log(data)
                            setExecutives(data)                           
                           }
                       } catch (err) {
                           console.log(err.message);
                       }
                   };
                   
                       fetchData();
                   
               
               }, []);

  return (
    <>
    <div className="d-flex  align-items-center justify-content-center  my-2">
        <label htmlFor="fts Scheme" style={{fontSize:"1rem"}}>Top Executive</label>        
    </div>

    {
      executives.map((executive,index)=>(

        
        <div className="d-flex align-items-center justify-content-around border-bottom py-2 my-2">
    <img src={`data:${executive?.image?.filetype};base64,${executive?.image?.data}`} alt="" className="rounded-circle"  width={"35rem"} height={"35rem"}/>
        
        <label htmlFor="fts Scheme" style={{fontSize:"1rem"}}>{executive.ExecutiveName}</label>
        
    </div>
    ))
  }
    </>
  )
}

export default TopExecutive