import React, { useContext, useEffect, useRef, useState } from 'react'
import { TableData } from "./TableData"
import { DownArrow, DownLoad, Filter, Add, Edit, ThreeDots } from "../../Assets/Images/ExportSvg"
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { context } from '../Context/Context';
import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const FilterSection = ({ setOpenFilter, allColoums, columns, setColumns }) => {
    const [selectedColumns, setSelectedColumns] = useState(columns.length === allColoums.length ? [] : columns)

    const handleSelectColoumn = (column) => {
        let arr = [...selectedColumns]
        const index = arr.findIndex(item => JSON.stringify(item) === JSON.stringify(column));

        if (index === -1) {
            // If object is not found, push it into the array
            arr.push(column);
        } else {
            // If object is found, remove it from the array
            arr.splice(index, 1);
        }
        setSelectedColumns(arr)
    }
    const handleApply = () => {
        setColumns(selectedColumns)
        setOpenFilter(false)
    }
    const cancel = () => {
        setColumns(allColoums)
        setOpenFilter(false)

    }
    //   console.log(selectedColumns)
    return (
        <>
            <div className="text-center mb-3 mt-2"><h5>Filter</h5></div>
            {allColoums.map((column) => (
                // <div>{column.label}</div>
                <div className='d-flex align-items-center mx-3 my-2'>
                    <div className={`${selectedColumns.some(item => JSON.stringify(item) === JSON.stringify(column)) ? "selected-checkBox" : "checkBox"} mx-2`} onClick={() => handleSelectColoumn(column)} />
                    <label className='mx-2'>{column.label}</label>
                </div>
            ))}

            <div className="position-absolute bottom-0 my-1 w-100">
                <div className="d-flex align-bottom justify-content-center w-100 align-item-center">
                    <div className="btn themeButton px-4 mx-1" onClick={handleApply}>Apply</div>
                    <div className="btn whiteThemeButton px-4 mx-1" onClick={() => { setOpenFilter(false) }}>Close</div>
                </div>
            </div>
        </>
    )
}
const DownLoadSection = ({ setOpenDownload, openDownLoad }) => {
    const generatePDF = () => {
        const doc = new jsPDF({
            orientation: 'landscape', // Set landscape orientation for wide tables
        });
    
        // Get the table element
        const table = document.getElementById('my-table');
    
        // Extract headers and rows dynamically
        const headers = [...table.querySelectorAll('thead th')].map(th => th.innerText);
        const rows = [...table.querySelectorAll('tbody tr')].map(tr => 
            [...tr.querySelectorAll('td')].map(td => td.innerText)
        );
    
        // Generate the PDF table
        doc.autoTable({
            head: [headers], // Table headers
            body: rows, // Table rows
            startY: 20, // Start table 20mm from top
            theme: 'striped', // Optional: striped table theme
            styles: {
                fontSize: 8, // Smaller font size for fitting more data
                cellPadding: 2, // Add padding for readability
                overflow: 'linebreak', // Handle text overflow without wrapping
            },
            columnStyles: {
                // Optional: Customize specific column widths
                0: { cellWidth: 30 }, // Adjust first column width
                1: { cellWidth: 40 }, // Adjust second column width
                // Add more configurations for other columns as needed
            },
            margin: { top: 10, left: 10, right: 10 }, // Add margins
            horizontalPageBreak: true, // Enable horizontal splitting if needed
            didDrawPage: (data) => {
                // Optional: Add a header or footer on each page
                doc.text('My Custom Table PDF', data.settings.margin.left, 10);
            },
        });
    
        // Save the PDF
        doc.save('table.pdf');
    };
    
    const exportToExcel = () => {
        const table = document.getElementById('my-table');
        const worksheet = XLSX.utils.table_to_sheet(table);
        const workbook = XLSX.utils.book_new();

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Export the workbook as Excel file
        XLSX.writeFile(workbook, 'table_data.xlsx');
        setOpenDownload(false)
    };

    return (
        <>{
            openDownLoad ? <>
                <div className="text-center my-2"><h5>Download</h5></div>
                <div className="mt-3 text-center" style={{fontSize:"1rem"}}>
                    <label htmlFor="excel" className="d-block my-1" onClick={exportToExcel}>Excel</label>
                    <label htmlFor="Pdf" className="d-block my-1" onClick={generatePDF}>PDF</label>
                </div>
            </> : null
        }
        </>
    )
}


const Tables = React.memo(({ TableName, extraButton, editable, colorful, filter, threeDots, heading, criteria }) => {
    const [selectedDate, setSelectedDate] = useState(() => {
      // Set today's date as default
      const today = new Date();
      return today.toISOString().split("T")[0]; // Format: YYYY-MM-DD
    });

    const EditableDate = ({selectedDate, setSelectedDate}) => {
        const [isEditing, setIsEditing] = useState(false);
      
        // Handle date input change
        const handleDateChange = (e) => {
          setSelectedDate(e.target.value);
        };
      
        // Toggle between label and input
        const toggleEdit = () => {
          setIsEditing((prev) => !prev);
        };
      
        return (
          <div>
            {isEditing ? (
              <input
                type="date"
                className='form-control'
                id='small-form-control'
                value={selectedDate}
                onChange={handleDateChange}                
                onBlur={toggleEdit} // Switch back to label when focus is lost
                autoFocus
              />
            ) : (
              <label onClick={toggleEdit} style={{ cursor: "pointer" }}>
                {selectedDate}
              </label>
            )}
          </div>
        );
      };
      







    const today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    const TableDetail = TableData[TableName]
    
    // console.log(TableDetail)
    const [selectedHeading, SetSelectedHeading] = useState(TableDetail?.SelectedLabel)
    const [columns, setColumns] = useState(TableDetail[selectedHeading])
    const [allData, setAllData] = useState([])

    function formatDate(datetype, dateString) {
        if (dateString == undefined) {
            return "";
        }
        else {

            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            const date = new Date(dateString);
            const day = date.getUTCDate();
            const month = months[date.getUTCMonth()];
            const year = date.getUTCFullYear();
            let hours = date.getHours();
            let minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            minutes = minutes.toString().padStart(2, '0');
            if (datetype === "date") {
                return `${day}-${month}-${year}`;
            }
            else {

                return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
            }

        }
    }

    useEffect(() => {
        setColumns(TableDetail[selectedHeading])
    }, [selectedHeading])

    const [openFilter, setOpenFilter] = useState(false)
    const [openDownLoad, setOpenDownload] = useState(false)
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [displayRecords, setDisplayRecords] = useState([])
    const { setFormData, setEditModeOption,setLoading,snackbarMessage, setSnackbarMessage,snackbarOpen, setSnackbarOpen } = useContext(context);
    const divRef = useRef(null);

    const handleClickOutside = (event) => {
        // Check if the click happened outside the div
        if (divRef.current && !divRef.current.contains(event.target)) {
            setOpenDownload(false);
        }
    };

    useEffect(() => {
        // Add event listener for clicks
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Clean up the event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // to get the data from the backend
    useEffect(() => {
        const endPoint = TableDetail?.viewApi?.[selectedHeading].endPoint
        const reqFor = TableDetail?.viewApi?.[selectedHeading].requestFor
        const port = TableDetail?.viewApi?.[selectedHeading].port

        const Url = `${port}/${endPoint}?reqFor=${reqFor}&date=${selectedDate}`
        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await fetch(Url);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                console.log(result)
                if(result.msg==="No Data Found"){
                    setDisplayRecords([])
                    setAllData([])
                }
                else{

                    if (endPoint === "RecentOrderRecords") {
                        console.log("hiiii")
                        let uniqueRecords = Object.values(
                            result.data.reduce((acc, obj) => {
                                acc[obj.OrderID] = obj; // Use orderId as the key to ensure uniqueness
                                return acc;
                            }, {})
                        );
                        console.log(uniqueRecords)
                        setDisplayRecords(uniqueRecords)
                        setAllData(result.data)
                    }
                    else {
    
                        setDisplayRecords(result.data);
                        setAllData(result.data)
    
                    }
                }
                setLoading(false)
            } catch (err) {
                console.log(err.message);
            }
        };
        
            fetchData();
        

    }, [selectedHeading, snackbarOpen,selectedDate]);

  

    // for filtering data
    useEffect(() => {
        if (criteria !== undefined) {

            const filterData = (data, criteria) => {
                return data.filter(item =>
                    Object.entries(criteria).some(([field, value]) => {
                        if (value === "") return true; // If criteria value is empty, include all
                        const itemValue = item[field]?.toString().toLowerCase() || ""; // Ensure string comparison
                        return itemValue.includes(value.toLowerCase()); // Partial, case-insensitive match
                    })
                );
            };
            if (TableName === "orderManagement") {
                console.log(criteria)
                let temp = []
                if (criteria.OrderStatus !== "All Orders") {
                    let uniqueRecords = Object.values(
                        allData.reduce((acc, obj) => {
                            acc[obj.OrderID] = obj; // Use orderId as the key to ensure uniqueness
                            return acc;
                        }, {})
                    );
                    temp = uniqueRecords.filter(item => item.OrderStatus === criteria.OrderStatus);
                    let tempCriteria = { ...criteria }
                    delete tempCriteria.OrderStatus;
                    console.log(tempCriteria)
                    const filteredData = filterData(temp, tempCriteria);
                    console.log(filteredData)
                    setDisplayRecords(filteredData)
                }
                else {
                    if (allData.length > 1) {
                        let uniqueRecords = Object.values(
                            allData.reduce((acc, obj) => {
                                acc[obj.OrderID] = obj; // Use orderId as the key to ensure uniqueness
                                return acc;
                            }, {})
                        );
                        setDisplayRecords(uniqueRecords)
                    }
                }

            }
            else {

                const filteredData = filterData(allData, criteria);
                setDisplayRecords(filteredData)
            }


        }


    }, [criteria, snackbarOpen])


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
// for edit the values
    const editValues = (detail) => {
        console.log(detail);
        let tempFormData = {}
        const base64ToFile = (base64, fileName, key) => {
            const byteString = atob(base64);
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const uint8Array = new Uint8Array(arrayBuffer);

            for (let i = 0; i < byteString.length; i++) {
                uint8Array[i] = byteString.charCodeAt(i);
            }

            const file = new File([uint8Array], fileName, { type: "image/png" });
            tempFormData[key] = file;
        };
        if (TableName === "updateStock") {
            const { ProductID, ProductName, Stock } = detail
            setFormData({ ProductID, ProductName, Stock })
            setEditModeOption(true)
        }
        if (TableName === "productList") {
            const { images } = detail
            const keys = ["mainImage", "2ndImage", "3rdImage", "4thImage", "ThumbnailImage"]
            images.map((image, index) => {
                base64ToFile(image.fileLocation, image?.filename, keys[index])
            })
            const { BrandName, BrandID, CategoryID, CategoryName, ProductID, ProductName, Stock, SubcategoryID, SubcategoryName, description, ruralPrice, urbanPrice } = detail
            tempFormData = { ...tempFormData, BrandName, BrandID, CategoryID, CategoryName, ProductID, ProductName, Stock, SubcategoryID, SubcategoryName, ruralPrice, urbanPrice, detail: description }

            setFormData(tempFormData)
            setEditModeOption(true)

        }
        if (TableName === "dealerList") {
            const {businessLogo,image,ftsApproved,businessName,areaType,aleternateNo,ZIPCode,UserID,State,PhoneNumber,Name,Gstin,Email,City,Address}=detail
            base64ToFile(image.data, image?.filename,"dealerImage")
            base64ToFile(businessLogo.data, businessLogo?.filename,"logo")
            tempFormData = { ...tempFormData, ftsApproved,businessName,areaType,aleternateNo,ZIPCode,UserID,State,PhoneNumber,Name,Gstin,Email,City,Address}
            setFormData(tempFormData)
            setEditModeOption(true)
            navigate("/layout/addDealer")
            console.log(detail)
        }
        if(TableName === "executiveList"){
            const{Image,Email, ExecutiveID, ExecutiveName, PhoneNumber, address, alternateNo, city, dateOFBirth, fatherName, pincode, position, state}=detail
            base64ToFile(Image.data, Image?.filename,"files")
            tempFormData = { ...tempFormData, Email, ExecutiveID, ExecutiveName, PhoneNumber, address, alternateNo, city, dateOfBirth:formatDate("date",dateOFBirth), fatherName, pincode, position, state}
            setFormData(tempFormData)
            setEditModeOption(true)
            navigate("/layout/addExecutive")
            console.log(detail)
        }
    }
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    return (
        <>
            <div className='card myCard' key={selectedHeading} style={{ border: 0, overflowX: 'hidden', overflowY: "hidden" }} >

                {heading === false ? null :
                    <div className="d-flex justify-content-center align-items-center">
                        {TableDetail.TableLabel.map((Tableheading) => (
                            <>{TableDetail.TableLabel.length === 1 ?
                                <>
                                    <label htmlFor="heading" style={{ fontSize: "1rem" }} className='my-3 mx-2' key={Tableheading}>{Tableheading}</label>
                                </> : <>
                                    <label htmlFor="heading" style={{ fontSize: "1rem" }} className={`my-3 mx-2 cursor-pointer ${selectedHeading === Tableheading ? "selectedHeading" : ""}`} key={Tableheading} onClick={() => SetSelectedHeading(Tableheading)}>{Tableheading}</label>

                                </>
                            }</>
                        ))}
                    </div>
                }
                {
                    <>
                        <div ref={divRef} className={`downLoadSection ${openDownLoad === true ? "openDownLoad" : ""} border shadow-sm`}
                            style={{ top: heading === false ? "10vh" : "21vh",borderRadius:".5rem" }}>
                            <DownLoadSection setOpenDownload={setOpenDownload} openDownLoad={openDownLoad} />
                        </div>

                        <div className={`filterSection ${openFilter === true ? "openFilter" : ""} border shadow-sm`}>
                            <FilterSection setOpenFilter={setOpenFilter} allColoums={TableDetail[selectedHeading]} columns={columns} setColumns={setColumns} />
                        </div>
                    </>
                }





                <div className="d-flex justify-content-end align-items-center mb-2 mt-4">
                    {extraButton === "Add Dealer" ?
                        <div className="btn themeButton px-3 py-1 mx-1 d-flex align-items-center" onClick={() => navigate("/layout/addDealer")}>
                            <img src={Add} alt="add button" width={20} className='mx-1' />
                            Add Dealer</div> :
                        extraButton === "Current Date" ? <label className='mx-3'><EditableDate selectedDate={selectedDate} setSelectedDate={setSelectedDate}/></label>
                            : extraButton === "Add Executive" ?
                                <div className="btn themeButton px-3 py-1 mx-1 d-flex align-items-center" onClick={() => navigate("/layout/addExecutive")}>
                                    <img src={Add} alt="add button" width={20} className='mx-1' />
                                    Add Employee</div> :
                                null}







                    {
                        filter === false ? null :
                            <div className="btn themeButton px-3 py-1 mx-1 d-flex align-items-center" onClick={() => setOpenFilter(true)}>
                                <img src={Filter} alt="filter" width={20} className='mx-1' />
                                Filter</div>
                    }
                    <div className="btn themeButton py-1 px-2 mx-1 d-flex align-items-center" onClick={() => { setOpenDownload(!openDownLoad) }}>
                        <img src={DownLoad} alt="downLoad" width={22} className='mx-1' />
                        Download</div>

                </div>



                <TableContainer sx={{ maxHeight: "51vh", marginTop: "5px" }} key={selectedHeading}>
                    <Table stickyHeader aria-label="sticky table" sx={{ zIndex: 999 }} id="my-table">
                        <TableHead >
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell
                                        className={`${colorful ? "colorful-table-head-cell" : "table-head-cell"} fw-bold`}
                                        key={column.name}
                                        align="center"
                                        style={{ minWidth: 200, whiteSpace: "nowrap" }}
                                    >


                                        {column.label}  <img src={DownArrow} alt="down arrow" width={22} />

                                    </TableCell>
                                ))}
                                {
                                    editable ?
                                        <TableCell align='center' className='table-body-cell-sticky' sx={{ position: "sticky", right: "-2px", zIndex: 1000 }}></TableCell>
                                        : null
                                }
                                {
                                    threeDots ?
                                        <TableCell align='center' className='table-body-cell-sticky' sx={{ position: "sticky", right: "-2px", zIndex: 1000 }}></TableCell>
                                        : null
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? displayRecords?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : displayRecords
                            )?.map((items, index) => (
                                <TableRow key={index}>

                                    <>{
                                        columns.map((label, indx) => (
                                            <>
                                                {
                                                    label.type === "dropdown" ?
                                                        <TableCell align='center' className='table-body-cell' style={{ minWidth: 280, fontSize: "1rem" }}>
                                                            {items.OrderStatus === "Accepted" ? <label >"Ongoing"</label> :
                                                                // items.OrderStatus === "Completed" ? 
                                                                <label>{items.OrderStatus}</label> 
                                                                // :
                                                                //     <div className="select_box">
                                                                //         <select class="form-select" id='additional-form-control' value={items.OrderStatus} style={{ color: "#EEEEEE" }} onChange={(e) => { handleStatusChange(items, e.target.value) }}>
                                                                //             {/* for orderManagement */}
                                                                //             {items.OrderStatus === "Pending" ? <>
                                                                //                 <option value="">Status</option>
                                                                //                 <option value="Accepted">Accepted</option>
                                                                //                 <option value="Rejected">Rejected</option>

                                                                //             </> : <>
                                                                //                 <option value="Ongoing">Ongoing</option>
                                                                //                 <option value="Dispatched">Dispatched</option>
                                                                //                 <option value="Completed">Completed</option>

                                                                //             </>


                                                                //             }
                                                                //             {/* {label.dropdownOption.map((options) => (
                                                                //         <option value={options}>{options}</option>
                                                                //     ))} */}
                                                                //         </select>
                                                                //     </div>
                                                                }

                                                        </TableCell> :
                                                        label.type === "button" ?
                                                            <TableCell align='center' className='table-body-cell'>

                                                                <div className="btn themeButton  py-1 px-2 mx-1 d-flex justify-content-center align-items-center">{label.name}</div>
                                                            </TableCell>

                                                            :
                                                            label.type === "image" && displayRecords.length !== 0 ?
                                                                <TableCell align='center' className='table-body-cell'>
                                                                    
                                                                    {"index" in label ?
                                                                        <>
                                                                            {label.label==="Thumbnail Image"?
                                                                                items?.[label.name][(items?.[label.name].length)-1]?.[label.key] === null ? "- - -" : items?.[label.name][items?.[label.name].length-1]?.[label.key]
                                                                            :
                                                                            label.label==="Main Image"?
                                                                                items?.[label.name][0]?.[label.key] === null ? "- - -" : items?.[label.name][0]?.[label.key]
                                                                            :
                                                                            items?.[label.name].length===2?""
                                                                            :
                                                                            <>
                                                                            {items?.[label.name][label.index]?.[label.key] === null ? "- - -" : items?.[label.name][label.index]?.[label.key]}
                                                                            </>}
                                                                        </> :
                                                                        <>
                                                                            {items?.[label.name]?.[label.key] === null ? "- - -" : items?.[label.name]?.[label.key]}
                                                                        </>}
                                                                </TableCell>

                                                                : <TableCell align='center' className='table-body-cell'>{label.label === "Date / Time" ? formatDate("date-time", items[label.name]) : label.label === "Date Of Birth" ? formatDate("date", items[label.name]) :
                                                                    items[label.name] === null ? "- - -" : items[label.name]}</TableCell>
                                                }
                                            </>
                                        ))
                                    }
                                    </>
                                    {
                                        editable ?
                                            <TableCell align='center' onClick={() => editValues(items)} className='table-body-cell-sticky' sx={{ position: "sticky", right: "-2px" }}><img src={Edit} alt="edit" width={23} /></TableCell>
                                            : null
                                    }

                                    {
                                        threeDots ?
                                            <TableCell align='center' onClick={() => navigate(TableDetail?.threeDot?.navigateTo, { state: { id: items[TableDetail?.threeDot?.id] } })} className='table-body-cell-sticky' sx={{ position: "sticky", right: "-2px" }}><img src={ThreeDots} alt="edit" width={23} /></TableCell>
                                            : null
                                    }

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className='table-pagination'
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={displayRecords?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

            </div>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                message={snackbarMessage}
                action={action}
            />
        </>
    )
})

export default Tables
