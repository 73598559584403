import React, { useContext, useEffect, useState } from 'react'
import { BlackFlight, BlackHome, BlackShop, WhiteFlight, WhiteHome, WhiteShop } from '../../../Assets/Images/ExportSvg'
import TileManagementForm from './TileManagementForm'
import HomePageTile from './HomePageTile'
import ShopPage from './ShopPage'
import FtsScheme from './FtsScheme'
import FtsTopBox from './FtsTopBox'
import FtsBottomBox from './FtsBottomBox'
import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { context } from '../../Context/Context'


const TileManagement = () => {
    const {setLoading,snackbarMessage, setSnackbarMessage,snackbarOpen, setSnackbarOpen} = useContext(context);
    const [tileDetail, setTileDetail] = useState({})
    const [tileData, setTileData] = useState({})
    const [dataFromBackend, setDataFromBackend] = useState(null)
    const [selectedPage, setSelectedPage] = useState("Home Page")
    const [selectedTab, setSelectedTab] = useState({ name: "achieverSlider", label: "Achiever Slider" })
    
    useEffect(() => {
        setSelectedTab(data[selectedPage].options[0])
    }, [selectedPage])

    const handleSelectPage = (page) => {
        setSelectedTab(data[page].options[0])
        setSelectedPage(page)
    }
    const data = {
        "Home Page": {
            BlackImage: BlackHome,
            WhiteImage: WhiteHome,
            options: [
                { name: "mainSlider", label: "Main Slider" },
                { name: "achieverSlider", label: "Achiever Slider" },
                { name: "employeeSlider", label: "Employee Slider" },
                { name: "newLaunchedProduct", label: "New Launched Product" },
                { name: "ftsScheme", label: "FTS Scheme" }
            ]

        },
        "Shop Page": {
            BlackImage: BlackShop,
            WhiteImage: WhiteShop,
            options: [
                { name: "popularProduct", label: "Popular Products" }
            ]

        },

        "FTS Scheme": {
            BlackImage: BlackFlight,
            WhiteImage: WhiteFlight,
            options: [
                { name: "ftsMainScreen", label: "FTS Main Screen" },
                { name: "ftsTopBoxPage", label: "FTS Top Box Page" },
                { name: "ftsBottomBoxPage", label: "FTS Bottom Box Page" }
            ]

        },
    }
    console.log("tileDetail", tileDetail)
    useEffect(() => {
        let endPoint = "ViewHomePageData"
        let reqFor = ""
        let port = "https://marina-world-tiles.onrender.com"

        if (selectedPage === "Home Page") {
             endPoint = "ViewHomePageData"
             reqFor = ""
        }
        else if (selectedPage === "Shop Page") {
             endPoint = "ViewShopPageData"
             reqFor = ""
        }
        else if (selectedPage === "FTS Scheme" && selectedTab.name === "ftsMainScreen") {
             endPoint = "ViewftsPageData"
             reqFor = "ftsMainScreen"
        }
        else if (selectedPage === "FTS Scheme" && selectedTab.name === "ftsTopBoxPage") {
             endPoint = "ViewftsPageData"
             reqFor = "ftsTopBoxPage"

        }
        else if (selectedPage === "FTS Scheme" && selectedTab.name === "ftsBottomBoxPage") {
             endPoint = "ViewftsPageData"
             reqFor = "ftsBottomBoxPage"

        }

        const Url = `${port}/${endPoint}?reqFor=${reqFor}`
        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await fetch(Url);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                console.log(result.data)
                setDataFromBackend(result.data)             
                setLoading(false)
            } catch (err) {
                console.log(err.message);
            }
        };
        fetchData();


    }, [selectedPage, selectedTab,tileDetail])

    useEffect(() => {
        if (selectedPage === "Home Page" && selectedPage === "Shop Page") {
            setTileDetail({})
        }
        else if (selectedPage === "FTS Scheme" && selectedTab.name === "ftsMainScreen" ||
            selectedPage === "FTS Scheme" && selectedTab.name === "ftsTopBoxPage" ||
            selectedPage === "FTS Scheme" && selectedTab.name === "ftsBottomBoxPage"
        ) {
            setTileDetail({})
        }

    }, [selectedPage, selectedTab])


    const handleUpdate = () => {
        console.log(tileDetail)
        let formdata = {}
        let endPoint = ""
        let folder = ""
        const formData = new window.FormData();
        if (selectedPage === "Home Page") {
            formdata = (tileDetail.homePage)
            endPoint = "addHomePage"
            folder = "homepage"

            if ("achieverSlider" in formdata) {
                console.log("achieverSliderrrrrrrrrr")
                // Append achieverSlider
                for (const [key, value] of Object.entries(formdata.achieverSlider)) {
                    formData.append(`achieverSlider[${key}]`, value);
                }
            }

            if ("employeeSlider" in formdata) {
                // Append employeeSlider
                for (const [key, value] of Object.entries(formdata.employeeSlider)) {
                    formData.append(`employeeSlider[${key}]`, value);
                }
            }

            if ("ftsScheme" in formdata) {
                // Append ftsScheme image
                formData.append('ftsScheme', formdata.ftsScheme.image);
            }
            if ("mainSlider" in formdata) {
                // Append mainSlider files
                formdata.mainSlider.forEach((file, index) => {
                    formData.append(`mainSlider[${index}]`, file);
                });
            }

            if ("newLaunchedProduct" in formdata) {
                // Append newLaunchedProduct
                formData.append('newLaunchedProduct[product]', formdata.newLaunchedProduct.product);
            }
        }
        else if (selectedPage === "Shop Page") {
            formdata = (tileDetail.shopPage)
            endPoint = "addShopPage"
            folder = "shoppage"
            if ("popularProduct" in formdata) {
                for (const key in formdata.popularProduct) {
                    formData.append(`popularProduct[${key}]`, formdata.popularProduct[key]);
                }
            }
            console.log(formdata)
        }
        else if (selectedPage === "FTS Scheme" && selectedTab.name === "ftsMainScreen") {
            formdata = (tileDetail.ftsScheme.ftsMainScreen)
            endPoint = "addftsPage"
            folder = "ftsMainScreen"
            if ("bottomBoxData" in formdata) {
                for (const key in formdata.bottomBoxData) {
                    formData.append(`bottomBoxData[${key}]`, formdata.bottomBoxData[key]);
                }
            }
            if ("mainQuote" in formdata) {
                for (const key in formdata.mainQuote) {
                    formData.append(`mainQuote`, formdata.mainQuote[key]);
                }
            }
            if ("topBoxData" in formdata) {
                for (const key in formdata.topBoxData) {
                    formData.append(`topBoxData[${key}]`, formdata.topBoxData[key]);
                }
            }
            console.log(formdata)

        }
        else if (selectedPage === "FTS Scheme" && selectedTab.name === "ftsTopBoxPage") {
            formdata = (tileDetail.ftsScheme.ftsTopBoxPage)
            endPoint = "addftsPage"
            folder = "ftsTopBoxPage"
            if ("firstBoxData" in formdata) {
                for (const key in formdata.firstBoxData) {
                    formData.append(`firstBoxData[${key}]`, formdata.firstBoxData[key]);
                }
            }
            if ("secondBoxData" in formdata) {
                for (const key in formdata.secondBoxData) {
                    formData.append(`secondBoxData[${key}]`, formdata.secondBoxData[key]);
                }
            }
            if ("thirdBoxData" in formdata) {
                for (const key in formdata.thirdBoxData) {
                    formData.append(`thirdBoxData[${key}]`, formdata.thirdBoxData[key]);
                }
            }
            if ("termsAndConditionsBoxData" in formdata) {
                for (const key in formdata.termsAndConditionsBoxData) {
                    formData.append(`termsAndConditionsBoxData`, formdata.termsAndConditionsBoxData[key]);
                }
            }
        }
        else if (selectedPage === "FTS Scheme" && selectedTab.name === "ftsBottomBoxPage") {
            formdata = (tileDetail.ftsScheme.ftsBottomBoxPage)
            endPoint = "addftsPage"
            folder = "ftsBottomBoxPage"
            if ("firstBoxData" in formdata) {
                for (const key in formdata.firstBoxData) {
                    formData.append(`firstBoxData[${key}]`, formdata.firstBoxData[key]);
                }
            }
            if ("secondBoxData" in formdata) {
                for (const key in formdata.secondBoxData) {
                    formData.append(`secondBoxData[${key}]`, formdata.secondBoxData[key]);
                }
            }
            if ("thirdBoxData" in formdata) {
                for (const key in formdata.thirdBoxData) {
                    formData.append(`thirdBoxData[${key}]`, formdata.thirdBoxData[key]);
                }
            }
            if ("termsAndConditionsBoxData" in formdata) {
                for (const key in formdata.termsAndConditionsBoxData) {
                    formData.append(`termsAndConditionsBoxData`, formdata.termsAndConditionsBoxData[key]);
                }
            }
        }

        const Url = `https://marina-world-tiles.onrender.com/${endPoint}?FolderName=${folder}`
        fetch(Url, {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data, "dataaaaaaa")
                if (data.msg == "data uploaded sucessfully") {
                    setTileData({})
                    setTileDetail({})
                    setSnackbarMessage(data.msg)
                    setSnackbarOpen(true)
                }


            })
            .catch((error) => {
                console.error('Error:', error);
                //   setMessage('Failed to post data.');
            });

    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <div className="row">
                <div className="col-5 text-center">
                    {selectedPage === "Home Page" ? <HomePageTile tileDetail={tileDetail} dataFromBackend={dataFromBackend} /> :
                        selectedPage === "Shop Page" ? <ShopPage tileDetail={tileDetail} dataFromBackend={dataFromBackend}/> :
                            selectedPage === "FTS Scheme" && selectedTab.name === "ftsMainScreen" ? <FtsScheme tileDetail={tileDetail} dataFromBackend={dataFromBackend}/> :
                                selectedPage === "FTS Scheme" && selectedTab.name === "ftsTopBoxPage" ? <FtsTopBox tileDetail={tileDetail} dataFromBackend={dataFromBackend}/> :
                                    selectedPage === "FTS Scheme" && selectedTab.name === "ftsBottomBoxPage" ? <FtsBottomBox tileDetail={tileDetail} dataFromBackend={dataFromBackend}/> : null
                    }
                    <button className="btn button px-4 py-1 mt-3" onClick={handleUpdate}>Update Changes</button>
                    <button className="btn whiteThemeButton d-block px-4 my-1 mx-auto" onClick={()=>setTileDetail({})} style={{ border: 0 }}>Cancel</button>

                </div>
                <div className="col-7">
                    <div className="d-flex justify-content-center align-items-center">
                        {Object.keys(data).map((pageName) => (
                            <button className={`${selectedPage === pageName ? "themeButton" : "whiteThemeButton1"} d-flex align-items-center mx-2 py-1`} onClick={() => { handleSelectPage(pageName) }}>
                                <img src={selectedPage === pageName ? data[pageName].WhiteImage : data[pageName].BlackImage} alt={pageName} className='mx-1' width={21} />
                                <label htmlFor={pageName} className='mx-1'>{pageName}</label>
                            </button>
                        ))}
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                        {data[selectedPage].options.map((formheading) => (
                            <>{data[selectedPage].options.length === 1 ?
                                <>
                                    <label htmlFor="heading" className='my-3 mx-2' key={formheading.name} style={{ fontSize: ".8rem" }}>{formheading.label}</label>
                                </> : <>
                                    <label htmlFor="heading" className={`my-3 mx-2 cursor-pointer ${selectedTab.name === formheading.name ? "selectedHeading" : ""}`} key={formheading.name} onClick={() => setSelectedTab(formheading)} style={{ fontSize: ".8rem" }}>{formheading.label}</label>

                                </>
                            }</>
                        ))}
                    </div>

                    <TileManagementForm selectedPage={selectedPage} selectedTab={selectedTab.name} tileData={tileData} setTileData={setTileData} tileDetail={tileDetail} setTileDetail={setTileDetail} dataFromBackend={dataFromBackend} />
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical:"bottom",horizontal:"right"}}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                message={snackbarMessage}
                action={action}
            />

        </div>
    )
}

export default TileManagement
