import React, { useEffect, useState } from 'react'
import { LeaderBoardsvg, MittalHospital } from '../../Assets/Images/ExportSvg'

const LeaderBoard = () => {
  const [dealers,setDealers]=useState([])
   useEffect(() => {
                
                 const endPoint="TopAchievers"
                 const Url = `https://marina-world-users.onrender.com/${endPoint}`
                 const fetchData = async () => {
                     try {
                         const response = await fetch(Url);
                         if (!response.ok) {
                             throw new Error(`HTTP error! status: ${response.status}`);
                         }
                         const result = await response.json();
                         console.log(result)
                         if(result.msg==="no orders found"){
                          setDealers([])
                         }
                         else if(result.msg==="data found"){                        
                          setDealers(result.data)                           
                         }
                     } catch (err) {
                         console.log(err.message);
                     }
                 };
                 
                     fetchData();
                 
             
             }, []);
             const totalDealers = dealers?.length;
  return (
    <>
    <div className="d-flex flex-column align-items-center justify-content-center  my-2">
        <label htmlFor="fts Scheme" style={{fontSize:"1rem"}}>FTS Scheme</label>
        <label htmlFor="leaderBoard">Leader Board</label>
    </div>

    {dealers.map((dealer,index)=>(

      <div className="d-flex justify-content-between align-items-center py-2 border-bottom">
        <img src={`data:${dealer?.image?.filetype};base64,${dealer?.image?.data}`} alt="" className="rounded-circle"  width={"30rem"} height={"30rem"}/>
        <div className="d-flex flex-column align-items-center justify-content-center  my-2">
        <label htmlFor="fts Scheme" style={{fontSize:"1rem"}}>{dealer?.Name}</label>
        <label htmlFor="leaderBoard"><label style={{fontSize:".9rem"}}>{index+1}</label> out of {totalDealers}</label>
    </div>
        <img src={LeaderBoardsvg} alt="" width={"30rem"} />

    </div>
    ))}
    </>
  )
}

export default LeaderBoard