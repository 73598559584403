import React from 'react'
import { CompanyGroupPicture, LogoGroup, MarinaWorld, MarinaWorldLogo,MittalHospital,JainTemple,Jln,Dargah,ArgCity,MitallMall ,Facebook,Instagram,Phone,Gmail} from '../../Assets/Images/ExportSvg'

const CompanyDetails = () => {
  const companyDetail={
    note:"In memory of Late Mr. Sunder Keswani Ji, we honor the guiding principles he instilled in Marina Electric General Store from 1975 to 2018. His belief that \"big success does not come without significant challenges\" remains central as we continue our journey. Embracing the principle of \"Be Indian, buy Indian,\" we strive for simplicity and focus on addressing real issues with a personal approach and a forward-thinking mindset. As CEO, my goal is to empower each team member to reach their highest potential, fostering kindness toward one another and animals. By learning from nature, we continue to grow, innovate, and carry forward his legacy of excellence."
  }
  const network=[
    {icon:Gmail,path:""},
    {icon:Phone,path:""},
    {icon:Instagram,path:""},
    {icon:Facebook,path:""},
  ]
  const ClientsPictures=[
    {name:"Mittal Mall",icon:MitallMall},    
    {name:"ARG City",icon:ArgCity},    
    {name:"Mittal Hospital",icon:MittalHospital},
    {name:"JLN Hospital Ajmer",icon:Jln},
    {name:"Jain Temple Nareli",icon:JainTemple},
    {name:"Dargah Sharif Ajmer",icon:Dargah},
  ]
  return (
    <div>
      <div className="w-75 mx-auto my-2">
        <div className="d-flex justify-content-center">
        <img
              src={MarinaWorldLogo}
              alt="logo"
              className="mx-1"
              style={{ width: "4rem", height: "auto" }}
            />

        </div>
        <div className="d-flex justify-content-center">
        <img
              src={MarinaWorld}
              alt="logo"
              className="mx-1"
              style={{ width: "6rem", height: "auto",marginBlock:"-4rem" }}
            />

        </div>

        <div className="row my-5 align-items-center">
          <div className="col-6">
            <p>{companyDetail.note}</p>
          </div>
          <div className="col-6">
            <img src={CompanyGroupPicture} alt="group Picture" className='w-100 mx-1' />
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center flex-column">
          <label>Some Cool Facts</label>
          <label style={{fontSize:"1rem"}}>Numbers Speak For Themselves</label>
        </div>

        <div className="d-flex align-items-center justify-content-evenly my-3">
          <div className='text-center'>
            <div style={{fontSize:"1.3rem"}}>50+</div>
            <div>Years of Experience</div>
          </div>
          <div className='text-center'>
            <div style={{fontSize:"1.3rem"}}>1000+ </div>
            <div>Product Range</div>
          </div>
          <div className='text-center'>
            <div style={{fontSize:"1.3rem"}}>1000+ </div>
            <div>Satisfied Customers</div>
          </div>

        </div>

        <div className="d-flex align-items-center justify-content-center mt-5 mb-3">
          <label style={{fontSize:"1rem"}} className='w-50 mt-5 text-wrap text-center'>We Are The Creators You Can Trust On For A Perfect Solution To All Your Needs</label>
        </div>

        <div className="d-flex align-items-center justify-content-center my-3"></div>
          <img src={LogoGroup} alt="logos" className='w-100' />
      </div>

      <div className="d-flex align-items-center justify-content-center mt-5 mb-3">
        <label style={{fontSize:"1rem"}} className='my-3'>Projects</label>
      </div>

      <div className="row w-75 mx-auto">
       { ClientsPictures.map((client,index)=>(
        <>
        <div key={index} className="col-lg-4 col-6 text-center mb-3">
        <div className="  mx-auto">
          <img src={client.icon} alt="" className='' />
        </div>
        <label className='my-1'>{client.name}</label>
        </div>
        </>
        ))}
      </div>

      <div className="d-flex align-items-center justify-content-center my-2">
        {network.map((val,index)=>(
          <><img src={val.icon} key={index} alt="" width={"15rem"} className='mx-2' style={{cursor:"pointer"}} />
          </>
        ))}
      </div>
      
    </div>
  )
}

export default CompanyDetails
