const FormData = {
    brandAdd: {
        formLabel: ["Brand Add"],
        SelectedLabel: "Brand Add",
        imagePort:"https://marina-world-tiles.onrender.com",
        port:"https://marina-world-order.onrender.com",
        initialValue:{
            "Brand Add":{BrandID:"sendBrandId"}
        },
        onSave:{
            "Brand Add":["addBrand","fileSend"]
        },
        noOfFields:{
         "Brand Add":3 
        },
        "Brand Add": [
            {
                "name": "BrandID",
                "readOnly":true,
                "type": "text",
                "label": "Sr. No.",
                "validation": [
                    "AlphaNumeric",
                    ""
                ]

            },
            {
                "name": "brandName",
                "type": "text",
                "label": "Brand Name",
                "validation": [
                    "AlphaNumeric",
                    ""
                ]

            },
            {
                "name": "files",
                "type": "upload",
                "label": "Upload Logo",

            },
        ],
        buttons: ["Save", "Cancel"]
    },
    categoryManagement: {
        formLabel: ["Category Add", "Sub-Category Add"],
        SelectedLabel: "Category Add",
        port:"https://marina-world-order.onrender.com",
        initialValue:{
            "Category Add":{CategoryID:"sendCatId"},
            "Sub-Category Add":{SubcategoryID:"senSubCatId"}
        },
        onSave:{
            "Category Add":["addCategory","jsonSend"],
            "Sub-Category Add":["addSubCategory","jsonSend"]
        },
        noOfFields:{
            "Category Add":3,
            "Sub-Category Add":3
           },
        dropdown:{
                "Category Add":[
                    {   dropDownName:"brand",
                        endPoint:"sendBrandId",
                        requestfor:"brands",
                        show:"BrandName",
                        send:"BrandID",

                    }
                ],
                "Sub-Category Add":[
                    {   dropDownName:"category",
                        endPoint:"sendCatId",
                        requestfor:"categories",
                        show:"CategoryName",
                        send:"CategoryID",

                    }
                ]
        },

        "Category Add": [
            {
                "name": "CategoryID",
                "readOnly":true,
                "type": "text",
                "label": "Sr. No.",
                "validation": [
                    "AlphaNumeric",
                    ""
                ]

            },
            {
                "name": "CategoryName",
                "type": "text",
                "label": "Category Name",
                "validation": [
                    "AlphaNumeric",
                    ""
                ]

            },
            {
                "name": "BrandID",
                "type": "dropdown",
                "label": "Brand Select",
                "key":"brand",

            },
        ],
        "Sub-Category Add": [
            {
                "name": "SubcategoryID",
                "readOnly":true,
                "type": "text",
                "label": "Sr. No.",
                "validation": [
                    "AlphaNumeric",
                    ""
                ]

            },
            {
                "name": "CategoryID",
                "type": "dropdown",
                "label": "Category Select",
                "key":"category"

            },
            {
                "name": "SubcategoryName",
                "type": "text",
                "label": "Sub-Category Name",
                "validation": [
                    "AlphaNumeric",
                    ""
                ]

            },
        ],
        buttons: ["Save", "Cancel"]
    },
    productAdd: {
        formLabel: ["Product Add"],
        SelectedLabel: "Product Add",
        editLabel:"Update Product",
        imagePort:"https://marina-world-tiles.onrender.com",
        port:"https://marina-world-order.onrender.com",
        initialValue:{
            "Product Add":{ProductID:"sendProductID"},
        },
        onSave:{
            "Product Add":["addProduct","fileSend"]
        },
        onEdit:{
            "Product Add":["updateProductDetail","fileSend"]
        },
        noOfFields:{
            "Product Add":11
           },
           editNoOfFields:{
            "Product Add":14
           },
        
        dropdown:{
            "Product Add":[
                {   dropDownName:"brand",
                    endPoint:"sendBrandId",
                    requestfor:"brands",
                    show:"BrandName",
                    send:"BrandID",

                },            
                {   dropDownName:"category",
                    endPoint:"sendCatId",
                    requestfor:"categories",
                    show:"CategoryName",
                    send:"CategoryID",

                },
                {   dropDownName:"subCategory",
                    endPoint:"senSubCatId",
                    requestfor:"subcategories",
                    show:"SubcategoryName",
                    send:"SubcategoryID",

                }
            ]
    },
        "Product Add": [
            {
                "name": "ProductID",
                "type": "text",
                "label": "Product ID",
                "validation": [
                    "AlphaNumeric",
                    ""
                ]

            },
            {
                "name": "ProductName",
                "type": "text",
                "label": "Product Name",
                "validation": [
                    "AlphaNumeric",
                    ""
                ]

            },
            {
                "name": "BrandID",
                "type": "dropdown",
                "label": "Brand Select",
                "key":"brand"

            },
            {
                "name": "CategoryID",
                "type": "dropdown",
                "label": "Category Select",
                "key":"category",
                "filter":true,
                "filterOnBasis":"BrandID"


            },
            {
                "name": "SubcategoryID",
                "type": "dropdown",
                "label": "Sub-Category Select",
                "key":"subCategory",
                "filter":true,
                "filterOnBasis":"CategoryID"

            },
            {
                "name": "urbanPrice",
                "type": "text",
                "label": "Urban Price",
                "validation": [
                    "Numeric",
                    ""
                ]

            },
            {
                "name": "ruralPrice",
                "type": "text",
                "label": "Rural Price",
                "validation": [
                    "Numeric",
                    ""
                ]

            },
            {
                "name": "mainImage",
                "type": "upload",
                "label": "Main Image",
                "flex": true,

            },
            {
                "name": "2ndImage",
                "type": "upload",
                "label": "2nd Image",
                "flex": true,

            },
            {
                "name": "3rdImage",
                "type": "upload",
                "label": "3rd Image",
                "flex": true,

            },
            {
                "name": "4thImage",
                "type": "upload",
                "label": "4th Image",
                "flex": true,

            },
            {
                "name": "ThumbnailImage",
                "type": "upload",
                "label": "Thumbnail Image",

            },
            {
                "name": "Stock",
                "type": "text",
                "label": "Stock",
                "validation": [
                    "Numeric",
                    ""
                ]


            },
            {
                "name": "detail",
                "type": "text-Box",
                "label": "Details",    


            },
        ],
        buttons: ["Save", "Cancel"]
    },
    dealerAdd: {
        formLabel: ["Add Dealer"],
        SelectedLabel: "Add Dealer",
        editLabel:"Update Dealer",
        imagePort:"https://marina-world-tiles.onrender.com",
        port:"https://marina-world-users.onrender.com",
        initialValue:{
            "Add Dealer":{UserID:"userDetails"},
        },
        onSave:{
            "Add Dealer":["addDealer","fileSend"]
        },
        onEdit:{
            "Add Dealer":["updateDealer","fileSend"]
        },
        noOfFields:{
            "Add Dealer":16
           },
           editNoOfFields:{
            "Add Dealer":15
           },
        "Add Dealer": [
            {
                "label":"Personal Details",
                "type":"labelOnly"
            },
            {
                "name": "UserID",
                "type": "text",
                "label": "Dealer ID",
                "validation": [
                    "AlphaNumeric",
                    ""
                ],
                "flex": true
            },
            {
                "name": "businessName",
                "type": "text",
                "label": "Business Name",
                "validation": [
                    "AlphaNumeric",
                    ""
                ],
                "flex": true
            },
            {
                "name": "Gstin",
                "type": "text",
                "label": "GST Number",
                "validation": [
                    "AlphaNumeric",
                    ""
                ],
                "flex": true
            },
            {
                "name": "Address",
                "type": "text",
                "label": "Address",
                "validation": [
                    "AlphaNumeric",
                    ""
                ],
                "flex": true
            },
            {
                "name": "State",
                "type": "text",
                "label": "State",
                "validation": [
                    "Alpha",
                    ""
                ],
                "flex": true
            },
            {
                "name": "City",
                "type": "text",
                "label": "City",
                "validation": [
                    "Alpha",
                    ""
                ],
                "flex": true
            },
            {
                "name": "ZIPCode",
                "type": "text",
                "label": "Pin Code",
                "validation": [
                    "Numeric",
                    "6"
                ],
                "flex": true
            },
            {
                "name": "areaType",
                "type": "text",
                "label": "Area Type",
                "validation": [
                    "AlphaNumeric",
                    ""
                ],
                "flex": true
            },
            {
                "name": "logo",
                "type": "upload",
                "label": "Logo",
                "flex": true
            },
            {
                "name": "Name",
                "type": "text",
                "label": "Owner Name",
                "validation": [
                    "Alpha",
                    ""
                ],
                "flex": true
            },
            {
                "name": "PhoneNumber",
                "type": "text",
                "label": "Contact Number",
                "validation": [
                    "Numeric",
                    "10"
                ],
                "flex": true
            },
            {
                "name": "aleternateNo",
                "type": "text",
                "label": "Alternate Number",
                "validation": [
                    "Numeric",
                    "10"
                ],
                "flex": true
            },                   
            {
                "name": "Email",
                "type": "text",
                "label": "Email",
                "validation": [
                    "Email",
                    ""
                ],
                "flex": true
            },
            {
                "name": "dealerImage",
                "type": "upload",
                "label": "Dealer Image",
                "flex": true
            },
            {
                "label":"Login Details",
                "type":"labelOnly"
            },
            {
                "name": "userName",
                "type": "text",
                "label": "Dealer Username",
                "validation": [
                    "AlphaNumeric",
                    ""
                ],
                "flex": true
            },
            {
                "name": "password",
                "type": "password",
                "label": "Password",
                "validation": [
                    "AlphaNumeric",
                    ""
                ],
                "flex": true
            },
            {
                "label":"Foreign Trip Scheme",
                "type":"labelOnly"
            },
            {
                "name": "ftsApproved",
                "type": "checkbox",
                "label": "Include in FTS Scheme",
                "checkboxOption":["Yes","No"],
            },
        ],
        buttons: ["Save", "Cancel"]
    },
    executiveAdd:{
        "formLabel": ["Add Employee"],
        "SelectedLabel": "Add Employee",
        editLabel:"Update Employee",
        imagePort:"https://marina-world-tiles.onrender.com",
        port:"https://marina-world-users.onrender.com",
        initialValue:{
            "Add Employee":{ExecutiveID:"executives"},
        },
        onSave:{
            "Add Employee":["addExecutive","fileSend"]
        },
        onEdit:{
            "Add Employee":["updateExecutive","fileSend"]
        },
        noOfFields:{
            "Add Employee":16
           },
           editNoOfFields:{
            "Add Employee":13
           },
        "Add Employee": [
          {
            "label": "Personal Details",
            "type": "labelOnly"
          },
          {
            "name": "ExecutiveID",
            "type": "text",
            "label": "Executive ID",
            "validation": [
              "AlphaNumeric",
              ""
            ],
            "flex": true
          },
          {
            "name": "ExecutiveName",
            "type": "text",
            "label": "Name",
            "validation": [
              "Alpha",
              ""
            ],
            "flex": true
          },
          {
            "name": "fatherName",
            "type": "text",
            "label": "Father Name",
            "validation": [
              "Alpha",
              ""
            ],
            "flex": true
          },
          {
            "name": "position",
            "type": "text",
            "label": "Position",
            "validation": [
              "AlphaNumeric",
              ""
            ],
            "flex": true
          },
          {
            "name": "dateOfBirth",
            "type": "date",
            "label": "Date of Birth",
            "validation": [
              "Date",
              ""
            ],
            "flex": true
          },
          {
            "name": "address",
            "type": "text",
            "label": "Address",
            "validation": [
              "AlphaNumeric",
              ""
            ],
            "flex": true
          },
          {
            "name": "city",
            "type": "text",
            "label": "City",
            "validation": [
              "Alpha",
              ""
            ],
            "flex": true
          },
          {
            "name": "state",
            "type": "text",
            "label": "State",
            "validation": [
              "Alpha",
              ""
            ],
            "flex": true
          },
          {
            "name": "pincode",
            "type": "text",
            "label": "Pin Code",
            "validation": [
              "Numeric",
              "6"
            ],
            "flex": true
          },
          {
            "name": "Email",
            "type": "text",
            "label": "Email",
            "validation": [
              "Email",
              ""
            ],
            "flex": true
          },
          {
            "name": "PhoneNumber",
            "type": "text",
            "label": "Contact Number",
            "validation": [
              "Numeric",
              "10"
            ],
            "flex": true
          },
          {
            "name": "alternateNo",
            "type": "text",
            "label": "Alternate Number",
            "validation": [
              "Numeric",
              "10"
            ],
            "flex": true
          },
          {
            "name": "files",
            "type": "upload",
            "label": "Photo",
            "flex": true
          },
          {
            "name": "role",
            "type": "dropdown",
            "label": "Select Role",
            "key":"role",
            "flex": true

        },
          {
            "label": "Login Details",
            "type": "labelOnly"
          },
          {
            "name": "userName",
            "type": "text",
            "label": "Employee UserName",
            "validation": [
              "AlphaNumeric",
              ""
            ],
            "flex": true
          },
          {
            "name": "password",
            "type": "password",
            "label": "Password",
            "validation": [
              "AlphaNumeric",
              ""
            ],
            "flex": true
          }
        ],
        "buttons": ["Save", "Cancel"]
      },
      updateStock: {
        formLabel: ["Update Stock"],
        SelectedLabel: "Update Stock",
        port:"https://marina-world-order.onrender.com",
        onEdit:{
            "Update Stock":["updateStock","jsonSend"]
        },
        noOfFields:{
         "Update Stock":4
        },
        editNoOfFields:{
            "Update Stock":4
           },
        "Update Stock": [
            {
                "name": "ProductID",
                "readOnly":true,
                "type": "text",
                "label": "Product ID",
                "validation": [
                    "AlphaNumeric",
                    ""
                ]

            },
            {
                "name": "ProductName",
                "readOnly":true,
                "type": "text",
                "label": "Product Name",
                "validation": [
                    "AlphaNumeric",
                    ""
                ]

            },
            // {
            //     "name": "date",
            //     "type": "date",
            //     "label": "Date",
            //     "validation": [
            //       "Date",
            //       ""
            //     ],

            // },
             {
                "name": "Stock",
                "type": "text",
                "readOnly":true,
                "label": "Current Stock",
                "validation": [
                    "Numeric",
                    ""
                ]

            }, 
            {
                "name": "newStock",
                "type": "text",
                "label": "New Stock",
                "validation": [
                    "Numeric",
                    ""
                ]

            },
        ],
        buttons: ["Save", "Cancel"]
    },
   
      
    
    
}


export { FormData }