export const AlphaInput = (event) => {
    const regex = /^[a-zA-Z\s]*$/; // Allows only alphabets and space
    const key = String.fromCharCode(event.which || event.keyCode);

    if (!regex.test(key)) {
      event.preventDefault(); // Prevent the keypress if it's not a valid character
    }
  
  }
export  const NumericInput = (event) => {
  const regex = /^[0-9]*$/; // Allows only numeric values (digits 0-9)
  const key = String.fromCharCode(event.which || event.keyCode);

  if (!regex.test(key)) {
    event.preventDefault(); // Prevent the keypress if it's not a numeric character
  }
};
export const AlphaNumericInput = (event) => {
  const regex = /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>_\-\s]*$/; // Allows alphanumeric, special characters, and space
  const key = String.fromCharCode(event.which || event.keyCode);

  if (!regex.test(key)) {
    event.preventDefault(); // Prevent the keypress if it's not a valid character
  }
};