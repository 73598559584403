import React from 'react'
import diamondClub from "../../../Assets/TempImages/Images/diamondClub.png"
import platinum from "../../../Assets/TempImages/Images/platinum.png"
import "./ShopPage.css"

const FtsScheme = ({ tileDetail, dataFromBackend }) => {
  console.log(tileDetail, "tileDetail")
  return (
    <div className="card loginCard">
      <div className="card-body">



        <div className='page-padding' style={{ paddingTop: "0rem" }}>
          <div className="scheme-header text-center">
            <h1>FTS Scheme</h1>
            <p>Foreign Trip Scheme</p>
            <p style={{ fontSize: "0.9rem" }}>
              {/* We aim to bring greater happiness to our channel partner improving their quality of life, while enhancing community value. */}
              {(tileDetail &&
                "ftsScheme" in tileDetail &&
                "ftsMainScreen" in tileDetail?.ftsScheme &&
                "mainQuote" in tileDetail?.ftsScheme?.ftsMainScreen) ? tileDetail?.ftsScheme?.ftsMainScreen?.mainQuote?.mainQuote :
                (dataFromBackend && "mainQuote" in dataFromBackend) && dataFromBackend.mainQuote}
            </p>
          </div>

          <div className="scheme-grid">
            {(tileDetail &&
              "ftsScheme" in tileDetail &&
              "ftsMainScreen" in tileDetail?.ftsScheme &&
              "topBoxData" in tileDetail?.ftsScheme?.ftsMainScreen) ?
              <div className="loginCard my-3 d-flex align-items-center justify-content-around py-5">

                <img
                style={{maxWidth:"8rem",maxHeight:"8rem"}}
                  src={"schemeImage" in tileDetail?.ftsScheme?.ftsMainScreen?.topBoxData ?
                    URL.createObjectURL(tileDetail?.ftsScheme?.ftsMainScreen?.topBoxData?.schemeImage) :
                    `data:${dataFromBackend.topBoxData?.schemeImage?.mimetype};base64,${dataFromBackend.topBoxData?.schemeImage?.data}`}
                  alt={tileDetail?.ftsScheme?.ftsMainScreen?.topBoxData?.schemeName} />

                <div className="club-text">
                  <h5>{"schemeName" in tileDetail?.ftsScheme?.ftsMainScreen?.topBoxData ?
                  tileDetail?.ftsScheme?.ftsMainScreen?.topBoxData?.schemeName:
                  dataFromBackend.topBoxData?.schemeName
                  }</h5>
                  <h5 className='no-text-wrap'>{ "schemeDuration" in tileDetail?.ftsScheme?.ftsMainScreen?.topBoxData ? 
                  tileDetail?.ftsScheme?.ftsMainScreen?.topBoxData?.schemeDuration:
                  dataFromBackend.topBoxData?.schemeDuration
                  }</h5>
                </div>
              </div>
              :
              dataFromBackend && "topBoxData" in dataFromBackend &&
              <div className="loginCard my-3 d-flex align-items-center justify-content-around py-5">

                <img
                style={{maxWidth:"8rem",maxHeight:"8rem"}}
                  src={`data:${dataFromBackend.topBoxData?.schemeImage?.mimetype};base64,${dataFromBackend.topBoxData?.schemeImage?.data}`}
                  alt={dataFromBackend.topBoxData?.schemeName} />

                <div className="club-text">
                  <h5>{dataFromBackend.topBoxData?.schemeName}</h5>
                  <h5 className='no-text-wrap'>{dataFromBackend.topBoxData?.schemeDuration}</h5>
                </div>
              </div>
            }
            {(tileDetail &&
              "ftsScheme" in tileDetail &&
              "ftsMainScreen" in tileDetail?.ftsScheme &&
              "bottomBoxData" in tileDetail?.ftsScheme?.ftsMainScreen) ?
              <div className="loginCard my-3 d-flex align-items-center justify-content-around py-5">

                <img
                  style={{maxWidth:"8rem",maxHeight:"8rem"}}
                  src={"schemeImage" in tileDetail?.ftsScheme?.ftsMainScreen?.bottomBoxData ?
                    URL.createObjectURL(tileDetail?.ftsScheme?.ftsMainScreen?.bottomBoxData?.schemeImage) :
                    `data:${dataFromBackend.bottomBoxData?.schemeImage?.mimetype};base64,${dataFromBackend.bottomBoxData?.schemeImage?.data}`}
                  alt={tileDetail?.ftsScheme?.ftsMainScreen?.bottomBoxData?.schemeName} />

                <div className="club-text">
                  <h5>{"schemeName" in tileDetail?.ftsScheme?.ftsMainScreen?.bottomBoxData ?
                  tileDetail?.ftsScheme?.ftsMainScreen?.bottomBoxData?.schemeName:
                  dataFromBackend.bottomBoxData?.schemeName}</h5>
                  <h5 className='no-text-wrap'>{"schemeDuration" in tileDetail?.ftsScheme?.ftsMainScreen?.bottomBoxData ?
                  tileDetail?.ftsScheme?.ftsMainScreen?.bottomBoxData?.schemeDuration:
                  dataFromBackend.bottomBoxData?.schemeDuration
                  }</h5>
                </div>
              </div>
              : dataFromBackend && "bottomBoxData" in dataFromBackend &&
              <div className="loginCard my-3 d-flex align-items-center justify-content-around py-5">

                <img
                style={{maxWidth:"8rem",maxHeight:"8rem"}}
                  src={`data:${dataFromBackend.bottomBoxData?.schemeImage?.mimetype};base64,${dataFromBackend.bottomBoxData?.schemeImage?.data}`}
                  alt={dataFromBackend.bottomBoxData?.schemeName} />

                <div className="club-text">
                  <h5>{dataFromBackend.bottomBoxData?.schemeName}</h5>
                  <h5 className='no-text-wrap'>{dataFromBackend.bottomBoxData?.schemeDuration}</h5>
                </div>
              </div>
            }


          </div>
        </div>

      </div>
    </div>
  )
}

export default FtsScheme
