

const TableData = {
    brandList: {
        TableLabel: ["Brand List"],
        SelectedLabel: "Brand List",

        viewApi: {
            "Brand List": {
                port: "https://marina-world-order.onrender.com",
                endPoint: "sendBrandId",
                requestFor: "brands"
            }
        },
        "Brand List": [
            {
                name: "BrandID",
                label: "Sr. No."
            },
            {
                name: "BrandName",
                label: "Brand Name"
            },
            {
                name: "image",
                label: "Brand Logo",
                type: "image",
                key:"imgName"
            }
        ]

    },
    categoryManagement: {
        TableLabel: ["Category List", "Sub-Category List"],
        SelectedLabel: "Category List",
        viewApi: {
            "Category List": {
                port: "https://marina-world-order.onrender.com",
                endPoint: "sendCatId",
                requestFor: "categories"
            },
            "Sub-Category List": {
                port: "https://marina-world-order.onrender.com",
                endPoint: "senSubCatId",
                requestFor: "subcategories"
            }
        },
        "Category List": [
            {
                "name": "CategoryID",
                "label": "Sr. No."
            },
            {
                "name": "CategoryName",
                "label": "Category Name"
            },
            {
                "name": "BrandName",
                "label": "Brand Name"
            }
        ],
        "Sub-Category List": [
            {
                "name": "SubcategoryID",
                "label": "Sr. No."
            },

            {
                "name": "SubcategoryName",
                "label": "Sub-Category Name"
            },
            {
                "name": "CategoryName",
                "label": "Category Name"
            },
        ],

    },
    productList: {
        TableLabel: ["Product List"],
        SelectedLabel: "Product List",
        viewApi: {
            "Product List": {
                port: "https://marina-world-order.onrender.com",
                endPoint: "ProductDetails",
                requestFor: ""
            },
        },
        "Product List": [
            {
                "name": "ProductID",
                "label": "Product ID"
            },
            {
                "name": "ProductName",
                "label": "Product Name"
            },
            {
                "name": "BrandName",
                "label": "Brand Name"
            },
            {
                "name": "CategoryName",
                "label": "Category"
            },
            {
                "name": "SubcategoryName",
                "label": "Sub-Category Name"
            },
            {
                "name": "urbanPrice",
                "label": "Urban Price"
            },
            {
                "name": "ruralPrice",
                "label": "Rural Price"
            },
            {
                "name": "images",
                "label": "Main Image",
                type: "image",
                "index":0,
                "key":"filename"

            },
            {
                "name": "images",
                "label": "2nd Image",
                type: "image",
                "index":1,
                "key":"filename"
            },
            {
                "name": "images",
                "label": "3rd Image",
                type: "image",
                "index":2,
                "key":"filename"
            },
            {
                "name": "images",
                "label": "4th Image",
                type: "image",
                "index":3,
                "key":"filename"
            },
            {
                "name": "images",
                "label": "Thumbnail Image",
                type: "image",
                "index":4,
                "key":"filename"
            },
            {
                "name": "Stock",
                "label": "Stock"
            }
        ],

    },
    dealerList: {
        TableLabel: ["Dealer List"],
        SelectedLabel: "Dealer List",
        viewApi: {
            "Dealer List": {
                port: "https://marina-world-users.onrender.com",
                endPoint: "userDetails",
                requestFor: "allDealers"
            },
        },
        "Dealer List": [
            {
                "name": "UserID",
                "label": "Dealer ID"
            },
            {
                "name": "businessName",
                "label": "Business name"
            },
            {
                "name": "Gstin",
                "label": "GST Number"
            },
            {
                "name": "Address",
                "label": "Address"
            },
            {
                "name": "State",
                "label": "State"
            },
            {
                "name": "ZIPCode",
                "label": "Pin Code"
            },
            {
                "name": "areaType",
                "label": "Area Type"
            },
            {
                "name": "businessLogo",
                "label": "Logo",
                type: "image",
                "key":"filename"
            },
            {
                "name": "Name",
                "label": "Owner Name"
            },
            {
                "name": "PhoneNumber",
                "label": "Contact Number"
            },
            {
                "name": "aleternateNo",
                "label": "Alternate Number"
            },
            {
                "name": "Email",
                "label": "Email"
            }
        ]
    },
    orderManagement: {
        TableLabel: ["Order Management"],
        SelectedLabel: "Order Management",
        threeDot:{
            navigateTo:"/layout/orderSummary",
            id:"OrderID"
        },
        viewApi: {
            "Order Management": {
                port: "https://marina-world-order.onrender.com",
                endPoint: "RecentOrderRecords",
                requestFor: "allusers"
            }
        },
        "Order Management": [
            {
                name: "UserID",
                label: "Retailers"
            },
            {
                name: "OrderID",
                label: "Order ID"
            },
            {
                name: "LastOrderDate",
                label: "Date / Time"
            },
            {
                name: "MethodName",
                label: "Mode"
            },
            {
                name: "InvoiceID",
                label: "Invoice ID"
            },
            {
                name: "OrderStatus",
                label: "Status",
                type: "dropdown",
            }
        ]
    },
    PaymentAndInvoice: {
        TableLabel: ["Order Wise Invoice", "Dealer Wise Invoice"],
        SelectedLabel: "Order Wise Invoice",
        "Order Wise Invoice": [
            {
                name: "retailers",
                label: "Retailers"
            },
            {
                name: "orderId",
                label: "Order ID"
            },
            {
                name: "dateTime",
                label: "Date / Time"
            },
            {
                name: "mode",
                label: "Mode"
            },
            {
                name: "invoiceId",
                label: "Invoice ID"
            },
            {
                name: "view",
                label: "View",
                type: "button"
            }
        ]
    },
    attendanceManagement: {
        TableLabel: ["Attendance Management"],
        SelectedLabel: "Attendance Management",
        viewApi: {
            "Attendance Management": {
                port: "https://marina-world-users.onrender.com",
                endPoint: "viewAttendance",
                requestFor: ""
            },
        },
        "Attendance Management": [
            {
                "name": "executive_id",
                "label": "Executive ID"
            },
            {
                "name": "name",
                "label": "Name"
            },
            {
                "name": "time_in",
                "label": "Time In"
            },
            {
                "name": "time_out",
                "label": "Time Out"
            },
            {
                "name": "location",
                "label": "Location "
            }
        ]
    },
    executiveList: {
        "TableLabel": ["Executive List"],
        "SelectedLabel": "Executive List",
        viewApi: {
            "Executive List": {
                port: "https://marina-world-users.onrender.com",
                endPoint: "executives",
                requestFor: "executives"
            },
        },
        "Executive List": [
            {
                "name": "ExecutiveID",
                "label": "Executive ID"
            },
            {
                "name": "ExecutiveName",
                "label": "Name"
            },
            {
                "name": "fatherName",
                "label": "Father Name"
            },
            {
                "name": "position",
                "label": "Position"
            },
            {
                "name": "dateOFBirth",
                "label": "Date Of Birth"
            },
            {
                "name": "address",
                "label": "Address"
            },
            {
                "name": "city",
                "label": "City"
            },
            {
                "name": "state",
                "label": "State"
            },
            {
                "name": "pincode",
                "label": "Pin Code"
            },
            {
                "name": "Email",
                "label": "Email"
            },
            {
                "name": "PhoneNumber",
                "label": "Contact Number"
            },
            {
                "name": "alternateNo",
                "label": "Alternate Number"
            },
            {
                "name": "Image",
                "label": "Photo",
                type: "image",
                "key":"filename"
            }
        ]
    },
    updateStock: {
        TableLabel: ["Update Stock"],
        SelectedLabel: "Update Stock",

        viewApi: {
            "Update Stock": {
                port: "https://marina-world-order.onrender.com",
                endPoint: "ProductDetails",
                requestFor: ""
            }
        },
        "Update Stock": [
            {
                name: "ProductID",
                label: "Product ID"
            },
            {
                name: "ProductName",
                label: "Product Name"
            },
            {
                name: "Stock",
                label: "Current Stock"
            },
            // {
            //     name: "newStock",
            //     label: "New Stock"
            // },
            
        ]

    },



}
export { TableData }