import React from 'react'
import "./Loading.css"

const Loading = () => {
  return (
    <>
    <div class="screen" style={{zIndex:5000}}>

                    <div class="loader">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>

                </div>
    </>
  )
}

export default Loading
