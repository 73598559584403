import React, { useContext, useState } from 'react'

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Key, MarinaWorld,MarinaWorldLogo,Supporticon, User } from '../../Assets/Images/ExportSvg';

import { useNavigate } from 'react-router-dom'

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { context } from '../Context/Context';

const LoginForm = () => {
    const navigate = useNavigate();
    const [passwordshow, setPasswordShow] = useState(false)
    const [userDetail, setuserdetail] = useState({ userName: "", password: "" })
    const { snackbarMessage, setSnackbarMessage,snackbarOpen, setSnackbarOpen,fcmToken,setAdminName, setIsAuthenticated} = useContext(context);

    const handleInput = (e) => {
        setuserdetail({ ...userDetail, [e.target.name]: e.target.value })
    }
    const handleLogin = (e) => {
        e.preventDefault()
        console.log(fcmToken)
        
        if (!userDetail.userName) {
            console.log("userName")
            setSnackbarMessage("field can't be empty!!")
            setSnackbarOpen(true)
            return;
        }
        else if (!userDetail.password) {
            console.log("password")
            setSnackbarMessage("field can't be empty!!")
            setSnackbarOpen(true)
            return;
        }
        else if(fcmToken===null){
            setSnackbarMessage("Allow Notifications & Reload the Page")
            setSnackbarOpen(true)
            return;
        }
        else if(fcmToken===undefined){
            setSnackbarMessage("Allow Notifications & Reload the Page")
            setSnackbarOpen(true)
            return;
        }
        if (userDetail.userName && userDetail.password && fcmToken!==null) {
            const Url=`https://marina-world-auth.onrender.com/Login?reqFor=admin`
            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Headers
                  },
                body: JSON.stringify(userDetail),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log('Success:', data);
                    if(data.msg==="Logged in Sucessfully"){
                        
                        setSnackbarMessage("Logged in Successfully")
                        setSnackbarOpen(true)
                        setAdminName(userDetail.userName)
                        // navigate("/layout/dashboard")
                        const Url=`https://marina-world-users.onrender.com/registerToken`
                        fetch(Url, {
                            method: 'PATCH',
                            headers: {
                                'Content-Type': 'application/json', // Headers
                              },
                            body: JSON.stringify({token:fcmToken.token, id:data.userid}),
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                console.log('Success:', data);
                                if(data.msg==="token updated sucessfully"){ 
                                    setIsAuthenticated(true)                                   
                                    navigate("/layout/dashboard")
                                    
                                }
                                setSnackbarMessage(data.msg)
                                    setSnackbarOpen(true)
                            })
                            .catch((error) => {
                                console.error('Error:', error);
                                setSnackbarMessage(error)
                                    setSnackbarOpen(true)
                            });
                        
                    }
                    setSnackbarMessage(data.msg)
                        setSnackbarOpen(true)
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setSnackbarMessage(error)
                        setSnackbarOpen(true)
                });
            
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div className='position-relative' style={{ height: "100vh" }}>
            <div className="position-absolute start-50 translate-middle" style={{top:"10%"}}>
                <div className='text-center my-0'>
                    <img src={MarinaWorldLogo} alt="MarinaWorldLogo" style={{marginBottom:"-15px"}} width={"70rem"}  height={"70rem"}/></div>
                <div className="text-center my-0">
                <img src={MarinaWorld}/>
                </div>
                </div>
            <div className=" position-absolute start-50 translate-middle card loginCard rounded-5 login-form-font py-3 mt-2" style={{ width: "25rem",top:"50%" }}>
                <div className="card-body">
                    <form onSubmit={handleLogin}>
                    
                    <div className="text-center Login-heading fs-2">Login</div>
                    <div className="row my-3">
                        <div className="col-12">
                            <label>
                                UserName
                            </label>
                        </div>
                        <div className="col-12 position-relative">
                            <img src={User} 
                            alt="user" 
                            width={"22rem"}
                            className='position-absolute top-50 start-0 translate-middle-y ms-3' />
                            <input name="userName"
                            autocomplete="off"
                                type='text'
                                value={userDetail.userName}
                                autoComplete="off"
                                onChange={handleInput}
                                className='form-control w-100'                                
                                style={{boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",paddingLeft:"2rem"}}
                                placeholder='shalin singh' />
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-12">
                            <label>
                                Password
                            </label>
                        </div>
                        <div className="col-12 position-relative">
                        <img src={Key} 
                            alt="key" 
                            width={"22rem"}
                            className='position-absolute top-50 start-0 translate-middle-y ms-3' />
                            <input name="password"
                            autocomplete="off"
                                type={passwordshow ? "text" : "password"}
                                value={userDetail.password}
                                style={{boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",paddingLeft:"2rem"}}
                                onChange={handleInput}
                                placeholder='********'
                                className='form-control w-100' />
                                <div
                                style={{ cursor: "pointer" }}
                                onClick={() => setPasswordShow(!passwordshow)}
                                className='position-absolute top-50 end-0 translate-middle-y me-4'>
                                    {passwordshow?<VisibilityIcon/>:<VisibilityOffIcon/>}
                                </div>


                        </div>
                        <div className="col-12 text-end">
                            {/* <label style={{ cursor: "pointer",fontSize:".8rem",fontWeight:"500"}} onClick={()=>{navigate("/forgetPassword")}} >
                                Forget Password?
                            </label> */}
                        </div>
                    </div>
                    <div className="row my-4">

                        <div className="col-12" >
                            <button className="w-100 py-1 button" onClick={handleLogin}>
                                Login
                            </button>
                        </div>
                    </div>

                </form>
                </div>
            </div>
            <div className="position-absolute start-50 bottom-0 mb-1 translate-middle-x " onClick={()=>navigate("/support")}>
                <div className="my-0 d-flex justify-content-center align-items-center"><img src={Supporticon} alt="support" className='mx-1' width={20} /> <label htmlFor="Support" className='login-form-font'>Support</label></div>
                <div className="my-0">Developed By UnaireSync</div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical:"bottom",horizontal:"right"}}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                message={snackbarMessage}
                action={action}
            />

        </div>
    )
}

export default LoginForm
