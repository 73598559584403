import React from 'react'
import Form from '../Forms/Form'
import PageHeader from './PageHeader'

const AddExecutive = React.memo(() => {
  console.log("add executive")
  return (
    <>
    <PageHeader back={true}/>
    <div className="w-75 mx-auto">
    <Form formName={"executiveAdd"} borderless={true} />
    </div>
    </>
  )
})

export default AddExecutive
