import './App.css';
import ForgetPasswordForm from './Components/Login/ForgetPasswordForm';
import LoginForm from "./Components/Login/LoginForm";
import Layout from "./Components/Layout/Layout";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import DashboardPage from './Components/Dashboard/DashboardPage';
import BrandManagement from './Components/Pages/BrandManagement';
import CategoryManagement from './Components/Pages/CategoryManagement';
import ProductManagment from './Components/Pages/ProductManagment';
import AddDealer from './Components/Pages/AddDealer';
import DealerManagement from './Components/Pages/DealerManagement';
import OrderManagement from './Components/Pages/OrderManagement';
import PaymentAndInvoice from './Components/Pages/PaymentAndInvoice';
import TileManagement from './Components/Pages/TileManagement.jsx/TileManagement';
import AddExecutive from './Components/Pages/AddExecutive';
import AttendanceManagement from './Components/Pages/AttendanceManagement';
import ExecutiveManagement from './Components/Pages/ExecutiveManagement';
import ExecutiveTaskManagement from './Components/Pages/ExecutiveTaskManagement/ExecutiveTaskManagement';
import ExecutiveAnalysis from './Components/Pages/ExecutiveAnalysis/ExecutiveAnalysis';
import UploadInvoice from './Components/Pages/UploadInvoice/UploadInvoice';
import StockManager from './Components/Pages/StockManager';
import WeekCalendar from './Components/Calendar/WeekCalendar';
import MainSummary from './Components/Pages/OrderManagementSummary/MainSummary';
import MainSetting from './Components/Settings/MainSetting';
import { useContext } from 'react';
import { context } from './Components/Context/Context';
import SupportPage from './Components/Pages/SupportPage';

// import ProtectedRoute from './Components/Routes/ProtectedRoute';


const ProtectedRoute = ({ isAuthenticated, children }) => {
    return isAuthenticated ? children : <Navigate to="/" />;
};

function AllRoutes() {
  const {isAuthenticated } = useContext(context);
   return (
    <>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LoginForm />} />
          <Route path="/support" element={<SupportPage/>} />

          {/* Protected Routes */}
          <Route
            path="/layout"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route path="dashboard" index element={<DashboardPage />} />
            <Route path="brandManagement" element={<BrandManagement />} />
            <Route path="CategoryManagement" element={<CategoryManagement />} />
            <Route path="productManagement" element={<ProductManagment />} />
            <Route path="dealerManagement" element={<DealerManagement />} />
            <Route path="addDealer" element={<AddDealer />} />
            <Route path="addExecutive" element={<AddExecutive />} />
            <Route path="attendanceManagement" element={<AttendanceManagement />} />
            <Route path="executiveManagement" element={<ExecutiveManagement />} />
            <Route path="orderManagement" element={<OrderManagement />} />
            <Route path="paymentAndInvoice" element={<PaymentAndInvoice />} />
            <Route path="tileManagement" element={<TileManagement />} />
            <Route path="executiveTaskManagement" element={<ExecutiveTaskManagement />} />
            <Route path="executiveAnalysis" element={<ExecutiveAnalysis />} />
            <Route path="uploadInvoice" element={<UploadInvoice />} />
            <Route path="stockManage" element={<StockManager />} />
            <Route path="orderSummary" element={<MainSummary />} />
            <Route path="AddEvents" element={<WeekCalendar />} />
            <Route path='Setting' element={<MainSetting/>}/> 
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default AllRoutes;
