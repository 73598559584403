import React, { useState } from 'react'
import {Return,TotalOrder,Delivery,Dispatch,Credit,CashRecieved,ChequeRecieved,PaymentRecieved, Completed} from "../../Assets/Images/ExportSvg"

const HorizontalCards = ({data}) => {
    const [icons,setIcons] = useState({
        "Total Orders ": TotalOrder,
        "Dispatched": Dispatch,
        "Ongoing": Delivery,
        "Rejected": Return,
        "Total Payment Recieve ": PaymentRecieved,
        "Cash Recieve": CashRecieved,
        "Cheque Recieved": ChequeRecieved,
        "Credit": Credit,
        "Completed":Completed
    })

  return (
    <div className="row my-3 align-items-stretch" key={data}>
                {Object.keys(data).map((itemName, index) => (
                    <div key={index} className="col-lg-3 col-6 py-1 px-2 d-flex justify-content-center  align-items-stretch">
                        <div className="card whiteCard w-100" style={{maxWidth:"20rem"}} >
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">

                                <label  style={{fontSize:"1rem"}}>
                                    {itemName}
                                </label>
                                <img src={icons[itemName]} alt={itemName} width="26rem" />
                                </div>
                                <label className="d-block fw-bold" style={{fontSize:"1rem"}}>
                                    {data[itemName]}
                                </label>
                            </div>
                        </div>
                    </div>

                ))}
            </div>
  )
}

export default HorizontalCards
