import React, { useContext, useEffect, useState } from "react";
import { Truck } from "../../Assets/Images/ExportSvg";
import { context } from "../Context/Context";

const TruckStatus = () => {
    const {} = useContext(context);
  const [tructStatus, setTruckStatus] = useState({});
  useEffect(() => {
   
    const endPoint="fetchOrderVehicleWise"
    const Url = `https://marina-world-order.onrender.com/${endPoint}`
    const fetchData = async () => {
        try {
            const response = await fetch(Url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            console.log(result)
            if(result.msg==="no orders found"){
              setTruckStatus({})
            }
            else if(result.msg==="data found"){
              function transformData(data) {
                const result = {};              
                data.forEach(item => {
                  const vehicleKey = item.vehicleNo.toUpperCase();
                  if (!result[vehicleKey]) {
                    result[vehicleKey] = [];
                  }
                  result[vehicleKey].push(item.OrderID);
                });
                return result;
              }
              const temp=transformData(result.data)
              setTruckStatus(temp);
                
            }
        } catch (err) {
            console.log(err.message);
        }
    };
    
        fetchData();
    

}, []);

 
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ fontSize: "1rem" }}
      >
        Truck Status
      </div>
      {Object.keys(tructStatus).length===0?<label className="my-2">No Orders Found</label>:<>
     
      {Object.keys(tructStatus).map((vechileNo, index) => (
        <>
          <div className="d-flex align-items-center justify-content-between my-2 border-bottom py-3">
            <div className="d-flex align-items-center justify-content-center">
              <img src={Truck} alt="" width={"20rem"} />
              <label className="mx-1" style={{ whiteSpace: "nowrap" }}>
                {vechileNo}
              </label>
            </div>
            <div className="d-flex align-items-start justify-content-center">
              <label className="mx-1" style={{ whiteSpace: "nowrap" }}>
                Order ID:
              </label>
              <div className="d-flex flex-wrap " style={{ maxWidth: "6rem" }}>
                {tructStatus[vechileNo].map((order, ind) => (
                  <>{ind <= 6 ? <label key={ind}>{order}{6===ind||tructStatus[vechileNo].length-1===ind?"":","}</label> :
                  ind===7?" ...":null}</>
                ))}
              </div>
            </div>
          </div>
        </>
      ))}
       </>}
    </>
  );
};

export default TruckStatus;
