import React, { useContext, useEffect, useRef, useState } from 'react'
import { Add, Upload, DeleteBin, AddWithoutBorder } from '../../../Assets/Images/ExportSvg'
import { context } from '../../Context/Context';

const TileManagementForm = ({ selectedPage, selectedTab, setTileData, tileData, tileDetail, setTileDetail, dataFromBackend }) => {
    const { snackbarMessage, setSnackbarMessage,snackbarOpen, setSnackbarOpen } = useContext(context);

    const seletedPage1 = selectedPage === "Home Page" ? "homePage" : selectedPage === "Shop Page" ? "shopPage" : "ftsScheme"

    const labels = {
        mainQuote: "Main Quote",
        topBoxData: "Top Box Data",
        bottomBoxData: "Bottom Box Data",
        firstBoxData: "First Box Data",
        secondBoxData: "Second Box Data",
        thirdBoxData: "Third Box Data",
        termsAndConditionsBoxData: "Terms & Condition Box Data"
    }
    const [searchObject, setSearchObject] = useState({

    })
    const [filterSearchObject, setFilterSearchObject] = useState({

    })

    useEffect(() => {

        let name = ""
        let endPoint = ""
        let reqFor = ""
        let port = ""
        if (selectedTab === "achieverSlider") {
            name = "dealer"
            endPoint = "userDetails"
            reqFor = "ftsApp"
            port = "https://marina-world-users.onrender.com"

        }
        else if (selectedTab === "employeeSlider") {
            name = "executive"
            endPoint = "executives"
            reqFor = "executives"
            port = "https://marina-world-users.onrender.com"
        }
        else if (selectedTab === "newLaunchedProduct" || selectedTab === "popularProduct") {
            name = "product"
            endPoint = "ProductDetails"
            reqFor = ""
            port = "https://marina-world-order.onrender.com"
        }

        if (name !== "" && endPoint !== "" && port !== "") {

            
            const Url = `${port}/${endPoint}?reqFor=${reqFor}`
            fetch(Url, {
                method: 'GET',  // Specify that this is a POST request
                headers: {
                    'Content-Type': 'application/json',  // Send JSON data
                },
                // Convert the JavaScript object to JSON string
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log('Success:', data.data);
                    if (name === "executive") {
                        const executive = data.data.filter((item) => item.role === "executive")
                        setSearchObject({ ...searchObject, [name]: executive })
                        setFilterSearchObject({ ...filterSearchObject, [name]: executive })
                        
                    }
                    else {
                        setSearchObject({ ...searchObject, [name]: data.data })
                        setFilterSearchObject({ ...filterSearchObject, [name]: data.data })
                        
                    }

                    
                })
                .catch((error) => {
                    console.error('Error:', error);
                    //   setMessage('Failed to post data.');
                });
        }



    }, [selectedTab])




    const data = {
        homePage: {
            mainSlider: [{}, {}, {}],
            achieverSlider: [
                {
                    "name": "firstPosition",
                    "type": "searchableText",
                    "label": "first Position",
                    "placeholder": "Search Dealer",
                    "searchKey": "dealer",
                    "id": "UserID",
                    "id2": "Name"
                },
                {
                    "name": "secondPosition",
                    "type": "searchableText",
                    "label": "Second Position",
                    "placeholder": "Search Dealer",
                    "searchKey": "dealer",
                    "id": "UserID",
                    "id2": "Name"
                },
                {
                    "name": "thirdPosition",
                    "type": "searchableText",
                    "label": "Third Position",
                    "placeholder": "Search Dealer",
                    "searchKey": "dealer",
                    "id": "UserID",
                    "id2": "Name"
                },
            ],
            employeeSlider: [
                {
                    "name": "firstPosition",
                    "type": "searchableText",
                    "label": "first Position",
                    "placeholder": "Search Executive",
                    "searchKey": "executive",
                    "id": "ExecutiveID",
                    "id2": "ExecutiveName"
                },
                {
                    "name": "secondPosition",
                    "type": "searchableText",
                    "label": "Second Position",
                    "placeholder": "Search Executive",
                    "searchKey": "executive",
                    "id": "ExecutiveID",
                    "id2": "ExecutiveName"

                },
                {
                    "name": "thirdPosition",
                    "type": "searchableText",
                    "label": "Third Position",
                    "placeholder": "Search Executive",
                    "searchKey": "executive",
                    "id": "ExecutiveID",
                    "id2": "ExecutiveName"

                },
            ],
            newLaunchedProduct: [
                {
                    "name": "product",
                    "type": "searchableText",
                    "label": "Select Product",
                    "placeholder": "Search Product",
                    "searchKey": "product",
                    "id": "ProductID",
                    "id2": "ProductName"

                },
            ],
            ftsScheme: [
                {
                    "name": "image",
                    "type": "Upload",
                    "label": "Select FTS Image",
                    "placeholder": "Upload Image"

                },

            ]
        },
        shopPage: {
            popularProduct: [
                {
                    "name": "product1",
                    "type": "searchableText",
                    "label": "Product Select",
                    "placeholder": "Search Product",
                    "searchKey": "product",
                    "id": "ProductID",
                    "id2": "ProductName"
                },
                {
                    "name": "product2",
                    "type": "searchableText",
                    "label": "Product Select",
                    "placeholder": "Search Product",
                    "searchKey": "product",
                    "id": "ProductID",
                    "id2": "ProductName"
                },
                {
                    "name": "product3",
                    "type": "searchableText",
                    "label": "Product Select",
                    "placeholder": "Search Product",
                    "searchKey": "product",
                    "id": "ProductID",
                    "id2": "ProductName"
                },
                {
                    "name": "product4",
                    "type": "searchableText",
                    "label": "Product Select",
                    "placeholder": "Search Product",
                    "searchKey": "product",
                    "id": "ProductID",
                    "id2": "ProductName"
                },
                {
                    "name": "product5",
                    "type": "searchableText",
                    "label": "Product Select",
                    "placeholder": "Search Product",
                    "searchKey": "product",
                    "id": "ProductID",
                    "id2": "ProductName"
                },
                {
                    "name": "product6",
                    "type": "searchableText",
                    "label": "Product Select",
                    "placeholder": "Search Product",
                    "searchKey": "product",
                    "id": "ProductID",
                    "id2": "ProductName"
                },
            ]
        },
        ftsScheme: {
            ftsMainScreen: {
                mainQuote: [
                    {
                        "name": "mainQuote",
                        "type": "text-Box",
                        "label": "main Quote",
                        "placeholder": "Description"
                    }
                ],
                topBoxData: [

                    {
                        "name": "schemeName",
                        "type": "text",
                        "label": "Scheme Name",
                        "placeholder": "Scheme Name"
                    },
                    {
                        "name": "schemeDuration",
                        "type": "text",
                        "label": "Scheme Duration",
                        "placeholder": "Scheme Duration"
                    },
                    {
                        "name": "schemeImage",
                        "type": "Upload",
                        "label": "Scheme Photo",
                        "placeholder": "Upload Image"
                    }
                ],
                bottomBoxData: [

                    {
                        "name": "schemeName",
                        "type": "text",
                        "label": "Scheme Name",
                        "placeholder": "Scheme Name"
                    },
                    {
                        "name": "schemeDuration",
                        "type": "text",
                        "label": "Scheme Duration",
                        "placeholder": "Scheme Duration"
                    },
                    {
                        "name": "schemeImage",
                        "type": "Upload",
                        "label": "Scheme Photo",
                        "placeholder": "Upload Image"
                    },

                ]
            },
            ftsTopBoxPage: {
                firstBoxData: [

                    {
                        "name": "typeName",
                        "type": "text",
                        "label": "Type Name",
                        "placeholder": "Type Name"
                    },
                    {
                        "name": "purchaseAmount",
                        "type": "text",
                        "label": "Purchase Amount",
                        "placeholder": "Purchase Amount"
                    },
                    {
                        "name": "numberOfPerson",
                        "type": "text",
                        "label": "Number Of Person",
                        "placeholder": "Number Of Person"
                    },
                    {
                        "name": "destinationPhoto",
                        "type": "Upload",
                        "label": "Destination Photo",
                        "placeholder": "Upload Image"
                    },
                    {
                        "name": "quote",
                        "type": "text-Box",
                        "label": "Main Quote",
                        "placeholder": "Description"
                    },
                ],
                secondBoxData: [

                    {
                        "name": "typeName",
                        "type": "text",
                        "label": "Type Name",
                        "placeholder": "Type Name"
                    },
                    {
                        "name": "purchaseAmount",
                        "type": "text",
                        "label": "Purchase Amount",
                        "placeholder": "Purchase Amount"
                    },
                    {
                        "name": "numberOfPerson",
                        "type": "text",
                        "label": "Number Of Person",
                        "placeholder": "Number Of Person"
                    },
                    {
                        "name": "destinationPhoto",
                        "type": "Upload",
                        "label": "Destination Photo",
                        "placeholder": "Upload Image"
                    },
                    {
                        "name": "quote",
                        "type": "text-Box",
                        "label": "Main Quote",
                        "placeholder": "Description"
                    },
                ],
                thirdBoxData: [

                    {
                        "name": "typeName",
                        "type": "text",
                        "label": "Type Name",
                        "placeholder": "Type Name"
                    },
                    {
                        "name": "purchaseAmount",
                        "type": "text",
                        "label": "Purchase Amount",
                        "placeholder": "Purchase Amount"
                    },
                    {
                        "name": "numberOfPerson",
                        "type": "text",
                        "label": "Number Of Person",
                        "placeholder": "Number Of Person"
                    },
                    {
                        "name": "destinationPhoto",
                        "type": "Upload",
                        "label": "Destination Photo",
                        "placeholder": "Upload Image"
                    },
                    {
                        "name": "quote",
                        "type": "text-Box",
                        "label": "Main Quote",
                        "placeholder": "Description"
                    },
                ],
                termsAndConditionsBoxData: [
                    {
                        "name": "termAndCondition",
                        "type": "text-Box",
                        "label": "terms & Condition",
                        "placeholder": "Description"
                    }
                ]
            },
            ftsBottomBoxPage: {
                firstBoxData: [

                    {
                        "name": "typeName",
                        "type": "text",
                        "label": "Type Name",
                        "placeholder": "Type Name"
                    },
                    {
                        "name": "purchaseAmount",
                        "type": "text",
                        "label": "Purchase Amount",
                        "placeholder": "Purchase Amount"
                    },
                    {
                        "name": "numberOfPerson",
                        "type": "text",
                        "label": "Number Of Person",
                        "placeholder": "Number Of Person"
                    },
                    {
                        "name": "destinationPhoto",
                        "type": "Upload",
                        "label": "Destination Photo",
                        "placeholder": "Upload Image"
                    },
                    {
                        "name": "quote",
                        "type": "text-Box",
                        "label": "Main Quote",
                        "placeholder": "Description"
                    },
                ],
                secondBoxData: [

                    {
                        "name": "typeName",
                        "type": "text",
                        "label": "Type Name",
                        "placeholder": "Type Name"
                    },
                    {
                        "name": "purchaseAmount",
                        "type": "text",
                        "label": "Purchase Amount",
                        "placeholder": "Purchase Amount"
                    },
                    {
                        "name": "numberOfPerson",
                        "type": "text",
                        "label": "Number Of Person",
                        "placeholder": "Number Of Person"
                    },
                    {
                        "name": "destinationPhoto",
                        "type": "Upload",
                        "label": "Destination Photo",
                        "placeholder": "Upload Image"
                    },
                    {
                        "name": "quote",
                        "type": "text-Box",
                        "label": "Main Quote",
                        "placeholder": "Description"
                    },
                ],
                thirdBoxData: [

                    {
                        "name": "typeName",
                        "type": "text",
                        "label": "Type Name",
                        "placeholder": "Type Name"
                    },
                    {
                        "name": "purchaseAmount",
                        "type": "text",
                        "label": "Purchase Amount",
                        "placeholder": "Purchase Amount"
                    },
                    {
                        "name": "numberOfPerson",
                        "type": "text",
                        "label": "Number Of Person",
                        "placeholder": "Number Of Person"
                    },
                    {
                        "name": "destinationPhoto",
                        "type": "Upload",
                        "label": "Destination Photo",
                        "placeholder": "Upload Image"
                    },
                    {
                        "name": "quote",
                        "type": "text-Box",
                        "label": "Main Quote",
                        "placeholder": "Description"
                    },
                ],
                termsAndConditionsBoxData: [
                    {
                        "name": "termAndCondition",
                        "type": "text-Box",
                        "label": "terms & Condition",
                        "placeholder": "Description"
                    }
                ]
            }
        }
    }

    const inputRef = useRef(null)
    const inputRef1 = useRef([])
    const inputRef2 = useRef([])

    const [imageArray, setImageArray] = useState(dataFromBackend?.mainSlider !== undefined ? dataFromBackend?.mainSlider : [{}, {}, {}])
    useEffect(() => {
        setImageArray(dataFromBackend?.mainSlider !== undefined ? dataFromBackend?.mainSlider : [{}, {}, {}])
    }, [dataFromBackend])

    const handleFileChange = (page, tab, fieldName) => {
        if (inputRef.current.files.length > 0) {
            const file = inputRef.current.files[0]
            console.log(file, "file1111")
            let formData1 = new FormData();
            formData1.append('upload', file);
            console.log(formData1, "ppppp")
            let obj = { ...tileData }
            if (obj[page] === undefined) {
                obj[page] = { [tab]: { [fieldName]: file } }
            }
            else {
                if (obj[page][tab] === undefined) {
                    obj[page][tab] = { [fieldName]: file }
                }
                else {
                    obj[page][tab] = { ...obj[page][tab], [fieldName]: file }
                }
            }
            setTileData(obj)

        }
    }
    const handleFileChange1 = (page, tab, section, fieldName, index) => {
        // console.log(inputRef1)
        if (inputRef1.current[index].files.length > 0) {
            const file = inputRef1.current[index].files[0]
            console.log(file, "file2222")
            let obj = { ...tileData }
            if (obj[page] === undefined) {
                obj[page] = { [tab]: { [section]: { [fieldName]: file } } }
            }
            else {
                if (obj[page][tab] === undefined) {
                    obj[page][tab] = { [section]: { [fieldName]: file } }
                }
                else {
                    if (obj[page][tab][section] === undefined) {
                        obj[page][tab][section] = { [fieldName]: file }
                    }
                    else {
                        obj[page][tab][section] = { ...obj[page][tab][section], [fieldName]: file }
                    }
                }
            }
            setTileData(obj)
        }
    }
    const handleFileChange2 = (index) => {
        if (inputRef2.current[index].files.length > 0) {
            const file = inputRef2.current[index].files[0]
            console.log(file)
            let a = [...imageArray]
            a[index] = file
            setImageArray(a)
            console.log(a)
            let obj = { ...tileData }
            if (obj.homePage === undefined) {
                obj.homePage = { "mainSlider": a }
            }
            else {
                if (obj.homePage.mainSlider === undefined) {
                    obj.homePage.mainSlider = a
                }
                else {
                    obj.homePage.mainSlider = a
                }
            }
            setTileData(obj)

        }
    }

    const handleChange = (page, tab, fieldName, fieldValue) => {
        console.log(page, tab, fieldName, fieldValue)
        let obj = { ...tileData }
        if (obj[page] === undefined) {
            obj[page] = { [tab]: { [fieldName]: fieldValue } }
        }
        else {
            if (obj[page][tab] === undefined) {
                obj[page][tab] = { [fieldName]: fieldValue }
            }
            else {
                obj[page][tab] = { ...obj[page][tab], [fieldName]: fieldValue }
            }
        }
        setTileData(obj)
    };
    console.log(tileData, "tileData");

    const handleChange1 = (page, tab, section, fieldName, fieldValue) => {
        console.log(page, tab, section, fieldName, fieldValue);

        let obj = { ...tileData }
        if (obj[page] === undefined) {
            obj[page] = { [tab]: { [section]: { [fieldName]: fieldValue } } }
        }
        else {
            if (obj[page][tab] === undefined) {
                obj[page][tab] = { [section]: { [fieldName]: fieldValue } }
            }
            else {
                if (obj[page][tab][section] === undefined) {
                    obj[page][tab][section] = { [fieldName]: fieldValue }
                }
                else {
                    obj[page][tab][section] = { ...obj[page][tab][section], [fieldName]: fieldValue }
                }
            }
        }
        setTileData(obj)
    };

    const selectedData = (data[seletedPage1][selectedTab])

    const addImage = () => {
        if (imageArray.length < 5) {
            let a = [...imageArray]
            a.push({})
            setImageArray(a)
        }
    }
    const handleDelete = async(index) => {
        let arr = [...imageArray]
        arr.splice(index, 1)
        setImageArray(arr)
        // let obj = { ...tileData }
        // obj.homePage.mainSlider = arr
        // setTileData(obj)



        const Url=`https://marina-world-tiles.onrender.com/deleteMainSliderImage?index=${index}`
            try {
              const response = await fetch(Url, {
                method: 'DELETE', // Use the DELETE method
                headers: {
                  'Content-Type': 'application/json', // Optional, depending on the API
                },
              });
          
              // Check if the request was successful
              if (response.ok) {
                console.log(`Item deleted successfully.`);
                return await response.json(); // Optional: API may return a response body
              } else {
                console.error(`Failed to delete item  Status: ${response.status}`);
              }
            } catch (error) {
              console.error('Error:', error);
            }
          

    }

    const handleSave = () => {
        let obj = { ...tileDetail }
        if(selectedTab==="achieverSlider" ||selectedTab==="employeeSlider" && seletedPage1==="homePage"){
            console.log(tileData)
            if(Object.keys(tileData[seletedPage1][selectedTab]).length<3){
                setSnackbarMessage("Fields Can't Be Empty")
                setSnackbarOpen(true)
                return;
            }
        }
        if(selectedTab==="popularProduct"  && seletedPage1==="shopPage"){
            if(Object.keys(tileData[seletedPage1][selectedTab]).length<2){
                setSnackbarMessage("Fields Can't Be Empty")
                setSnackbarOpen(true)
                console.log("Fields Can't Be Empty")
                return;
            }
        }
        console.log(selectedTab, seletedPage1, obj)
        if (obj[seletedPage1] === undefined) {
            if (selectedTab === "mainSlider") {
                obj[seletedPage1] = { [selectedTab]: [...tileData[seletedPage1][selectedTab]] }
            }
            else {
                obj[seletedPage1] = { [selectedTab]: { ...tileData[seletedPage1][selectedTab] } }
            }
        }
        else {
            if (obj[seletedPage1][selectedTab] === undefined) {
                if (selectedTab === "mainSlider") {
                    obj[seletedPage1][selectedTab] = [...tileData[seletedPage1][selectedTab]]
                }
                else {
                    obj[seletedPage1][selectedTab] = { ...tileData[seletedPage1][selectedTab] }
                }
            }
            else {
                if (selectedTab === "mainSlider") {
                    obj[seletedPage1][selectedTab] = [...tileData[seletedPage1][selectedTab]]
                }
                else {
                    obj[seletedPage1][selectedTab] = { ...tileData[seletedPage1][selectedTab] }
                }
            }
        }
        console.log(obj)
        setTileDetail(obj)

    }

    const handleCancel=()=>{
        let obj = { ...tileDetail }
        delete obj[seletedPage1][selectedTab];
        if(Object.keys(obj[seletedPage1]).length===0){
            delete obj[seletedPage1];  
        }
        setTileDetail(obj)
    }



    const [showList, setShowList] = useState([])
    const filterList = (value, key, id, name) => {
        if (value) {
            const filteredItem = searchObject?.[key].filter(
                (option) =>
                    option[id] == (value) ||
                    option[name].toLowerCase().includes(value.toLowerCase())
            );
            if (filteredItem.length === 1) {
                setShowList(false)
            }
            let temp = { ...filterSearchObject }
            temp[key] = filteredItem
            setFilterSearchObject(temp)
        }

    }

    const handleShowListTrue = (index) => {
        // setFilterSearchObject(searchObject)
        let arr = [...showList]
        arr[index] = true
        setShowList(arr)
    }
    const handleShowListFalse = (index) => {
        setFilterSearchObject(searchObject)
        let arr = [...showList]
        arr[index] = false
        setShowList(arr)
    }



    return (
        <>
            <div className="mx-5" key={selectedTab || selectedPage}>

                {seletedPage1 === "ftsScheme" ? <>{
                    Object.keys(selectedData).map((section, index) => (
                        <>  {
                            section !== "mainQuote" ?
                                <div className="text-center my-3">
                                    <label htmlFor="section" className="fw-bold">{labels[section]}</label>
                                </div> : null
                        }
                            {
                                selectedData[section].map((field, ind) => (
                                    <div className="row my-2 align-items-center" key={index}>
                                        <div className="col-6">
                                            <label>{field.label}</label>
                                        </div>
                                        <div className="col-6">
                                            {field.type === "Upload" ? <>
                                                <div className="position-relative">
                                                    <input
                                                        autocomplete="off"
                                                        type="text"
                                                        name={field.name}
                                                        value={tileData?.[seletedPage1]?.[selectedTab]?.[section]?.[field.name]?.name || ""}
                                                        placeholder={field.placeholder}
                                                        onClick={() => inputRef1.current[ind + index].click()}
                                                        className="form-control w-100 box-shadow"
                                                    />

                                                    <img src={Upload}
                                                        alt='upload'
                                                        style={{ cursor: 'pointer' }}
                                                        width={25}
                                                        onClick={() => inputRef1.current[ind + index].click()}
                                                        className="position-absolute top-50 end-0 translate-middle-y me-2 uploadButton"
                                                    />
                                                </div>

                                                <input
                                                    autocomplete="off"
                                                    type="file"
                                                    accept="image/*"
                                                    name={field.name}
                                                    ref={el => inputRef1.current[index + ind] = el}
                                                    onChange={() => handleFileChange1(seletedPage1, selectedTab, section, field.name, ind + index)}
                                                    style={{ display: 'none' }}
                                                />
                                            </> : field.type === "text-Box" ? <>
                                                <textarea
                                                    class="form-control box-shadow"
                                                    name={field.name}
                                                    // value={tileData?.[seletedPage1]?.[selectedTab]?.[section]?.[field.name] || ""}
                                                    // onChange={(e) => handleChange1(seletedPage1, selectedTab, section, field.name, e.target.value)}
                                                    value={field.name === "termAndCondition" ? tileData?.[seletedPage1]?.[selectedTab]?.[section]?.[field.name]?.join("\n") || ""
                                                        : tileData?.[seletedPage1]?.[selectedTab]?.[section]?.[field.name] || ""
                                                    }
                                                    onChange={(e) => {
                                                        // Split the input into lines and save as an array
                                                        const listData = e.target.value.split("\n");
                                                        field.name === "termAndCondition" ?
                                                            handleChange1(seletedPage1, selectedTab, section, field.name, listData) :
                                                            handleChange1(seletedPage1, selectedTab, section, field.name, e.target.value)
                                                    }}
                                                    placeholder={field.placeholder} rows="3" />
                                            </> : <>
                                                <input
                                                    autocomplete="off"
                                                    className='w-100 form-control box-shadow'
                                                    name={field.name}
                                                    value={tileData?.[seletedPage1]?.[selectedTab]?.[section]?.[field.name] || ""}
                                                    onChange={(e) => handleChange1(seletedPage1, selectedTab, section, field.name, e.target.value)}
                                                    placeholder={field.placeholder} />
                                            </>}
                                        </div>
                                    </div>
                                ))

                            }
                        </>

                    ))

                }</> :

                    (seletedPage1 === "homePage" && selectedTab === "mainSlider") ? <>
                        <div className="row my-2 align-items-center">
                            <div className="col-6 text-center">Current Images</div>
                            <div className="col-6 text-center">New Images</div>
                        </div>


                        {imageArray.map((field, index) => (
                            <div className="row my-2 align-items-center">
                                <div className="col-6">
                                    {dataFromBackend && "mainSlider" in dataFromBackend &&
                                        <input
                                            autocomplete="off"
                                            className='w-100 form-control box-shadow'
                                            value={dataFromBackend?.mainSlider[index]?.fileName || ""}
                                        />}
                                </div>
                                <div className="col-6 d-flex align-items-center">
                                    <div className="position-relative">
                                        <input
                                            autocomplete="off"
                                            type="text"
                                            name={field.name}
                                            value={tileData?.[seletedPage1]?.[selectedTab]?.[index]?.name || ""}
                                            placeholder={"Upload Image"}
                                            onClick={() => inputRef2.current[index].click()}
                                            className="form-control w-100 box-shadow"
                                        />

                                        <img src={Upload}
                                            alt='upload'
                                            style={{ cursor: 'pointer', background: "#222831" }}
                                            width={25}
                                            onClick={() => inputRef2.current[index].click()}
                                            className="position-absolute top-50 end-0 translate-middle-y me-2 uploadButton"
                                        />
                                    </div>
                                    {imageArray.length > 3 ?
                                        <img src={DeleteBin} alt="delete" width={22} className='mx-1' onClick={() => handleDelete(index)} /> : null
                                    }

                                    <input
                                        autocomplete="off"
                                        type="file"
                                        accept="image/*"
                                        name={field.name}
                                        ref={el => inputRef2.current[index] = el}
                                        onChange={() => { handleFileChange2(index) }}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </div>
                        ))}

                        <div className="row my-2 align-items-center">
                            <div className="offset-6 col-6 text-center">
                                <img src={AddWithoutBorder} alt="add" width={28} className='box-shadow p-1' style={{ borderRadius: "50%" }} onClick={addImage} />
                            </div>
                        </div>
                    </> :
                        selectedData.map((field, index) => (
                            <div className="row my-2 align-items-center">
                                <div className="col-6">
                                    <label>{field.label}</label>
                                </div>
                                <div className="col-6">
                                    {field.type === "Upload" ? <>
                                        <div className="position-relative">
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                name={field.name}
                                                value={tileData?.[seletedPage1]?.[selectedTab]?.[field.name]?.name}
                                                placeholder={field.placeholder}
                                                onClick={() => inputRef.current.click()}
                                                className="form-control w-100 box-shadow"
                                            />

                                            <img src={Upload}
                                                alt='upload'
                                                style={{ cursor: 'pointer' }}
                                                width={25}
                                                onClick={() => inputRef.current.click()}
                                                className="position-absolute top-50 end-0 translate-middle-y me-2 uploadButton"
                                            />
                                        </div>

                                        <input
                                            autocomplete="off"
                                            type="file"
                                            accept="image/*"
                                            name={field.name}
                                            ref={inputRef}
                                            onChange={() => { handleFileChange(seletedPage1, selectedTab, field.name) }}
                                            style={{ display: 'none' }}
                                        />
                                    </> : field.type === "text-Box" ? <>
                                        <textarea class="form-control box-shadow"
                                            name={field.name}
                                            value={tileData?.[seletedPage1]?.[selectedTab]?.[field.name] || ""}
                                            onChange={handleChange}
                                            placeholder={field.placeholder}
                                            rows="3" />
                                    </> :
                                        field.type === "searchableText" ?
                                            <>
                                                <input
                                                    autocomplete="off"
                                                    className='w-100 form-control box-shadow'
                                                    name={field.name}
                                                    onClick={() => { handleShowListTrue(index) }}
                                                    value={tileData?.[seletedPage1]?.[selectedTab]?.[field.name] || ""}
                                                    onChange={(e) => {
                                                        filterList(e.target.value, field.searchKey, field.id, field.id2);
                                                        handleShowListTrue(index)
                                                        handleChange(seletedPage1, selectedTab, field.name, e.target.value)
                                                    }}
                                                    placeholder={field.placeholder}

                                                />
                                                {showList[index] && filterSearchObject[field.searchKey] !== undefined && (
                                                    <ul className="list-group position-absolute" style={{ zIndex: 1, maxHeight: "50vh", overflowY: "auto" }}>
                                                        {filterSearchObject?.[field?.searchKey].map((option, i) => (
                                                            <li
                                                                key={i}
                                                                className="list-group-item"
                                                                onClick={() => {
                                                                    handleChange(seletedPage1, selectedTab, field.name, option?.[field.id])
                                                                    handleShowListFalse(index) // Hide list on select
                                                                }}
                                                            >
                                                                {option?.[field.id]} - {option?.[field.id2]}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}

                                            </>
                                            :





                                            <>
                                                <input
                                                    autocomplete="off"
                                                    className='w-100 form-control box-shadow'
                                                    name={field.name}
                                                    value={tileData?.[seletedPage1]?.[selectedTab]?.[field.name] || ""}
                                                    onChange={(e) => { handleChange(seletedPage1, selectedTab, field.name, e.target.value) }}
                                                    placeholder={field.placeholder} />
                                            </>}
                                </div>
                            </div>

                        ))

                }
            </div>

            <div className="d-flex align-bottom justify-content-center w-100 align-item-center mt-4 mb-3">
                <div className={`btn px-5 mx-1 themeButton`} onClick={handleSave}>Save</div>
                <div className="btn whiteThemeButton px-4 mx-1" style={{ border: 0 }} onClick={handleCancel}>Cancel</div>
            </div>
        </>
    )
}

export default TileManagementForm
