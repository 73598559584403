import React from "react";
import { Facebook, Gmail, Instagram, LeftArrow, Phone, Supporticon,Whatsapp } from "../../Assets/Images/ExportSvg";
import { useNavigate } from "react-router-dom";

const SupportPage = () => {
  const navigate=useNavigate();
  const network=[
    {icon:Instagram,path:"https://www.instagram.com/unairesync_itsolution/profilecard/?igsh=M2UyMnl0bzUwMTl1"},
    {icon:Facebook,path:"https://www.facebook.com/share/1DgWpgFho8/"},
    {icon:Whatsapp,path:"https://wa.me/918690693835"},
    ]
  return (
    <>
      <div className="position-relative" style={{ height: "100vh" }}>
        <div className='text-start py-5 px-5'>
              <img src={LeftArrow} alt="Cross icon" width={"25rem"} onClick={()=>{   
              navigate("/")}}
              />
            </div>
        <div
          className="position-absolute start-50 translate-middle-x  d-flex justify-content-center align-items-center"
          style={{ fontSize: "1.5rem", top: "10vh" }}
        >
          <img
            src={Supporticon}
            alt="support"
            className="mx-1"
            width={"30rem"}
          />
          <label htmlFor="Support" className="login-form-font">
            Support
          </label>
        </div>

        <div className="position-absolute " style={{top:"30vh",left:"30vw"}}>
      <h5>Connect unairesync support</h5>
      <div className="d-flex my-2 align-items-center flex-nowrap justify-content-start">
        <img src={Phone} alt="" className="me-2" width={"25rem"}/>
        <h5 style={{whiteSpace:"nowrap"}}>Phone Number - +91-8690693835</h5>
      </div>
      <div className="d-flex my-2 align-items-center flex-nowrap justify-content-start">
        <img src={Phone} alt="" className="me-2" width={"25rem"}/>
        <h5 style={{whiteSpace:"nowrap"}}>Phone Number - +91-9257117783</h5>
      </div>
      <div className="d-flex my-2 align-items-center flex-nowrap justify-content-start">
        <img src={Gmail } alt="" className="me-2" width={"25rem"}/>
        <h5 style={{whiteSpace:"nowrap"}}>Mail Id -  info@unairesync.com / unairesync@gmail.com</h5>
      </div>

      <div className="d-flex my-5 align-items-center justify-content-center my-2">
        {network.map((val,index)=>(
          <>
          <a href={val.path}   target="_blank" rel="noopener noreferrer">
          <img src={val.icon} key={index} alt="" width={"25rem"} className='mx-3' style={{cursor:"pointer"}} />
          </a>
          </>
        ))}
      </div>


        </div>
      </div>
    </>
  );
};

export default SupportPage;
