import React from 'react'
import Form from '../Forms/Form'

const AddEmployees = () => {
  return (
    <>
   
    <div className="w-75 mx-auto">
    <Form formName={"executiveAdd"} borderless={true} />
    </div>
    </>
  )
}

export default AddEmployees
